import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { uiActions } from "../store/uiSlice";
import { useDispatch } from "react-redux";
import { IconButton, TextField, Typography } from "@mui/material";
import { adsurgentHumanDateTime } from "../util/util";
import classes from "./NotesUpgradeEditDialog.module.css";
import { useRef, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import ConfirmationDialog from "./ConfirmationDialog";
import { RootState } from "../store";
import { useParams } from "react-router-dom";
import {
  useGetUpgradeQuery,
  useUpdateUpgradeMutation,
  useDeleteUpgradeMutation,
} from "../store/api";
import { Upgrade } from "../types";
//slate RTE stuff:
import { Descendant } from "slate";
import NoteEditor from "./NoteEditorSlate";
import { emptyValue } from "./SlateRTEComponents";
import { ForkRight } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { getByTitle } from "@testing-library/react";
import LoadingComponent from "./LoadingComponent";

const NotesEditDialog: React.FunctionComponent<{ upgradeId: string }> = ({
  upgradeId,
}) => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const ui = useSelector((state: RootState) => state.ui);
  const params = useParams();
  const brandId: string = params.brandId!;

  const {
    data: upgradeData,
    error: upgradeError,
    isLoading: upgradeIsLoading,
  } = useGetUpgradeQuery({ brandId: brandId, upgradeId: upgradeId }, {});
  //console.log("upgrade data: " + JSON.stringify(upgradeData));

  const [title, setTitle] = React.useState("");
  const [isTitleFocused, setIsTitleFocused] = React.useState(false);

  const [
    updateUpgrade, // This is the mutation trigger (RTK Query)
    { isLoading: upgradeIsUpdating }, // This is the destructured mutation result
  ] = useUpdateUpgradeMutation();

  const [
    deleteUpgrade, // This is the mutation trigger (RTK Query)
    { isLoading: ugpradeIsDeleting }, // This is the destructured mutation result
  ] = useDeleteUpgradeMutation();

  //   const trixUpgradeEditor = useRef();
  //   const trixTitleInputRef = useRef();
  //   const trixBodyInputRef = useRef();
  const dispatch = useDispatch();
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    useState<boolean>(false);

  const handleSetConfirmationDialogOpen = (tf: boolean) => {
    setConfirmationDialogOpen(tf);
  };

  const editor = React.useRef();

  const [content, setContent] = useState<Descendant[] | undefined>(emptyValue);

  //let's set the initial value for the notes from database, if it exists
  const getInitialContent = async () => {
    if (upgradeData && upgradeData.notes) {
      const existingNotes = JSON.parse(upgradeData.notes);
      return setContent(existingNotes);
    } else return setContent(emptyValue);
  };

  const getInitialTitle = async () => {
    const title = upgradeData && upgradeData.label ? upgradeData.label : "";
    return setTitle(title);
  };

  const upgradeIdToWatch = upgradeData ? upgradeData?.id : "";

  useEffect(() => {
    getInitialTitle();
    getInitialContent();
  }, [upgradeIdToWatch]);

  if (upgradeData) {
    const onChangeTitleHandler = (title: string) => {
      if (title) {
        //create a new upgrade object with udpated "notes"
        const updatedUpgrade = { ...upgradeData, label: title };
        //check to see if anything has actually changed,
        //because onChange fires even for text selection, etc.
        if (updatedUpgrade.label != upgradeData.label) {
          console.log("Title changed, updating now...");
          //update the database
          updateUpgrade(updatedUpgrade);
          //now update the local state used by Slate
          setTitle(updatedUpgrade.label);
        }
      }
    };
    const onChangeContentHandler = (content: any) => {
      if (content) {
        const contentJson = JSON.stringify(content);
        //create a new upgrade object with udpated "notes"
        const updatedUpgrade = { ...upgradeData, notes: contentJson };
        //check to see if anything has actually changed,
        //because onChange fires even for text selection, etc.
        if (updatedUpgrade.notes != upgradeData.notes) {
          console.log("Notes changed, updating now...");
          //update the database
          updateUpgrade(updatedUpgrade);
          //now update the local state used by Slate
          setContent(content);
        }
      }
    };

    //this closes the main modal
    const handleClose = () => {
      dispatch(uiActions.setActiveUpgradeId(""));
    };
    //this opens the main modal
    const handleOpen = () => {
      uiActions.toggleNotesEditDialog(true);
    };

    const handleDelete = (upgradeId: string) => {
      //deleteUpgrade(activeUpgrade);
      console.log("handleDelete clicked...");
      deleteUpgrade({
        brandId: upgradeData.brandId,
        upgradeId: upgradeData.id,
      });
      handleSetConfirmationDialogOpen(false);
      handleClose();
    };

    const handleDeleteIconClick = () => {
      console.log("delete button clicked.");
      handleSetConfirmationDialogOpen(true);
    };

    const className = largeScreen
      ? classes["trix-input-large-screen"]
      : classes["trix-input-small-screen"];

    const getTitle = () => {
      setTitle("Oh Hello");
    };

    const dateObject = upgradeData.dueDate
      ? new Date(upgradeData.dueDate!.toString())
      : null;

    return (
      <>
        <Dialog
          //go full screen if it's a small screen
          fullScreen={true /*!largeScreen*/}
          open={upgradeId === ui.activeUpgradeId ? true : false}
          onClose={handleClose}
          PaperProps={
            !largeScreen
              ? {
                  sx: {
                    width: 800,
                  },
                }
              : {
                  sx: {},
                }
          }
        >
          <DialogContent>
            {/* If title is focused or the upgrade is completed, 
            don't allow editing, otherwise show a text input */}
            {!isTitleFocused || upgradeData.isCompleted ? (
              <Typography
                className={classes.title}
                onClick={() => {
                  setIsTitleFocused(true);
                }}
              >
                {title}
              </Typography>
            ) : (
              <TextField
                autoFocus
                inputProps={{ className: classes.title }}
                value={title}
                onChange={(event) => onChangeTitleHandler(event.target.value)}
                onBlur={(event) => setIsTitleFocused(false)}
                fullWidth={true}
              />
            )}
            <div>
              {upgradeData.dueDate && !upgradeData.isCompleted && (
                <p>
                  Due on: {dateObject && adsurgentHumanDateTime(dateObject)}
                </p>
              )}

              {upgradeData.dateCompleted && upgradeData.isCompleted && (
                //vertically align text with icon
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <CheckCircleOutlineIcon />
                  &nbsp;Completed:{" "}
                  {dateObject && adsurgentHumanDateTime(dateObject)}
                </p>
              )}
            </div>

            <NoteEditor
              // @ts-ignore
              content={content}
              onChangeHandler={onChangeContentHandler}
            />
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <IconButton onClick={handleDeleteIconClick}>
              <DeleteIcon />
            </IconButton>
            {!upgradeIsUpdating && (
              <Button onClick={handleClose} autoFocus={false}>
                Done
              </Button>
            )}
            {/* If upgrade is updating, show a non-clickable button. */}
            {upgradeIsUpdating && <Button autoFocus={false}>Saving...</Button>}
          </DialogActions>
        </Dialog>
        <ConfirmationDialog
          title="Delete this brand lab item?"
          message="This will permanently delete the item and any notes you wrote."
          open={confirmationDialogOpen}
          setOpen={handleSetConfirmationDialogOpen}
          onConfirm={handleDelete}
          children={undefined}
        />
      </>
    );
  } else return <div></div>;
};

export default NotesEditDialog;
