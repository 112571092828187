import * as React from "react";
// import Box from "@mui/material/Box";
// import Backdrop from "@mui/material/Backdrop";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
// import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
// import SaveIcon from "@mui/icons-material/Save";
// import PrintIcon from "@mui/icons-material/Print";
// import ShareIcon from "@mui/icons-material/Share";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import SmsIcon from "@mui/icons-material/Sms";
import MailIcon from "@mui/icons-material/Mail";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import DescriptionIcon from "@mui/icons-material/Description";
// import { ClassNames } from "@emotion/react";
// import classes from "./LeadSpeedDial.module.css";
// import { createTheme, ThemeProvider } from "@mui/material";
// import { red } from "@mui/material/colors";
// import { useMediaQuery, useTheme } from "@mui/material";
import ContactViaPhoneDialog from "./ContactViaPhoneDialog";
import ContactViaSmsDialog from "./ContactViaSmsDialog";
import ContactViaEmailDialog from "./ContactViaEmailDialog";
// import ContactViaReviewRequestDialog from "./ContactViaReviewRequestDialog";
import NotesCustomerEditDialog from "./NotesCustomerEditDialog";
// import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
//import { contactActions } from "../store/contact-slice";
import { uiActions } from "../store/uiSlice";
import { useParams } from "react-router-dom";

const speedDialContactMethods = [
  { name: "Call", icon: <LocalPhoneIcon /> },
  { name: "Text", icon: <SmsIcon /> },
  { name: "Email", icon: <MailIcon /> },
  { name: "Notes", icon: <ThumbsUpDownIcon /> },
];

const LeadSpeedDial: React.FunctionComponent = () => {
  const params = useParams();
  const customerId: string = params.customerId!;

  const dispatch = useDispatch();
  const phoneDialogToggleHandler = (isOpen: boolean) => {
    dispatch(uiActions.togglePhoneDialog(isOpen));
  };
  const smsDialogToggleHandler = (isOpen: boolean) => {
    dispatch(uiActions.toggleSmsDialog(isOpen));
  };
  const emailDialogToggleHandler = (isOpen: boolean) => {
    dispatch(uiActions.toggleEmailDialog(isOpen));
  };

  const toggleEditNotesHandler = (isOpen: boolean) => {
    dispatch(uiActions.setActiveUpgradeId(customerId));
    dispatch(uiActions.toggleNotesEditDialog(isOpen));
  };

  const actions = [
    {
      name: "Call",
      icon: <LocalPhoneIcon />,
    },
    {
      name: "Text",
      icon: <SmsIcon />,
    },
    // {
    //   name: "Email",
    //   icon: <MailIcon />,
    // },
    {
      name: "Notes",
      icon: <DescriptionIcon />,
    },
  ];

  const handleClick = (name: string) => {
    if (name === "Call") {
      phoneDialogToggleHandler(true);
    } else if (name === "Text") {
      smsDialogToggleHandler(true);
    } else if (name === "Email") {
      emailDialogToggleHandler(true);
    } else if (name === "Notes") {
      toggleEditNotesHandler(true);
    }
  };

  return (
    <>
      <SpeedDial
        ariaLabel="Lead Speed Dial"
        icon={<SpeedDialIcon />}
        direction="right"
        sx={{
          position: "flex",
          bottom: 0,
          left: 30,
          "& .MuiSpeedDialIcon-root": {
            color: "white",
            backgroundColor: "#000036",
            boxShadow: "none",
          },
          "& .MuiSpeedDial-fab": {
            color: "white",
            backgroundColor: "#000036",
            boxShadow: "none",
          },
          "& .MuiSpeedDialAction-fab": {
            color: "white",
            backgroundColor: "#000036",
            boxShadow: "none",
          },
          "& .MuiSpeedDialAction-staticTooltipLabel": {
            color: "white",
            backgroundColor: "#000036",
            boxShadow: "none",
          },
        }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={(e) => {
              handleClick(action.name);
            }}
          />
        ))}
      </SpeedDial>

      {/*let's make sure all the dialogs are ready to go*/}
      <ContactViaPhoneDialog />
      <ContactViaSmsDialog />
      <ContactViaEmailDialog />
      <NotesCustomerEditDialog />
    </>
  );
};

export default LeadSpeedDial;
