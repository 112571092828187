import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { uiActions } from "../../store/uiSlice";
import {
  useGetCustomersQuery,
  useGetCustomerQuery,
  useUpdateCustomerMutation,
  useGetBrandQuery,
} from "../../store/api";
import { Customer, Message } from "../../types";
import classes from "./LeadList.module.css";
import { RootState } from "../../store";
import { Brand } from "../../types";
import { useParams, Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  adsurgentHumanDateTime,
  getCustomerDisplayPhone,
} from "../../util/util";
import LoadingComponent from "../LoadingComponent";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Typography } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
//import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import { getProperCase } from "../../util/util";
import { selectCurrentRole } from "../../store/authSlice";
import { getCustomersFiltered } from "../../util/searchFilters";
import { useEffect, useState, useMemo } from "react";
import logger from "../../logger";

const LeadList: React.FunctionComponent = () => {
  const params = useParams();
  const brandId: string | undefined = params.brandId;

  const {
    data: brandData,
    error: brandError,
    isLoading: brandIsLoading,
  } = useGetBrandQuery({ brandId: brandId! });

  const {
    data: rawCustomersData,
    error: rawCustomersError,
    isLoading: rawCustomersIsLoading,
  } = useGetCustomersQuery({ brandId: brandId! }, { pollingInterval: 10000 });

  //let's run the customersData through any user supplied filters
  const filters = useSelector((state: RootState) => state.ui.searchFilters);
  //const filters = {...rawFilters, dateRange:JSON.parse(rawFilters.dateRange) }
  //logger.info("LeadList filters: ", filters);
  //logger.info("filters: ", filters.dateRange);

  const dispatch = useDispatch();

  //We only want to filter the data if the rawCustomersData OR search filters have changed
  //so we are using useMemo to make sure this expensive process doesn't fire more than it should
  const customersData = useMemo(() => {
    if (rawCustomersData) {
      //apply any filters
      //logger.info("useMemo - rawCustomersData[0]: ", rawCustomersData[0]);
      const filtered = getCustomersFiltered(rawCustomersData, filters);
      //logger.info("useMemo - filtered[0]: ", filtered[0]);
      //send the filtered count to redux state for use in other component(s)
      dispatch(uiActions.setFilteredCustomerCount(filtered.length));
      return filtered;
    }
  }, [rawCustomersData, filters]);

  if (customersData) {
    const getItemKey = () => {
      const randomId = uuid();
      return randomId;
    };

    return (
      <AutoSizer>
        {({ height, width }) => (
          //@ts-ignore
          <FixedSizeList
            //@ts-ignore
            itemData={{ customers: customersData, brand: brandData }}
            itemCount={customersData.length}
            //FixedSizeList exposes the itemKey prop, which takes a function
            //that should return either a string or a number.
            itemKey={getItemKey}
            itemSize={72}
            height={height}
            width={width}
            className={classes.list}
          >
            {/*render row using function below, using the properties above*/}
            {RenderRow}
          </FixedSizeList>
        )}
      </AutoSizer>
    );
  } else if (rawCustomersError) {
    logger.error("rawCustomersError: ", rawCustomersError);
    return <LoadingComponent statusMsg="Trouble loading customers..." />;
  } else if (rawCustomersIsLoading) {
    logger.info("rawCustomersIsLoading: ", rawCustomersIsLoading);
    return <LoadingComponent statusMsg="Loading customers..." />;
  } else {
    logger.warn("Unknown error while loading customersData.");
    return <LoadingComponent statusMsg="Sorry, something went wrong..." />;
  }
  // } else {
  //   logger.info("Probably loading customer data...");
  //   return <LoadingComponent statusMsg="Loading customer data..." />;
  // }
};

type Customers = Customer[];

function RenderRow(props: {
  data: { customers: Customer[]; brand: Brand };
  index: any;
  style: any;
}) {
  const userRole = useSelector(selectCurrentRole);
  //logger.info("userRole: ", userRole);
  const params = useParams();
  //const brandId: string | undefined = params.brandId;
  const customerId: string | undefined = params.customerId;

  const { data, index, style } = props;

  const brand = props.data.brand;
  const customers = data;
  //use the index to locate the individual customer
  const customer = data.customers[index];
  const customerDisplayPhone = getCustomerDisplayPhone(customer);
  const dispatch = useDispatch();
  const openDrawerHandler = () => {
    //there's a bit of a delay, so adding this to create a better UX
    setTimeout(() => {
      dispatch(uiActions.toggleHistoryDrawer(true));
    }, 300);
  };

  const [updateCustomer, result] = useUpdateCustomerMutation();

  // const lastIndex = customer.messages!.length - 1;
  // const mostRecentMessage = () => {
  //   customer.messages.reduce((r, o) => (o.timestamp > r.timestamp ? o : r));
  // };

  const mostRecentMessage = (function () {
    if (customer.messages && customer.messages.length > 0) {
      const mostRecentMessage = customer.messages.reduce((r, o) =>
        o.timestamp > r.timestamp ? o : r
      );
      return mostRecentMessage;
    } else return null;
  })();

  const mostRecentDate = mostRecentMessage
    ? new Date(mostRecentMessage.timestamp)
    : null;
  //logger.info("mostRecentDate", mostRecentDate);
  const dateTime: string = mostRecentDate
    ? adsurgentHumanDateTime(mostRecentDate)
    : "";
  //logger.info("dateTime: ", dateTime);

  //if the brandId URL param matches the list item customer ID
  //then let's mark it the "activeCustomer"
  const activeCustomer = customerId == customer.id ? true : false;
  //logger.info(customerId, customer.id, activeCustomer);

  //NOTE: we are doing the following if-statements here, because
  //there were issues doing them in the return statement - not sure why
  //but were getting unexpected results
  const listItemClasses = activeCustomer
    ? `${classes["list-item"]} ${classes["list-item-active"]}`
    : (customer.needsAttention && !activeCustomer) ||
      (customer.salesStatus === 0 && !activeCustomer)
    ? `${classes["list-item"]} ${classes["list-item-needs-attention"]}`
    : classes["list-item"];

  const nameClasses =
    customer.needsAttention || customer.salesStatus === 0
      ? `${classes["name-needs-attention"]}`
      : "";

  const displayName = customer.verifiedCustomerName
    ? getProperCase(customer.verifiedCustomerName)
    : customer && customer.messages && customer.messages[0]
    ? getProperCase(customer.messages[0].customerName)
    : null;

  const verifiedCheck = () => {
    if (customer.verifiedCustomerName) {
      return (
        <VerifiedIcon
          sx={{ marginLeft: "5px", color: "#6a6b6a", fontSize: "20px" }}
        />
      );
    } else return <></>;
  };

  const spamCheck = () => {
    if (customer.isSpam) {
      return (
        <ReportGmailerrorredIcon
          sx={{ marginLeft: "5px", color: "#6a6b6a", fontSize: "20px" }}
        />
      );
    } else return <></>;
  };

  const saleCheck = () => {
    const brandStatuses = brand.statuses;
    if (brandStatuses) {
      const brandIsSaleStatus = brandStatuses.find(
        (brandStatus) => brandStatus.isSale === true
      );

      //logger.info(brandIsSaleStatus);

      if (
        brandIsSaleStatus &&
        customer.salesStatus &&
        customer.salesStatus >= brandIsSaleStatus.id
      ) {
        return (
          <AttachMoneyOutlinedIcon
            sx={{ marginLeft: "5px", color: "#6a6b6a", fontSize: "20px" }}
          />
        );
      }
    } else return <></>;
  };

  return (
    <ListItem
      style={style}
      key={index}
      component="div"
      disablePadding
      className={listItemClasses}
    >
      <ListItemButton
        component={Link}
        to={`/${customer.brandId}/leads/${customer.id}`}
        onClick={() => {
          //managers should not affect the needsAttention property
          //logger.info(userRole);
          if (userRole !== "manager") {
            try {
              //logger.info(customerData.id, customerData.brandId, "salesStatus", clickedStatus );
              const updateCustomerResult = updateCustomer({
                brandId: customer.brandId,
                customerId: customer.id,
                propertyName: "needsAttention",
                propertyValue: false,
              }).unwrap();
              //logger.info("fulfilled", updateCustomerResult);
            } catch (error) {
              logger.error("rejected", error);
            }
          }
          openDrawerHandler();
        }}
      >
        <ListItemText
          disableTypography
          primary={
            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div className={nameClasses}>{displayName}</div>
              {/* let's place a blue checkmark if they're name is verified by a user */}
              {verifiedCheck()}
              {/* let's place a warning sign if spam  */}
              {spamCheck()}
              {/* let's place a $ sign if sale  */}
              {saleCheck()}
            </Typography>
          }
          secondary={
            customer?.messages ? customerDisplayPhone : "No phone available"
          }
          // primaryTypographyProps={
          //   customer.needsAttention || customer.salesStatus === 0
          //     ? { fontWeight: 800, color: "#333333" }
          //     : { fontWeight: 400, color: "#0000008a" }
          // }
        />
        <ListItemIcon>{customer?.messages ? dateTime : ""}</ListItemIcon>
      </ListItemButton>
    </ListItem>
  );
}

export default LeadList;
