import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import MuiTooltip from "@mui/material/Tooltip";
import AutoSizer from "react-virtualized-auto-sizer";
import { getDataForGoodFitsChart } from "../util/performanceData";
import { useParams } from "react-router";
import { useGetCustomersQuery, useGetBrandQuery } from "../store/api";
import dayjs from "dayjs";
import { Divider } from "@mui/material";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ChartMultiSelect from "./ChartMultiSelect";
import {
  getBrandOptimizationScore,
  getDateRangesByMonth,
} from "../util/performanceData";
import { useMemo, useState } from "react";
import logger from "../logger";

const ChartBrandOptimization: React.FunctionComponent = () => {
  const params = useParams();
  const brandId: string | undefined = params.brandId;

  const {
    data: customersData,
    error: customersError,
    isLoading: customersIsLoading,
  } = useGetCustomersQuery({ brandId: brandId! });

  const {
    data: brandData,
    error: brandError,
    isLoading: brandIsLoading,
  } = useGetBrandQuery({ brandId: brandId! });

  const [dataForChart, setDataForChart]: [
    {
      month: string;
      BPOscore: number;
      CPOscore: number;
    }[],
    any
  ] = useState([]);

  useMemo(async () => {
    if (brandData && customersData) {
      const dateRangesByMonth = getDateRangesByMonth(12);
      //logger.info("ChartBrandOptimization - dateRangesByMonth: ", dateRangesByMonth);

      for await (const month of dateRangesByMonth) {
        //logger.info("month.endDate: ", month.endDate);
        const brandPerformanceOptimization = await getBrandOptimizationScore(
          brandData,
          customersData,
          month.startDate,
          month.endDate
        );
        logger.info(
          "ChartBrandOptimization: brandPerformanceOptimization: ",
          brandPerformanceOptimization
        );

        if (brandPerformanceOptimization) {
          const item = {
            month: month.startDate.format("MMM"),
            BPOscore: brandPerformanceOptimization.BPOscore,
            CPOscore: brandPerformanceOptimization.CPOscore,
            SPOscore: brandPerformanceOptimization.SPOscore,
            closingRate: brandPerformanceOptimization.closingRate,
            sales: brandPerformanceOptimization.sales,
            cac: brandPerformanceOptimization.actualCAC,
            goodFits: brandPerformanceOptimization.goodFits,
          };

          dataForChart.push(item);
        }
      }
      setDataForChart(dataForChart);
      //   logger.info("ChartBrandOptimization, dataForChart: ", dataForChart);
      //   logger.info("dateRangesByMonth/Chartbrandoptimize: ", dateRangesByMonth);
      //logger.info("ChartBrandOptimization - dataForChart: ", dataForChart);
    }
  }, [brandData, customersData]);

  //   const data = [
  //     { Month: "Jan", BrandPerformanceOptimization: 32 },
  //     { Month: "Feb", BrandPerformanceOptimization: 38 },
  //     { Month: "Mar", BrandPerformanceOptimization: 47 },
  //     { Month: "Apr", BrandPerformanceOptimization: 52 },
  //     { Month: "May", BrandPerformanceOptimization: 58 },
  //     { Month: "Jun", BrandPerformanceOptimization: 71 },
  //     { Month: "Jul", BrandPerformanceOptimization: 95 },
  //   ];

  if (brandData && dataForChart && dataForChart.length > 0) {
    const renderLineChart = (
      height: number | undefined,
      width: number | undefined
    ) => (
      <LineChart
        width={width}
        height={300}
        data={dataForChart.reverse()}
        margin={{ top: 0, right: 20, bottom: 5, left: 0 }}
        //style={{ display: "block" }}
      >
        <Line
          name="Adsurgent Performance"
          type="monotone"
          dataKey="BPOscore"
          stroke="#000036"
          strokeWidth={1}
        />
        <Line
          name={brandData.name + " Performance"}
          type="monotone"
          dataKey="CPOscore"
          stroke="brown"
          strokeWidth={1}
        />
        {/* <Line
          name="Good Fits"
          type="monotone"
          dataKey="goodFits"
          stroke="brown"
          strokeWidth={2}
        /> */}
        <Line
          name="Sales Optimization"
          type="monotone"
          dataKey="SPOscore"
          stroke="darkgreen"
          strokeWidth={4}
        />

        {/* <Line
          name="Acquisition Cost"
          type="monotone"
          dataKey="cac"
          stroke="red"
          strokeWidth={2}
        /> */}

        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis dataKey="month" />
        <YAxis />
        <Legend />
        <Tooltip />
      </LineChart>
    );
    return (
      <AutoSizer>
        {({ height, width }) => (
          <div style={{ width: width }}>{renderLineChart(height, width)} </div>
        )}
      </AutoSizer>
    );
  } else return <div>Loading...</div>;
};
export default ChartBrandOptimization;
