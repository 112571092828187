import React from "react";
import classes from "./LoadingComponent.module.css";
import AutoSizer from "react-virtualized-auto-sizer";

const LoadingComponent: React.FunctionComponent<{
  statusMsg: string | null;
}> = ({ statusMsg }) => {
  return (
    // <AutoSizer>
    //   {({ height, width }) => (
    <div
      className={classes["outerbox"]}
      //style={{ height: height, width: width }}
    >
      <div className={classes["innerbox"]}>
        <div className={classes["custom-loader"]}></div>
      </div>
    </div>

    //   )}
    // </AutoSizer>
  );
};
export default LoadingComponent;
