import LeadHeader from "../LeadHeader";
import Messages from "../Messages";
import MessageInput from "../MessageInput";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
//import LeadSpeedDial from "../LeadSpeedDial";
import React from "react";
import { RootState } from "../../store";
import { Customer } from "../../types";
import { useGetCustomerQuery } from "../../store/api";
import { useParams } from "react-router-dom";
import { red } from "@mui/material/colors";
import LoadingComponent from "../LoadingComponent";
import logger from "../../logger";

const LeadHistory: React.FunctionComponent<{ winHeight: number }> = ({
  winHeight,
}) => {
  const params = useParams();
  const brandId: string | undefined = params.brandId;
  const customerId: string | undefined = params.customerId;
  //logger.info(brandId, customerId);

  const {
    currentData: currentCustomerData,
    data: customerData,
    error: customerError,
    isLoading: customerIsLoading,
    isFetching: customerisFetching,
  } = useGetCustomerQuery({ brandId: brandId!, customerId: customerId! }, {});
  //const customer = useSelector((state) => state.customer);
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));

  if (!brandId || !customerId) {
    return (
      <div>That brand or customer does not exist. Please check your URL.</div>
    );
  }

  if (customerError) {
    logger.error(
      "Customer error in lead history: " + JSON.stringify(customerError)
    );
    return (
      <div>
        <LoadingComponent statusMsg={null} />
      </div>
    );
  } else if (customerIsLoading) {
    return <LoadingComponent statusMsg={null} />;

    //The following check is extremely important, we are checking to see if RTK Query
    //data matches currentData property.
    //https://redux-toolkit.js.org/rtk-query/usage/queries
    //https://github.com/reduxjs/redux-toolkit/discussions/2861
    //we are detecting first if isFetching, but ALSO if there's been an arg change (e.g., customerId)
    //This allows us to keep polling without triggering the spinner all the time with isFetching
    //while also letting us know if the user has clicked to a different customer (new route)
    //solves the issue where we were getting delayed data after a click on to the user
  } else if (customerisFetching && currentCustomerData !== customerData) {
    return (
      <>
        <LoadingComponent statusMsg={null} />
      </>
    );
  } else if (customerData && customerData.messages) {
    //logger.info(JSON.stringify(customerData));
    //the following numbers are manually inputed from screen dimensions
    //...observed in the dom for the containing elements
    ///probably a better way to do this
    const leadHeaderHeight = largeScreen ? 170 : 170;

    const messageInputHeight = 100;
    const navBarHeight = 0; /*64*/
    const messagesWindowHeight =
      winHeight - leadHeaderHeight - messageInputHeight - navBarHeight;

    return (
      <>
        <div style={{ height: leadHeaderHeight }}>
          <LeadHeader height={leadHeaderHeight} />
        </div>
        <div style={{ height: messagesWindowHeight }}>
          <Messages height={messagesWindowHeight} />
        </div>
        <div style={{ height: messageInputHeight }}>
          <MessageInput height={messageInputHeight} />
        </div>
      </>
    );
  } else return <LoadingComponent statusMsg={null} />;
  ///if we want to show something here, we're going to have to open the drawer first
};

export default LeadHistory;
