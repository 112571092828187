import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Grid } from "@mui/material";
import ConversionGauge from "./ConversionGauge";

import classes from "./StatusCard.module.css";
import { StatusCard } from "../../types";

const BrandStatusCard: React.FunctionComponent<StatusCard> = ({
  children,
  statusCount,
  goodFit,
  sale,
  postSale,
  conversionRate,
  title,
  conversionCount,
}) => {
  return (
    <Grid item md={1} sm={2} xs={2}>
      <Card
        className={classes.statuscard}
        style={
          postSale === true
            ? { backgroundColor: "white" }
            : sale === true
            ? { backgroundColor: "white" }
            : goodFit === true
            ? { backgroundColor: "white" }
            : { backgroundColor: "white" }
        }
      >
        <CardContent>
          <ConversionGauge conversionRate={conversionRate} />
          <h1>{title}</h1>
          <h2>{conversionCount}</h2>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default BrandStatusCard;
