import resolveProps from "@mui/utils/resolveProps";
import GaugeChart from "react-gauge-chart";
import classes from "./ConversionGauge.module.css";
import React from "react";

const ConversionGauge: React.FunctionComponent<{ conversionRate: number }> = ({
  children,
  conversionRate,
}) => {
  return (
    <div className={classes.gaugediv}>
      <GaugeChart
        //id={props.id}
        nrOfLevels={1}
        colors={["#757575"]}
        arcWidth={0.1}
        percent={conversionRate}
        textColor="#000036"
        needleColor="#757575"
        needleBaseColor="#757575"
        className={classes.gaugediv}
        fontSize="1.25rem"
      />
    </div>
  );
};

export default ConversionGauge;
