import dayjs from "dayjs";
import { Customer, SearchFilters, Message } from "../types";
import logger from "../logger";
// ---------------------------------------
// -------CUSTOMER SEARCH & FILTERS-------
// ---------------------------------------
export const getCustomersFiltered = (
  customersData: Customer[],
  filters: SearchFilters
) => {
  //   logger.info("Filtering customers...", dayjs().format());
  //filter works by returning the actual condition to test for
  const stepOneFilterByDate = customersData.filter((customer: Customer) => {
    const startDate = filters.dateRange[0];
    const endDate = filters.dateRange[1];

    //logger.info("searchFilters: ", startDate, endDate);
    //get most recent customer message, so we can use it's timestamp
    if (customer && customer.messages && customer.messages[0]) {
      const customerMostRecentMessage = customer.messages.reduce((r, o) =>
        o.timestamp > r.timestamp ? o : r
      );
      //   logger.info(
      //     "startDate: ",
      //     startDate,
      //     "endDate: ",
      //     endDate,
      //     "mostRecentTimestamp: ",
      //     customerMostRecentMessage.timestamp
      //   )
      return (
        customerMostRecentMessage.timestamp >= startDate &&
        customerMostRecentMessage.timestamp <= endDate
      );
    }
    //return customer. >= startTime && itemTime <= endTime;
  });
  //   logger.info("searchFilters - customersData[0]: ", customersData[0]);
  //   logger.info("searchFilters - stepOneFilterByDate[0]: ", stepOneFilterByDate[0]);

  //   logger.info("stepOneFilterByDate: ", stepOneFilterByDate);

  const stepTwoFilterBySpam = stepOneFilterByDate.filter(
    (customer: Customer) => {
      if (filters.showSpam === true) {
        //this condition should return ALL customers
        return customer;
        ///the condition is to remove SPAM from results
        //also filters if isSpam is undefined
      } else return customer.isSpam !== true;
    }
  );

  //   logger.info("filterOutSpamStepOne: ", stepTwoFilterBySpam);

  const stepThreeFilterByNeedsAttention = stepTwoFilterBySpam.filter(
    (customer: Customer, index: number) => {
      if (filters.showOnlyNeedsAttention === true) {
        return customer.needsAttention === true || customer.salesStatus === 0;
      }
      //no need to filter, just return a condition that must be true
      else return customer;
    }
  );

  //   logger.info(
  //     "stepThreeFilterByNeedsAttention:",
  //     stepThreeFilterByNeedsAttention
  //   );

  const stepFourFilterByStatus = stepThreeFilterByNeedsAttention.filter(
    (customer: Customer, index: number) => {
      if (filters.showStatus === 99) {
        return customer;
      } else {
        return customer.salesStatus === filters.showStatus;
      }
    }
  );

  //   logger.info("stepFourFilterByStatus:", stepFourFilterByStatus);

  ////result = nested.map(a => a.filter(e => e > limit ));
  //logger.info(filters.searchString);

  //a condition must that must return true if condition is met and false if not
  const isFoundInName = (customer: Customer) => {
    let foundMessages: any = [];
    if (customer.messages) {
      customer.messages.map((message: Message) => {
        if (message.customerName) {
          const foundMessage = message.customerName
            .toLowerCase()
            .includes(filters.searchString.toLowerCase())
            ? message
            : null;

          if (foundMessage) {
            foundMessages.push(foundMessage);
          }
        }
        //logger.info("foundMessages: ", foundMessages);
      });
      const foundVerifiedName = customer.verifiedCustomerName
        ?.toLowerCase()
        .includes(filters.searchString.toLowerCase());
      // logger.info(foundMessages.length, foundVerifiedName);
      if (foundMessages.length > 0 || foundVerifiedName) {
        //logger.info("true");
        return true;
      } else return false;
    }
  };

  const isFoundInPhone = (customer: Customer) => {
    let foundMessages: any = [];
    if (customer.messages) {
      customer.messages.map((message: Message) => {
        if (message.customerPhone) {
          const foundMessage = message.customerPhone
            ?.toString()
            .replace(/\D/g, "")
            .includes(filters.searchString)
            ? message
            : null;
          if (foundMessage) {
            foundMessages.push(foundMessage);
          }
        }
      });
      if (foundMessages.length > 0) {
        //logger.info("true");
        return true;
      } else return false;
    }
  };

  const isFoundInEmail = (customer: Customer) => {
    let foundMessages: any = [];
    if (customer.messages) {
      customer.messages.map((message: Message) => {
        if (message.customerEmail) {
          const foundMessage = message.customerEmail
            ?.toLowerCase()
            .includes(filters.searchString.toLowerCase())
            ? message
            : null;
          if (foundMessage) {
            foundMessages.push(foundMessage);
          }
        }
      });
      if (foundMessages.length > 0) {
        //logger.info("true");
        return true;
      } else return false;
    }
  };

  const isFoundInNotes = (customer: Customer) => {
    if (
      customer.notes?.toLowerCase().includes(filters.searchString.toLowerCase())
    ) {
      return true;
    } else return false;
  };

  const isFoundInCustomer = (customer: Customer) => {
    if (
      isFoundInName(customer) ||
      isFoundInPhone(customer) ||
      isFoundInEmail(customer) ||
      isFoundInNotes(customer)
    ) {
      return true;
    } else return false;
  };

  //we just need an OVERALL FUNCTION that returns TRUE OR FALSE that incorporates not only name, but also
  // phone, email, notes, etc.
  const stepFiveFilterBySearchString = stepFourFilterByStatus.filter(
    (customer) => isFoundInCustomer(customer)
  );

  //   logger.info("stepFiveFilterBySearchString:", stepFiveFilterBySearchString);

  return stepFiveFilterBySearchString;
};
