import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import MuiTooltip from "@mui/material/Tooltip";
import AutoSizer from "react-virtualized-auto-sizer";
import { getDataForGoodFitsChart } from "../util/performanceData";
import { useParams } from "react-router";
import { useGetCustomersQuery } from "../store/api";
import dayjs from "dayjs";
import { Divider } from "@mui/material";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ChartMultiSelect from "./ChartMultiSelect";
import logger from "../logger";

const ChartNewLeads: React.FunctionComponent = () => {
  const params = useParams();
  const brandId: string | undefined = params.brandId;
  const {
    data: customersData,
    error: customersError,
    isLoading: customersIsLoading,
  } = useGetCustomersQuery({ brandId: brandId! }, { pollingInterval: 10000 });
  //const data = [{ name: "Page A", uv: 400, pv: 2400, amt: 2400 }];

  if (customersData) {
    const thisYear = dayjs().year();
    const lastYear = thisYear - 1;
    const twoYearsAgo = thisYear - 2;
    const threeYearsAgo = thisYear - 3;
    const { data, dataQuality } = getDataForGoodFitsChart(customersData);

    if (data) {
      //logger.info("ChartGoodFitLeads:", data);
      const renderLineChart = (
        height: number | undefined,
        width: number | undefined
      ) => (
        <LineChart
          width={width}
          height={300}
          data={data}
          margin={{ top: 0, right: 20, bottom: 5, left: 0 }}
          //style={{ display: "block" }}
        >
          <Line
            type="monotone"
            dataKey={thisYear}
            stroke="darkgreen"
            strokeWidth={4}
          />
          <Line
            type="monotone"
            dataKey={lastYear}
            stroke="darkgray"
            strokeWidth={3}
          />
          <Line
            type="monotone"
            dataKey={twoYearsAgo}
            stroke="gray"
            strokeWidth={1.5}
          />
          <Line
            type="monotone"
            dataKey={threeYearsAgo}
            stroke="lightgray"
            strokeWidth={1}
          />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis dataKey="month" />
          <YAxis />
          <Legend />
          <Tooltip />
        </LineChart>
      );
      return (
        <AutoSizer>
          {({ height, width }) => (
            <div style={{ width: width }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <h1>Quantitative Marketing Performance</h1>
                  <p>
                    This chart shows "Good Fits" performance over time. Most
                    businesses experience seasonal fluctuations. This chart
                    helps you understand your business throughout the year.
                  </p>
                </div>
                <div>
                  <p>
                    Data quality: <strong>{dataQuality}%</strong>
                  </p>
                  {/* <ChartMultiSelect /> */}
                </div>
              </div>
              <Divider style={{ marginTop: 25, marginBottom: 25 }}></Divider>
              {renderLineChart(height, width)}{" "}
            </div>
          )}
        </AutoSizer>
      );
    } else return <div>Sorry, no chart data available.</div>;
  } else return <div>No customer data available.</div>;
};
export default ChartNewLeads;
