import React from "react";
import classes from "./LeadHeader.module.css";
import { Grid } from "@mui/material";
// import AddIcCallIcon from "@mui/icons-material/AddIcCall";
// import DescriptionIcon from "@mui/icons-material/Description";
// import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";
import StatusStepper from "./StatusStepper";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AutoSizer from "react-virtualized-auto-sizer";
import { useSelector } from "react-redux";
import { createTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { getThemeProps } from "@mui/system";
import DoneIcon from "@mui/icons-material/Done";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import PaidIcon from "@mui/icons-material/Paid";
//import LeadSpeedDial from "./LeadSpeedDial";
// import {
//   CustomerPhonePrimaryObject,
//   CustomerEmailPrimaryObject,
// } from "./utils";
import NumberFormat from "react-number-format";
//import CustomerEditDialog from "./CustomerEditDialog";
import { useDispatch } from "react-redux";
//import { contactActions } from "../store/contact-slice";
import { Brand, BrandStatus, Customer, Message } from "../types";
import { RootState } from "../store";
import { uiActions } from "../store/uiSlice";
import { useParams } from "react-router-dom";
import {
  useGetCustomerQuery,
  useGetBrandQuery,
  useUpdateCustomerMutation,
} from "../store/api";
import { getCustomerDisplayPhone } from "../util/util";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Typography } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { useEffect } from "react";
import { useForm, Controller, useController } from "react-hook-form";
import { TextField, InputAdornment } from "@mui/material";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { getCustomerName, getCustomerEmail } from "../util/util";
import ConfirmationDialog from "./ConfirmationDialog";
import ContactViaReviewRequestDialog from "./ContactViaReviewRequestDialog";
import { getProperCase } from "../util/util";
import logger from "../logger";

//https://stackoverflow.com/questions/71054812/focus-on-input-with-error-on-form-submission
// const props = {};
// const { field, fieldState } = useController(props);
// const { ref, ...fieldProps } = field;

function getSaleStatuses(statuses: BrandStatus[]) {
  const saleStatuses: number[] = [];
  {
    statuses.map((status) => {
      if (status.isSale === true) {
        saleStatuses.push(status.id);
      }
    });
  }
  return saleStatuses;
}

const LeadHeader: React.FunctionComponent<{ height: number }> = ({
  height,
}) => {
  const ui = useSelector((state: RootState) => state.ui);
  const [updateCustomer, result] = useUpdateCustomerMutation();
  const params = useParams();
  const brandId: string = params.brandId!;
  const customerId: string = params.customerId!;
  //we are already checking isLoading & error in LeadHistory (wrapper)
  //so we can just assert the customerData here
  const { data: customerData } = useGetCustomerQuery(
    { brandId: brandId, customerId: customerId },
    {}
  );
  const { data: brandData } = useGetBrandQuery({ brandId: brandId }, {});
  // if (customer.emails) {

  const theme = createTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const dispatch = useDispatch();

  const [isNameInputLocked, setNameInputLocked] = React.useState(true);

  const getSpamEligibility = () => {
    //if any message has inbound content available, it is eligible to be marked as spam
    //we're mainly preventing users from marking missed calls (with no voicemail) as spam
    if (customerData && customerData.messages) {
      const qualifyingMessage = customerData.messages.find(
        (message: Message) =>
          //note calls use "inbound" while forms & sms use "incoming"
          (message.direction === "inbound" &&
            message.callDetails.recordingFilename) ||
          (message.direction === "incoming" &&
            message.type === "form-submission") ||
          (message.direction === "incoming" && message.type === "sms")
      );
      if (qualifyingMessage) {
        //logger.info("True. Here's the qualifyingMessage:", qualifyingMessage);
        return true;
      } else return false;
    } else return false;
    //return true;
  };
  const isEligibleForSpam = getSpamEligibility();
  const handleReportSpamClick = () => {
    setIsSpamConfirmationDialogOpen(true);
  };
  const handleConfirmSpam = () => {
    //check if this contact is eligible for spam reporting
    //ie., must have content available (not just missed calls)

    if (customerData && isEligibleForSpam) {
      try {
        //logger.info(customerData.id, customerData.brandId, "salesStatus", clickedStatus );
        const updateCustomerResult = updateCustomer({
          brandId: customerData.brandId,
          customerId: customerData.id,
          propertyName: "isSpam",
          propertyValue: customerData.isSpam ? false : true,
        }).unwrap();
        //logger.info("isSpam fulfilled", updateCustomerResult);
      } catch (error) {
        logger.error("rejected", error);
      }
    } else {
      //logger.info("Not eligible for Spam OR no customerData.");
    }
  };

  const [isSpamConfirmationDialogOpen, setIsSpamConfirmationDialogOpen] =
    React.useState(false);

  // title="Report Spam"
  // open={isSpamConfirmationDialogOpen}
  // message="Are you sure you want to report this lead as spam?"
  // setOpen={setSpamConfirmationDialogOpen}
  // onConfirm={handleReportSpam}
  const handleCustomerEditNameToggle = (isOpen: boolean) => {
    //if name input is locked, let's focus on it before we unlock it
    if (isNameInputLocked) {
      //logger.info("trying to set focus");
      setNameInputLocked(!isNameInputLocked);
      setTimeout(() => {
        setFocus("customerName", { shouldSelect: true });
      }, 10);

      //customerName.ref.focus();
    }

    //if unlocked & clicked, let's update the Name before we lock it.
    if (!isNameInputLocked) {
      setNameInputLocked(!isNameInputLocked);
      const customerNameUppercase = customerName.toUpperCase();
      //logger.info(customerNameUppercase);
      //let's update the database
      if (customerData) {
        const dbCustomerName = getCustomerName(customerData);
        //let's make sure something has actually changed before we try to update the DB
        if (dbCustomerName !== customerNameUppercase) {
          try {
            //logger.info(customerData.id, customerData.brandId, "salesStatus", clickedStatus );
            const updateCustomerResult = updateCustomer({
              brandId: customerData.brandId,
              customerId: customerData.id,
              propertyName: "verifiedCustomerName",
              propertyValue: customerNameUppercase,
            }).unwrap();
            //logger.info("fulfilled", updateCustomerResult);
          } catch (error) {
            logger.error("rejected", error);
          }
        } else {
          logger.info("name has not changed.");
        }
      }
    }

    //let's unlock the field so that the user can edit the name
  };

  //set up react-hook-form so user can edit the customer name
  const { control, reset, watch, setFocus } = useForm({
    // defaultValues: {},
  });
  useEffect(() => {
    if (customerData) {
      let defaultValues: any = {};
      const name = getCustomerName(customerData);
      if (name) {
        defaultValues.customerName = getProperCase(name);
        reset({ ...defaultValues });
      }
    }
  }, [customerData]);

  const customerName = watch("customerName");
  //logger.info(customerName);

  if (customerId && customerData && brandData) {
    const statuses = brandData.statuses!;
    const currentStatusNum = customerData.salesStatus!;
    //const currentStatusLabel = statuses[currentStatusNum].label ?? "test";
    const phoneDisplay = getCustomerDisplayPhone(customerData);
    const emailDisplay = getCustomerEmail(customerData);
    //logger.info("emailDisplay: ", emailDisplay);
    const emailPrimary = customerData.emails?.primary;
    const nameClass = classes.name;
    const customerNameIcon = customerData.verifiedCustomerName ? (
      <Tooltip title="User-verified name">
        <VerifiedIcon sx={{ color: "#b8c6db" }} />
      </Tooltip>
    ) : (
      <Tooltip title="Unverified name from caller ID">
        <HelpIcon />
      </Tooltip>
    );

    return (
      <>
        <AutoSizer>
          {({ height, width }) => (
            <div
              className={classes["main"]}
              data-height={height}
              style={{ display: "flexbox", width: width }}
            >
              <Grid container spacing={0}>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  flexDirection="column"
                  alignItems="left"
                  justifyContent=""
                  sx={!largeScreen ? { marginRight: "" } : {}}
                >
                  <div
                    className={classes["client-card"]}
                    style={
                      largeScreen ? { justifyContent: "space-between" } : {}
                    }
                  >
                    <div className={classes["info-container"]}>
                      {/* <span className={classes["name"]}> */}
                      <span>
                        {/* https://react-hook-form.com/api/usecontroller/controller */}
                        {/* Controller is required with 3rd party libraries (like MUI) */}
                        <Controller
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                          }) => (
                            <TextField
                              autoFocus
                              value={value}
                              onChange={onChange} // send value to hook form
                              onBlur={onBlur} // notify when input is touched
                              inputRef={ref} // wire up the input ref
                              inputProps={{
                                style: {
                                  fontSize: largeScreen ? "2rem" : "1.4rem",
                                  fontWeight: 800,
                                  padding: 3,
                                },
                                className: classes.name,
                              }}
                              //as fucked up as it is, there are two inputprops-check case
                              //https://stackoverflow.com/questions/70544205/material-ui-inputadornment-not-showing-anything
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {customerNameIcon}
                                  </InputAdornment>
                                ),
                              }}
                              sx={
                                isNameInputLocked
                                  ? {
                                      "& fieldset": {
                                        border: "none",
                                      },
                                      "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#000036",
                                      },
                                    }
                                  : {
                                      "& fieldset": {
                                        border: "solid",
                                        //padding: "0px",
                                      },
                                    }
                              }
                              disabled={isNameInputLocked}
                            />
                          )}
                          name="customerName"
                          control={control}
                          rules={{ required: true }}
                        />
                        {/* if Customer has a verified name, show the verified icon */}

                        {/* https://github.com/react-hook-form/react-hook-form/issues/5947 */}
                        {/* https://stackoverflow.com/questions/69719627/how-to-focus-when-an-error-occurs-in-react-hook-form-controller */}
                      </span>
                      <div style={{ display: "block", marginLeft: 48 }}>
                        <div className={classes["phone"]}>{phoneDisplay}</div>
                        <div className={classes["phone"]}>{emailDisplay}</div>
                      </div>

                      {/* <span className={classes["email"]}>{emailDisplay}</span> */}
                      <div className={classes["utility-icons"]}>
                        {/* If NOT customerData.isSpam */}
                        {!customerData.isSpam && (
                          <Tooltip title="Report Spam">
                            <IconButton
                              aria-label="report-spam"
                              color="secondary"
                              onClick={(e) => {
                                handleReportSpamClick();
                              }}

                              //sx={largeScreen ? { marginRight: 1 } : { marginRight: 8 }}
                            >
                              {<ReportGmailerrorredIcon />}
                            </IconButton>
                          </Tooltip>
                        )}
                        {/* If IS customerData.isSpam */}
                        {customerData.isSpam && (
                          <Tooltip title="Marked as Spam">
                            <IconButton
                              aria-label="report-spam"
                              color="secondary"
                              onClick={() => {
                                handleReportSpamClick();
                              }}

                              //sx={largeScreen ? { marginRight: 1 } : { marginRight: 8 }}
                            >
                              {<ReportGmailerrorredIcon color="warning" />}
                            </IconButton>
                          </Tooltip>
                        )}
                        <ConfirmationDialog
                          children={null}
                          title={
                            isEligibleForSpam && customerData.isSpam
                              ? "Retract Spam Report"
                              : isEligibleForSpam && !customerData.isSpam
                              ? "Report Spam"
                              : !isEligibleForSpam
                              ? "Sorry, but you can't report this as Spam"
                              : ""
                          }
                          open={isSpamConfirmationDialogOpen}
                          message={
                            isEligibleForSpam && customerData.isSpam
                              ? "This lead has been reported as spam.  Do you want to retract the spam report?"
                              : isEligibleForSpam && !customerData.isSpam
                              ? "Would you like to report this lead as spam? We will filter this lead from your dashboard and it will not be counted as a genuine customer lead."
                              : !isEligibleForSpam
                              ? "There is not enough information available to determine if this is Spam.  This usually happens when you only have 1 or 2 missed calls from a customer and no voicemails. We don't want to risk blocking a real customer from contacting you."
                              : ""
                          }
                          setOpen={setIsSpamConfirmationDialogOpen}
                          onConfirm={
                            isEligibleForSpam ? handleConfirmSpam : null
                          }
                        />
                        <Tooltip title="Edit Name">
                          <IconButton
                            aria-label="edit-customer"
                            color="secondary"
                            onClick={(e) => {
                              handleCustomerEditNameToggle(true);
                            }}
                          >
                            {isNameInputLocked ? (
                              <ModeEditIcon />
                            ) : (
                              <DoneIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-evenly"
                  sx={{ bottom: "0px" }}
                >
                  <StatusStepper
                    //@ts-ignore
                    fullWidth={width}
                    //@ts-ignore
                    fullHeight={height}
                  />
                  <ContactViaReviewRequestDialog />
                </Grid>
              </Grid>
            </div>
          )}
        </AutoSizer>
      </>
    );
  } else return <div>Hi. Something went wrong.</div>;
};

export default LeadHeader;
