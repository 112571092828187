import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { getBrandOptimizationScore } from "../util/performanceData";
import { useGetCustomersQuery, useGetBrandQuery } from "../store/api";
import { useParams } from "react-router";
import { useMemo, useState } from "react";
import dayjs, { Dayjs } from "dayjs";

const BrandPerformanceCard: React.FunctionComponent = () => {
  const params = useParams();
  const brandId: string | undefined = params.brandId;

  const {
    data: customersData,
    error: customersError,
    isLoading: customersIsLoading,
  } = useGetCustomersQuery({ brandId: brandId! });

  const {
    data: brandData,
    error: brandError,
    isLoading: brandIsLoading,
  } = useGetBrandQuery({ brandId: brandId! });

  const [brandOptimizationStats, setBrandOptimizationStats]: [
    (
      | {
          BPOscore: number;
          CPOscore: number;
          SPOscore: number;
          targetCPGF: number;
          actualCPGF: number;
          actualCAC: number;
          closingRate: number;
          goodFits: number;
          sales: number;
          startDate: Dayjs;
          endDate: Dayjs;
        }
      | undefined
    ),
    any
  ] = useState();

  useMemo(async () => {
    if (brandData && customersData) {
      const startDate = dayjs().subtract(12, "month");
      const endDate = dayjs();
      const brandOptimizationStats = await getBrandOptimizationScore(
        brandData,
        customersData,
        startDate,
        endDate
      );

      if (brandOptimizationStats && brandOptimizationStats.BPOscore) {
        setBrandOptimizationStats(brandOptimizationStats);
      }
    }
  }, [customersData, brandData]);

  if (
    brandOptimizationStats &&
    brandData &&
    brandData.customerAvgLifetimeValue
  ) {
    return (
      <Box sx={{ minWidth: 275 }}>
        <Card variant="outlined">
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Adsurgent Performance
            </Typography>
            <Typography variant="h1" component="div">
              {brandOptimizationStats.BPOscore}%
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {brandData.name} Performance
            </Typography>
            <Typography variant="h1" component="div">
              {brandOptimizationStats.CPOscore}%
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Overall Performance
            </Typography>
            <Typography variant="h1" component="div">
              {brandOptimizationStats.SPOscore}%
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              Last{" "}
              {brandOptimizationStats.endDate.diff(
                brandOptimizationStats.startDate,
                "month"
              )}{" "}
              Months
            </Typography>
            <Typography variant="body2">
              BPO = Target CPGF: ${brandOptimizationStats.targetCPGF} / Actual
              CPGF: ${brandOptimizationStats.actualCPGF}
              <br />
              Good Fits: {brandOptimizationStats.goodFits}
              <br />
              Sales: {brandOptimizationStats.sales}
              <br />
              Assumes customer lifetime value (LTV): $
              {brandData.customerAvgLifetimeValue}
              <br />& Target Customer Acquisition (CAC) cost of $
              {(brandData.cacPercentage! * brandData.customerAvgLifetimeValue) /
                100}{" "}
              ({brandData.cacPercentage}% of LTV) <br />
              Your actual CAC is ${brandOptimizationStats.actualCAC}
              <br />
              Your Good-Fit closing rate is {brandOptimizationStats.closingRate}
              %, while warm lead sales conversions across all industries range
              from 30-50% as a general rule
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small">Learn More</Button>
          </CardActions>
        </Card>
      </Box>
    );
  } else return <div>Loading...</div>;
};

export default BrandPerformanceCard;
