import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
//import { contactActions } from "../store/contact-slice";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { MenuItem } from "@mui/material";
import NumberFormat from "react-number-format";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { IconButton } from "@mui/material";
import { ClassNames } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import { RootState } from "../store";
import { Phone, Customer } from "../types";
import { uiActions } from "../store/uiSlice";
//import CustomerEditDialog from "./CustomerEditDialog";
import {
  useGetCustomerQuery,
  useUpdateCustomerMutation,
  useGetBrandQuery,
} from "../store/api";
import { useParams } from "react-router-dom";
import {
  getCustomerPhone,
  getCustomerPhones,
  getBusinessPhone,
  getTrackingPhone,
  formatPhoneNumber,
} from "../util/util";
import { useCreateCallMutation } from "../store/api";
import { useState, useEffect } from "react";
//we are using react-hook-form to manage forms, but
//we need to utilize their Controller feature to make it work
//with Material UI (MUI), and Controller takes care of registration process
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import logger from "../logger";
type OutboundPhone = number | null;

const ContactViaPhoneDialog: React.FunctionComponent = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const ui = useSelector((state: RootState) => state.ui);

  const params = useParams();
  const brandId: string = params.brandId!;
  const customerId: string = params.customerId!;

  //we are already checking isLoading & error in LeadHistory (wrapper)
  //so we can just assert the customerData here
  const {
    data: customerData,
    // error: customerError,
    // isLoading: customerIsLoading,
  } = useGetCustomerQuery({ brandId: brandId, customerId: customerId }, {});

  const {
    data: brandData,
    // error: customerError,
    // isLoading: customerIsLoading,
  } = useGetBrandQuery({ brandId: brandId });

  const { control, reset, watch } =
    useForm(/*{
    defaultValues: {
      outboundPhoneNumber: "",
    },
  }*/);

  //react-hook-form has a useful way of "watching" field inputs, without causing a re-render
  //this is helpful when you don't need the change to appear on screen (re-render), but just
  //need the input value to send somewhere, but in our case we need it
  //https://www.youtube.com/watch?v=3qLd69WMqKk

  useEffect(() => {
    if (brandData && customerData) {
      let defaultValues: any = {};
      defaultValues.businessPhoneNumber = getBusinessPhone(
        brandData,
        customerData
      );
      defaultValues.customerPhoneNumber = getCustomerPhone(customerData);
      //logger.info("outboundPhoneNumber: ", defaultValues.businessPhoneNumber);
      reset({ ...defaultValues });
    }
  }, [customerData]);

  const businessPhone = watch("businessPhoneNumber");

  const [
    createCall, // This is the mutation trigger
    { isLoading: isCreatingCall }, // This is the destructured mutation result
  ] = useCreateCallMutation();

  // const customer: Customer = ui.customer;
  const dispatch = useDispatch();
  const [readyAlertOpen, setReadyAlertOpen] = React.useState(false);
  const [notReadyAlertOpen, setNotReadyAlertOpen] = React.useState(false);
  //let's set phone number for the dropdown select

  //look through the phones that are available on the messages
  //we should default to the most recent phone number, but perhaps give an option
  //of any previously used phone number as well
  //next, let's hit the new endpoint with the correct phone numbers to initiate the call
  if (customerId && customerData && brandData) {
    const customerPhoneNumbers = getCustomerPhones(customerData);
    const customerPhone = getCustomerPhone(customerData);
    // const businessPhone = getBusinessPhone(customerData);

    const trackingPhone = getTrackingPhone(brandData, customerData);
    // logger.info("trackingPhone:", trackingPhone);
    // logger.info("businessPhone:", businessPhone);
    // logger.info("customerPhone:", customerPhone);

    const customerName = customerData!.verifiedCustomerName
      ? customerData!.verifiedCustomerName
      : customerPhone;

    const handleClose = () => {
      dispatch(uiActions.togglePhoneDialog(false));
    };

    const handleOpen = () => {
      uiActions.togglePhoneDialog(true);
    };

    const handleCreateCall = async () => {
      dispatch(uiActions.togglePhoneDialog(false));

      if (trackingPhone && customerPhone && businessPhone) {
        setReadyAlertOpen(true);
        const result = await createCall({
          brandId: customerData.brandId,
          //phone number for API call should be in format "15552146065"
          callerIdPhone: trackingPhone.toString().replace("+", ""),
          // businessPhone: getBusinessPhone(customerData)
          //   .toString()
          //   .replace("+", ""),
          businessPhone: businessPhone,
          customerPhone: getCustomerPhone(customerData)
            .toString()
            .replace("+", ""),
        });
      } else {
        setNotReadyAlertOpen(true);
        logger.error(
          "Sorry, but we can't create a phone call.  We're missing a required phone number (trackingPhone, businessPhone, or customerPhone)."
        );
      }
    };

    // const handlePhoneChange = (event) => {
    //   setPhone(event.target.value);
    // };

    //handle alerts

    const handleReadyAlert = () => {
      setReadyAlertOpen(!readyAlertOpen);
    };

    const handleNotReadyAlert = () => {
      setNotReadyAlertOpen(!notReadyAlertOpen);
    };

    return (
      <>
        <Dialog
          fullScreen={fullScreen}
          open={ui.isPhoneDialogOpen}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          PaperProps={{
            sx: {
              minHeight: 400,
              width: fullScreen ? null : 400,
            },
          }}
        >
          <DialogTitle id="responsive-dialog-title">
            <div style={{ float: "right" }}>
              <div
                style={{
                  fontSize: ".7rem",
                  fontStyle: "italic",
                  textAlign: "right",
                }}
              >
                {brandData?.name} Caller ID
              </div>
              <div style={{ fontSize: "1rem" }}>
                {trackingPhone
                  ? formatPhoneNumber(trackingPhone.toString())
                  : ""}
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            {/* https://react-hook-form.com/api/usecontroller/controller */}
            {/* Controller is required with 3rd party libraries (like MUI) */}
            <Controller
              name="businessPhoneNumber"
              control={control}
              rules={{ required: true }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
              }) => (
                //react-input-mask
                <InputMask
                  mask="+1 (999) 999-9999"
                  value={value}
                  disabled={false}
                  onChange={(value): void => {
                    onChange(value);
                    //changeHandler(value);
                  }}
                >
                  {/* self invoking fucntion to return the MUI TextField */}
                  {(props: any) => (
                    //MUI TextField
                    <TextField
                      autoFocus={businessPhone ? false : true}
                      value={props.value}
                      onChange={props.onChange} // send value to hook form
                      onBlur={props.onBlur} // notify when input is touched
                      inputRef={props.ref} // wire up the input ref
                      inputProps={{
                        // style: {
                        //   fontSize: "2rem",
                        //   fontWeight: 800,
                        //   padding: 3,
                        // },

                        className: "",
                      }}
                      sx={{
                        marginTop: "20px",
                        width: "100%",
                      }}
                      label="Your Number (Changeable)"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      //disabled={isNameInputLocked}
                    />
                  )}
                </InputMask>
              )}
            />

            {/* <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Your Number (Changeable)"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  sx={{
                    marginTop: "20px",
                    width: "100%",
                  }}
                />
              )}
              name="businessPhoneNumber"
              control={control}
            /> */}

            {/*phone number to call select from available phone #s */}
            <div>
              <Controller
                render={({ field }) => (
                  <TextField
                    select
                    label="Customer Number"
                    {...field}
                    sx={{
                      marginTop: "20px",
                      width: "100%",
                    }}
                  >
                    {" "}
                    {customerPhoneNumbers
                      ? customerPhoneNumbers.all.map((phoneNumber, index) => {
                          if (phoneNumber) {
                            return (
                              <option value={phoneNumber}>
                                {formatPhoneNumber(phoneNumber.toString())}
                              </option>
                            );
                          }
                        })
                      : ""}
                    {/* <option value={10}>Ten</option>
                    <option value={20}>Twenty</option>
                    <option value={30}>Thirty</option>{" "} */}
                  </TextField>
                )}
                name="customerPhoneNumber"
                control={control}
              />
            </div>
            <div style={{ fontSize: "small" }}>
              <p>
                Your phone will ring first. Please answer the call and press 1
                to connect to your customer.
              </p>
              <p>
                Using the outbound call features ensures that all of your
                customer communications are accessible on your dashboard - and
                your brand phone numbers remain consistent to your customers.
              </p>
            </div>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <Tooltip title="Initiate Call">
              <IconButton
                color="primary"
                aria-label="initiate call"
                sx={{ height: "80px", width: "80px" }}
              >
                <LocalPhoneIcon
                  sx={{ fontSize: "60px" }}
                  onClick={handleCreateCall}
                />
              </IconButton>
            </Tooltip>

            <Tooltip title="Cancel">
              <IconButton color="primary" aria-label="close call dialog">
                <CloseIcon onClick={handleClose} />
              </IconButton>
            </Tooltip>
          </DialogActions>
        </Dialog>

        <Dialog open={readyAlertOpen} onClose={handleReadyAlert}>
          <Alert
            severity="success"
            color="success"
            role="button"
            icon={<PhoneCallbackIcon />}
            onClose={() => {
              handleReadyAlert();
            }}
            closeText="Got it."
            sx={{
              // width: '80%',
              // margin: 'auto',
              "& .MuiAlert-icon": {
                color: "blue",
              },
              //backgroundColor: "green"
            }}
          >
            <AlertTitle>Calling you first...</AlertTitle>
            Make sure to answer and accept the call. We will then connect you to{" "}
            {customerName}. You may dismiss this alert.
          </Alert>
        </Dialog>

        <Dialog open={notReadyAlertOpen} onClose={handleNotReadyAlert}>
          <Alert
            severity="error"
            color="error"
            role="button"
            icon={<PhoneCallbackIcon />}
            onClose={() => {
              handleNotReadyAlert();
            }}
            closeText="Got it."
            sx={{
              // width: '80%',
              // margin: 'auto',
              "& .MuiAlert-icon": {
                color: "red",
              },
              //backgroundColor: "green"
            }}
          >
            <AlertTitle>Oops!</AlertTitle>
            Sorry, but we're missing one of the required phone numbers we need
            to make this call. Please correct your phone number or contact
            Adsurgent support.
          </Alert>
        </Dialog>
      </>
    );
  } else return <div></div>;
};
export default ContactViaPhoneDialog;
