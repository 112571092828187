import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import classes from "./StatusStepper.module.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ContactViaReviewRequestDialog from "./ContactViaReviewRequestDialog";
import { uiActions } from "../store/uiSlice";
import { RootState } from "../store";
import {
  useGetBrandQuery,
  useGetCustomerQuery,
  useUpdateCustomerMutation,
} from "../store/api";
import { useParams } from "react-router-dom";
import { Customer, Brand, BrandStatus } from "../types";
import logger from "../logger";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#ff6d75",
  },
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
    transform: "none",
  },
});

function RatingsRectangle(props: { status: string; width: number }) {
  const status = props.status;
  const rectangleWidth = props.width;

  const divStyle = {
    width: rectangleWidth,
  };

  if (status === "filled") {
    return (
      <div
        style={divStyle}
        className={`${classes["ratings-rectangle"]} ${classes["ratings-rectangle-filled"]}`}
      ></div>
    );
  } else
    return (
      <div
        style={divStyle}
        className={`${classes["ratings-rectangle"]} ${classes["ratings-rectangle-empty"]}`}
      ></div>
    );
}

// const LeadHistory: React.FunctionComponent<{ winHeight: number }> = ({
//   children,
//   winHeight,
// }) => {

const StatusStepper: React.FunctionComponent<{
  fullWidth: number;
  fullHeight: number;
}> = ({ fullWidth, fullHeight }) => {
  // const statuses = useSelector((state) => state.statuses);
  const dispatch = useDispatch();
  // const customer = useSelector((state) => state.customer);
  const ui = useSelector((state: RootState) => state.ui);
  // const brand: Brand = ui.brand;
  // const customer: Customer = ui.customer;
  const params = useParams();
  const brandId: string = params.brandId!;
  const customerId: string = params.customerId!;
  //we are already checking isLoading & error in LeadHistory (wrapper)
  //so we can just assert the customerData here
  const { data: customerData } = useGetCustomerQuery(
    { brandId: brandId, customerId: customerId },
    {}
  );
  const [updateCustomer, result] = useUpdateCustomerMutation();

  const { data: brandData } = useGetBrandQuery({ brandId: brandId }, {});
  //const brandStatuses: BrandStatus[] = brandData.statuses;
  const [hover, setHover] = React.useState(-1);
  // logger.info(
  //   "Brand Id: " + brandId,
  //   "Customer Id: " + customerId,
  //   "Sales Satus: " + customerData!.salesStatus,
  //   "Brand Data: " + JSON.stringify(brandData),
  //   "Statuses: " + brandData!.statuses
  // );
  if (
    brandId &&
    customerId &&
    customerData &&
    typeof customerData.salesStatus === "number" &&
    brandData &&
    brandData.statuses
  ) {
    const leadCurrentStatus: number = customerData.salesStatus;
    const statusLabels: string[] = [];
    {
      brandData.statuses.map((status) => statusLabels.push(status.label));
    }

    const statusChangeHandler = (clickedStatus: number) => {
      //update the status in redux state slice
      // dispatch(uiActions.updateStatus(status));
      //customerData.salesStatus = clickedStatus;
      try {
        //logger.info(customerData.id, customerData.brandId, "salesStatus", clickedStatus );
        const updateCustomerResult = updateCustomer({
          brandId: customerData.brandId,
          customerId: customerData.id,
          propertyName: "salesStatus",
          propertyValue: clickedStatus,
        }).unwrap();
        //logger.info("fulfilled", updateCustomerResult);
      } catch (error) {
        logger.error("rejected", error);
      }

      //if it's the singular isSale status, then trigger
      //the review request dialog here
      const reviewRequestDialogToggleHandler = (isOpen: boolean) => {
        dispatch(uiActions.toggleReviewRequestDialog(isOpen));
      };
      const isSaleStatus: BrandStatus | undefined = brandData.statuses!.find(
        (status: BrandStatus) =>
          status.isSale === true && status.isPostSale === false
      );

      if (clickedStatus === isSaleStatus?.id) {
        reviewRequestDialogToggleHandler(true);
      }
    };

    const statusBoxCount = brandData.statuses.length - 1;
    //const fullWidth = props.fullWidth;
    const rectangleXMargins = 0;
    const rectangleWidth = fullWidth / statusBoxCount - rectangleXMargins;

    return (
      <>
        <Box
          sx={{
            "& > legend": { mt: 2 },
          }}
          width="100%"
        >
          <StyledRating
            name="customized-color"
            value={leadCurrentStatus}
            getLabelText={(value) => `${value} Status${value !== 1 ? "s" : ""}`}
            precision={1}
            icon={<RatingsRectangle status="filled" width={rectangleWidth} />}
            emptyIcon={
              <RatingsRectangle status="empty" width={rectangleWidth} />
            }
            sx={{
              width: "100%",
            }}
            max={statusBoxCount}
            onChange={(event, value) => {
              //we don't want to send null here when empty, but rather "0", so...
              const newValue = value ?? 0;
              statusChangeHandler(newValue);
            }}
            onChangeActive={(event, value) => {
              const newHover = value ?? 0;
              setHover(newHover);
            }}
          />
          {customerData.salesStatus !== null && (
            <Box className={classes["rating-label"]} sx={{ ml: 2 }}>
              {statusLabels[hover !== -1 ? hover : customerData.salesStatus]}
            </Box>
          )}
        </Box>
        <ContactViaReviewRequestDialog />
      </>
    );
  } else
    return (
      <div>
        Sorry, customer ID, sales status, brand ID, or brand statuses not
        available.
      </div>
    );
};

export default StatusStepper;
