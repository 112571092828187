// ConfirmDialog.jsx
// material ui
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";

import Close from "@mui/icons-material/Close";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";

const ConfirmationDialog: React.FunctionComponent<{
  children: any;
  title: string;
  message: string;
  open: boolean;
  setOpen: Function;
  onConfirm: Function | null;
}> = (props) => {
  const { title, message, open, setOpen, onConfirm } = props;
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
        {/* if an onConfirm function exists, show the button, else we'll only show cancel */}
        {onConfirm && (
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              setOpen(false);
              onConfirm();
            }}
          >
            Confirm
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
