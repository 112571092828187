import React from "react";
import UpgradesList from "../components/UpgradesList";

const NewUpgradesListPage: React.FunctionComponent = (props) => {
  return (
    <>
      <UpgradesList />
    </>
  );
};

export default NewUpgradesListPage;
