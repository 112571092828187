import { FixedSizeList } from "react-window";
import DynamicList, { createCache } from "react-window-dynamic-list";
import {
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  unstable_useId,
} from "@mui/material";
import classes from "./Messages.module.css";
import { useSelector } from "react-redux";
import AutoSizer from "react-virtualized-auto-sizer";
import SmsTwoToneIcon from "@mui/icons-material/SmsTwoTone";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AlternateEmailTwoToneIcon from "@mui/icons-material/AlternateEmailTwoTone";
import ForwardToInboxTwoToneIcon from "@mui/icons-material/ForwardToInboxTwoTone";
import HelpCenterTwoToneIcon from "@mui/icons-material/HelpCenterTwoTone";
import PhoneMissedIcon from "@mui/icons-material/PhoneMissed";
import LanguageIcon from "@mui/icons-material/Language";
import Tooltip from "@mui/material/Tooltip";
//import Tooltip from "@mui/material";
import { Fragment } from "react";
import { flexbox, maxWidth } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { Brand, Customer, Message, PendingTextMessage } from "../types";
import { RootState } from "../store";
import { useParams } from "react-router-dom";
import { useGetCustomerQuery, useGetBrandQuery } from "../store/api";
import { format, render, cancel, register } from "timeago.js";
import AudioPlayer from "./AudioPlayerNew";
import { v4 as uuid } from "uuid";
import { selectCurrentToken } from "../store/authSlice";
import selectIsCustomerFetchStopped from "../store/uiSlice";
import dayjs from "dayjs";
import parse from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import { adsurgentHumanDateTime } from "../util/util";
import { getFileSignedUrl } from "../util/fetchFiles";
import LoadingComponent from "./LoadingComponent";
import { uiActions } from "../store/uiSlice";
import { useDispatch } from "react-redux";
import { useLayoutEffect } from "react";
import logger from "../logger";
// import 'react-h5-audio-player/lib/styles.less' Use LESS
// import 'react-h5-audio-player/src/styles.scss' Use SASS

const cache = createCache();

function makeid(length: number) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

// export type Message = {
//   id: string;
//   brandId: string;
//   companyName: string;
//   customerName: string;
//   customerId?: string;
//   timestamp: string;
//   source: string;
//   type: string;
//   customerPhone: number | null;
//   businessPhone: number | null;
//   trackingPhone: number | null;
//   customerEmail: string | null;
//   businessEmail: string | null;
//   direction: string;
//   urlParams?: {
//     gclid: string | null;
//     fbclid: string | null;
//     msclkid: string | null;
//   };
//   callDetails: {
//     recordingUrl: string | null;
//     recordingFilename: string | null;
//     transcript: string | null;
//     answered: boolean;
//     duration: number; //in seconds
//   };
//   //email also refers to submissions, which are emailed
//   emailDetails: {
//     content: string | null;
//     landingUrl: string | null;
//   };
//   smsDetails: {
//     content: string | null;
//   };
//   apiToken?: string;
//   preSignedAudioUrl?: string;
// };

const getMessageFromPendingText = (pendingMessage: PendingTextMessage) => {
  const message: Message = {
    id: "pending-message",
    brandId: pendingMessage.brandId,
    companyName: "pending",
    customerName: "pending",
    customerId: pendingMessage.customerId,
    timestamp: new Date().toString(),
    source: "pending",
    type: "sms",
    customerPhone: Number(pendingMessage.customerPhone),
    businessPhone: Number(pendingMessage.businessPhone),
    trackingPhone: Number(pendingMessage.callerIdPhone),
    customerEmail: null,
    businessEmail: null,
    direction: "outgoing",
    urlParams: {
      gclid: null,
      fbclid: null,
      msclkid: null,
    },
    callDetails: {
      recordingUrl: null,
      recordingFilename: null,
      transcript: null,
      answered: false,
      duration: 0, //in seconds
    },
    //email also refers to submissions, which are emailed
    emailDetails: {
      content: null,
      landingUrl: null,
    },
    smsDetails: {
      content: pendingMessage.message,
    },
    apiToken: "null",
    preSignedAudioUrl: "null",
  };
  return message;
};

const getSignedAudioUrlFromFilename = async (
  recordingFilename: string,
  digitalOceanApiToken: string
) => {
  if (recordingFilename && digitalOceanApiToken) {
    //format required by backend API:
    //localhost:3000/api/files/aos/recordings+2022-10+CAL07869d346ed1484b8492c6ab6e5c5897.mp3
    const fileNameWithPlus = recordingFilename.replaceAll("/", "+");
    //get rid of the first "+"
    //const fileNameReformatted = fileNameWithPlus.substring(1);
    const fileUrl =
      process.env.REACT_APP_BACKEND_URL + "files/aos/" + fileNameWithPlus;
    //logger.info("File URL: " + fileUrl);

    const response = await getFileSignedUrl(fileUrl, digitalOceanApiToken);
    //logger.info(response + " this was the response.");
    if (response) {
      //logger.info("audio response: " + response);
      return response;
    } else {
      return null;
      logger.warn("No response from audio file url.");
    }
  }
};

// const getMessagesWithAudioUrl = async (
//   customerData: Customer,
//   apiToken: string
// ) => {

// };

//scrollToItem (focus on bottom message) was tricky to get working -
//this has something to do with using AutoSizer:
//https://github.com/bvaughn/react-window/issues/521

const MessagesList: React.FunctionComponent<{
  width: number;
  height: number;
}> = ({ width, height }) => {
  const ui = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();

  const apiToken = useSelector(selectCurrentToken);
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  //logger.info("selector api token: " + apiToken);
  //const ui = useSelector((state: RootState) => state.ui);
  const params = useParams();
  const brandId: string = params.brandId!;
  const customerId: string = params.customerId!;
  //we are already checking isLoading & error in LeadHistory (wrapper)
  //so we can just assert the customerData here
  const {
    //current data is the last successfully fetched data
    currentData: customerData,
    //data is the actual freshest copy
    data: freshestCustomerData,
    error: customerError,
    isLoading: customerIsLoading,
  } = useGetCustomerQuery(
    { brandId: brandId, customerId: customerId },
    { pollingInterval: 3000, skip: ui.isCustomerFetchStopped ? true : false }
  );

  //let's add the apiToken to each object in the array, so that we have the token available in RenderRow
  //... this was a headache, but we can't use useSelector to get the token
  //inside of RenderRow since it's not a react component

  //const { data: brandData } = useGetBrandQuery({ brandId: brandId }, {});
  const listRef = useRef<any>();

  const [messages, setMessages]: [any, any] = useState();

  useEffect(() => {
    //let's make sure the window goes to the bottom of the messages
    if (messages) {
      const lastItem = messages.length;
      listRef.current.scrollToItem(lastItem);
    }
  }, [messages]);

  useEffect(() => {
    ///
    //We need to get a presigned url for each Digital Ocean Spaces (S3-style) file
    //and add it to each message, so we can use it in the AudioPlayer
    //
    if (customerData && apiToken) {
      const mutateMessages = async () => {
        if (customerData && customerData.messages) {
          //iterate over each message and add the apiToken & pre-signed Audio Url
          const messagesWithToken = await Promise.all(
            customerData.messages.map(async (message) => {
              const fetchAudioUrl = async () => {
                if (
                  message.callDetails &&
                  message.callDetails.recordingFilename &&
                  apiToken
                ) {
                  const url = await getSignedAudioUrlFromFilename(
                    message.callDetails.recordingFilename,
                    apiToken
                  );
                  return url;
                } else return null;
              };
              const extendedMessage = {
                ...message,
                apiToken: apiToken,
                preSignedAudioUrl: await fetchAudioUrl(),
              };
              return extendedMessage;
            })
          );

          //sort the new array by last message timestamp
          const messagesWithTokenSorted = messagesWithToken.sort((a, b) => {
            return (
              (new Date(a.timestamp) as any) - (new Date(b.timestamp) as any)
            );
          });
          //now look to see if any pending messages are missing
          //if missing, add it here.  If not, null out the pending message
          const pendingTextMessage: PendingTextMessage = ui.pendingMessage;
          //logger.info("pendingTextMessage: ", pendingTextMessage);
          if (
            pendingTextMessage &&
            pendingTextMessage.brandId === brandId &&
            pendingTextMessage.customerId === customerId &&
            !messagesWithTokenSorted.find(
              (message) =>
                message.smsDetails &&
                message.smsDetails.content &&
                message.smsDetails.content === pendingTextMessage.message
            )
          ) {
            const pendingTextMessageConverted: Message =
              getMessageFromPendingText(pendingTextMessage);
            messagesWithTokenSorted.push(pendingTextMessageConverted);
            setMessages(messagesWithTokenSorted);
          } else {
            //need to also null out the pending text message here

            setMessages(messagesWithTokenSorted);
            //dispatch(uiActions.setPendingMessage(null));
          }
        }
      };
      mutateMessages();
    }
  }, [customerData, customerId]);

  if (customerError) {
    logger.error("errror getting customer..." + " : " + customerError);
    return <div>Sorry, error getting customer...</div>;
  } else if (customerIsLoading) {
    logger.info("customer is loading...");
    return <div>Hello, is loading...</div>;
  } else if (messages && customerData && customerData.id === customerId) {
    //logger.info(messagesWithToken); //when state changes, scroll to the last item in messages

    return (
      <DynamicList
        itemData={{
          messages: messages,
          divHeight: height,
          divWidth: width,
          largeScreen,
        }}
        data={messages}
        //itemSize={200}
        height={height}
        width={width}
        className={classes.list}
        ref={listRef}
        cache={cache}
        lazyMeasurement={true}
        recalculateItemsOnResize={{ width: true, height: true }}

        //style={{divWidth:width, }}
      >
        {/*render row using function below, using the properties above*/}
        {RenderRow}
      </DynamicList>
    );
  } else {
    return (
      <div
        style={{
          height: height,
          width: width,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingComponent statusMsg={null} />
      </div>
    );
  }
};

const RenderRow: React.FunctionComponent<{
  index: any;
  style: any;
  data: {
    messages: Message[];
    divHeight: number;
    divWidth: number;
    largeScreen: boolean;
  };
}> = ({ index, style, data }) => {
  const dateObject = new Date(data.messages[index].timestamp);
  const humanReadableTimestamp = adsurgentHumanDateTime(dateObject);
  const time = dayjs(data.messages[index].timestamp).format("h:mma");
  //logger.info("Time:" + time);
  //const recordingUrl = undefined;
  //const randomString = uuid();
  //const digitalOceanApiToken = data.messages[index].apiToken;
  const preSignedAudioUrl = data.messages[index].preSignedAudioUrl;
  // const [preSignedAudioUrl, setPreSignedAudioUrl] = useState();

  // useEffect(() => {
  //   const fetchAndSetAudioUrl = async () => {
  //     const url = await getSignedAudioUrlFromFilename(
  //       data.messages[index].callDetails.recordingFilename!,
  //       digitalOceanApiToken!
  //     );
  //     setPreSignedAudioUrl(url);
  //   };
  //   fetchAndSetAudioUrl();
  // }, [data]);
  //logger.info("API token AFTER: " + apiToken);
  //logger.info(data[index].smsDetails.content);

  //don't ask me why this needs so many steps, but TS was throwing errors otherwise.
  const rawFormSubmissionContent =
    data.messages[index].emailDetails &&
    data.messages[index].emailDetails.content
      ? data.messages[index].emailDetails.content
      : " ";
  const formContentSanitized = rawFormSubmissionContent
    ? sanitizeHtml(rawFormSubmissionContent)
    : " ";
  const parsedFormContent = parse(formContentSanitized);
  //const formContent = parsedFormContent;

  return (
    <>
      <ListItem
        key={index + "-" + data.messages[index].id}
        style={style}
        sx={
          data.messages[index].direction === "outbound" ||
          data.messages[index].direction === "outgoing"
            ? { justifyContent: "flex-end" }
            : { justifyContent: "flex-start" }
        }
      >
        <div
          style={
            data.messages[index].direction === "outbound" ||
            data.messages[index].direction === "outgoing"
              ? {
                  backgroundColor: "#b8c6db",
                  borderRadius: "15px 0px 15px 15px",
                  display: "flex",
                }
              : data.messages[index].direction === "inbound" &&
                data.messages[index].callDetails.recordingFilename
              ? {
                  backgroundColor: "#f4f4f4",
                  borderRadius: "0px 15px 15px 15px",
                  display: "flex",
                }
              : //missed inboundcalls styling below
              data.messages[index].direction === "inbound" &&
                !data.messages[index].callDetails.recordingFilename
              ? {
                  display: "flex",
                  alignItems: "center",
                  border: "solid",
                  borderWidth: "1px",
                  borderColor: "#ccc",
                  borderRadius: "0px 15px 15px 15px",
                }
              : //inbound text message styling
              data.messages[index].direction === "incoming" &&
                data.messages[index].type === "sms"
              ? {
                  backgroundColor: "#f4f4f4",
                  borderRadius: "0px 15px 15px 15px",
                  display: "flex",
                }
              : {
                  display: "flex",
                  alignItems: "center",
                  border: "solid",
                  borderWidth: "1px",
                  borderColor: "#ccc",
                  borderRadius: "0px 15px 15px 15px",
                }
          }
        >
          <ListItemText
            sx={{
              paddingLeft: "15px",
              //width: ,
            }}
            primary={
              data.messages[index].type == "call" &&
              data.messages[index].callDetails &&
              data.messages[index].callDetails.recordingFilename !== null ? (
                //we need a decent size canvas for Waveform Audio to draw upon
                <div
                  style={
                    data.largeScreen
                      ? { minWidth: "350px" }
                      : { minWidth: "175px" }
                  }
                >
                  {preSignedAudioUrl && (
                    <AudioPlayer
                      audioUrl={preSignedAudioUrl}
                      callDate={humanReadableTimestamp + " " + time}
                    />
                  )}
                  {/* <AudioPlayer
                    recordingFilename={
                      data.messages[index].callDetails.recordingFilename!
                    }
                    messageId={data.messages[index].id}
                    bearerToken={apiToken ? apiToken : ""}
                    callDate={humanReadableTimestamp + " " + time}
                  /> */}
                </div>
              ) : data.messages[index].type == "call" ? (
                <div
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {"Missed call: " + humanReadableTimestamp + " " + time}
                </div>
              ) : data.messages[index].type == "sms" &&
                data.messages[index].smsDetails &&
                data.messages[index].smsDetails.content ? (
                <>
                  <div
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {data.messages[index].smsDetails.content}
                  </div>
                  <div
                    style={{
                      fontSize: "11px",
                    }}
                  >
                    {humanReadableTimestamp + " " + time}
                  </div>
                </>
              ) : data.messages[index].type == "form-submission" &&
                data.messages[index].emailDetails &&
                data.messages[index].emailDetails.content ? (
                <div
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <div>{parsedFormContent}</div>
                  <div>{humanReadableTimestamp + " " + time}</div>
                </div>
              ) : (
                <div
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {"Missing content."}
                </div>
              )
            }
            //secondary={humanReadableTimestamp + data[index].customerPhone}
            // secondaryTypographyProps={{
            //   sx: {
            //     textAlign: "center",
            //     fontSize: "small",
            //     textTransform: "uppercase",
            //   },
            // }}
          />
          <ListItemIcon
            sx={{
              margin: "8px 0px 5px 10px",
            }}
          >
            {data.messages[index].type === "sms" ? (
              // <Tooltip title="SMS/Text">
              <div>
                <SmsTwoToneIcon />
              </div>
            ) : //  </Tooltip>
            data.messages[index].type === "email" ? (
              //<Tooltip title="E-Mail">
              <div>
                <AlternateEmailTwoToneIcon />
              </div>
            ) : //</Tooltip>
            data.messages[index].type === "call" &&
              data.messages[index].callDetails.answered === true ? (
              //call answered, show answered call icon
              // <Tooltip title="Answered Phone Call">
              <div>
                <LocalPhoneIcon />{" "}
              </div>
            ) : // </Tooltip>
            data.messages[index].type === "call" &&
              data.messages[index].callDetails.answered === false ? (
              //call not answered, show missed call icon
              // <Tooltip title="Missed Phone Call">
              <div>
                <PhoneMissedIcon color="warning" />{" "}
              </div>
            ) : // </Tooltip>
            data.messages[index].type === "form-submission" ? (
              // <Tooltip title="Form Submission">
              <div>
                <ForwardToInboxTwoToneIcon />
              </div>
            ) : (
              // </Tooltip>
              // <Tooltip title="Unknown">
              <div>
                <HelpCenterTwoToneIcon />
              </div>
              // </Tooltip>
            )}
          </ListItemIcon>
        </div>
        {/* <div className={classes.timestamps}>
          <div className={classes.timestamp}>{humanReadableTimestamp}</div>
        </div> */}
      </ListItem>
    </>
  );
};

const Messages: React.FunctionComponent<{ height: number }> = ({
  children,
  height,
}) => {
  return (
    <AutoSizer>
      {({ width, height }) => (
        <MessagesList
          //@ts-ignore
          width={width}
          //@ts-ignore
          height={height}
        />
      )}
    </AutoSizer>
  );
};

export default Messages;
