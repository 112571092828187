import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { uiActions } from "../store/uiSlice";
import { useDispatch } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { TextField } from "@mui/material";
import { RootState } from "../store";
import { Customer, Message } from "../types";
import { useGetCustomerQuery } from "../store/api";
import { useParams } from "react-router-dom";

const ContactViaEmailDialog: React.FunctionComponent = () => {
  //const [open, setOpen] = React.useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const ui = useSelector((state: RootState) => state.ui);

  const params = useParams();
  const brandId: string = params.brandId!;
  const customerId: string = params.customerId!;

  //we are already checking isLoading & error in LeadHistory (wrapper)
  //so we can just assert the customerData here
  const {
    data: customerData,
    // error: customerError,
    // isLoading: customerIsLoading,
  } = useGetCustomerQuery({ brandId: brandId, customerId: customerId }, {});

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(uiActions.toggleEmailDialog(false));
  };

  const handleOpen = () => {
    dispatch(uiActions.toggleEmailDialog(true));
  };

  const mostRecentEmailAddress = () => {
    //array1.find(element => element > 10)
    if (customerData!.messages) {
      const firstMessageWithEmail: Message | undefined =
        customerData!.messages.find(
          (message: Message) => message.customerEmail
        );
      if (firstMessageWithEmail?.customerEmail) {
        return firstMessageWithEmail.customerEmail;
      } else return "";
    } else return "";
  };

  if (customerId && customerData) {
    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={ui.isEmailDialogOpen}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          PaperProps={
            !fullScreen
              ? {
                  sx: {
                    width: 800,
                  },
                }
              : {
                  sx: {},
                }
          }
        >
          <DialogTitle id="responsive-dialog-title">
            {"Send Email to " + customerData.verifiedCustomerName}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              From: [your@email.domain.com]
              <br />
            </DialogContentText>
            <FormControl sx={{ marginBottom: "25px", display: "inline-block" }}>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                To:
              </InputLabel>
              <NativeSelect
                defaultValue={
                  mostRecentEmailAddress() ? mostRecentEmailAddress() : ""
                }
                inputProps={{
                  name: "age",
                  id: "uncontrolled-native",
                }}
              >
                <option
                  value={
                    mostRecentEmailAddress()
                      ? mostRecentEmailAddress()
                      : undefined
                  }
                >
                  {mostRecentEmailAddress()}
                </option>
                {/*<option value={20}>Twenty</option>
              <option value={30}>Thirty</option>*/}
              </NativeSelect>
            </FormControl>

            <TextField
              fullWidth={true}
              id="email-input"
              label="Email Input"
              multiline
              rows={8}
              defaultValue=""
              sx={{ width: "100%" }}
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={handleClose} autoFocus>
              Send
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else return <div>Sorry, no customer data.</div>;
};

export default ContactViaEmailDialog;
