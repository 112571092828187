import React from "react";
import { useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import AutoSizer from "react-virtualized-auto-sizer";
import logger from "../logger";

function revokeGoogleAdsConversion() {
  logger.info("hello from revokeGooogleAdsConversion function...");
}

const NewUtilitiesPage: React.FunctionComponent = () => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const dispatch = useDispatch();

  const revokeConversionHandler = () => {
    //dispatch();
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <>
          <h1>Utilities Page</h1>
        </>
      )}
    </AutoSizer>
  );
};

export default NewUtilitiesPage;
