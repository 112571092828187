import React from "react";
import LeadDividedWindow from "../components/layout/LeadDividedWindow.js";
import { Drawer } from "@mui/material";
import { makeStyles } from "@mui/material";
import { Container } from "@mui/material";
import AutoSizer from "react-virtualized-auto-sizer";
import LeadList from "../components/layout/LeadList";
import { useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LeadHistory from "../components/layout/LeadHistory";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { uiActions } from "../store/uiSlice";
import CloseIcon from "@mui/icons-material/Close.js";
import classes from "./leads.module.css";
import { RootState } from "../store/index";
import { Outlet, useOutletContext } from "react-router-dom";
import LeadFiltering from "../components/LeadFiltering";

function getDrawerWidth(containerWidth: number) {
  const drawerWidth = containerWidth / 2;
  return drawerWidth;
}

const NewLeadsPage: React.FunctionComponent = () => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const ui = useSelector((state: RootState) => state.ui);

  // const [state, setState] = useState({
  //   top: false,
  //   left: false,
  //   bottom: false,
  //   right: false,
  // });

  const dispatch = useDispatch();

  const closeDrawerHandler = () => {
    dispatch(uiActions.toggleHistoryDrawer(false));
  };
  //const isDrawerOpen = useSelector((state) => state.ui.);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <>
          {/* LEADLIST DRAWER */}
          <Drawer
            key="drawer1"
            variant="permanent"
            anchor="left"
            PaperProps={
              largeScreen
                ? {
                    sx: {
                      width: "30%",
                    },
                    style: {
                      position: "absolute",
                      height: height,
                      marginTop: "64px",
                    },
                  }
                : {
                    sx: {
                      width: "100%",
                    },
                    style: {
                      position: "absolute",
                      height: height,
                      marginTop: "64px",
                    },
                    //height: { height },
                  }
            }
          >
            <LeadFiltering />
            <LeadList />
          </Drawer>
          <div
            style={
              largeScreen
                ? {
                    position: "absolute",
                    width: "70%",
                    height: height,
                    marginTop: "64px",
                    overflow: "hidden",
                    backgroundColor: "#ccc",
                    padding: "25px",
                    right: 0,
                  }
                : {
                    width: "100%",
                    position: "absolute",
                    height: height,
                    marginTop: "64px",
                    overflow: "hidden",
                    backgroundColor: "#ccc",
                    padding: "25px",
                  }
            }
          >
            {/* <h1>"Brand Name" At a Glance</h1>
            <p>You have answered x% of calls in the last 30 days.</p>
            <p>You have x new leads in the last 90 days.</p>
            <p>You have x leads that need your attention.  Please make sure every customer has a status.</p>
            <p>Daily Inspiration:</p>
            <p>Putty a cool video or gif here.</p> */}
          </div>
          {/* HISTORY SIDE (DRAWER) */}
          <Drawer
            open={ui.isHistoryDrawerOpen}
            key="drawer2"
            variant="persistent"
            anchor="right"
            BackdropProps={{ invisible: true }}
            transitionDuration={largeScreen ? 0 : 150}
            PaperProps={
              largeScreen
                ? {
                    sx: {
                      width: "70%",
                    },
                    style: {
                      position: "absolute",
                      height: height,
                      marginTop: "64px",
                      overflow: "hidden",
                    },
                  }
                : {
                    sx: {
                      width: "100%",
                    },
                    style: {
                      position: "absolute",
                      height: height,
                      marginTop: "64px",
                      overflow: "hidden",
                    },
                  }
            }
          >
            <CloseIcon
              onClick={() => {
                closeDrawerHandler();
              }}
              className={classes["close-button"]}
              sx={largeScreen ? { display: "none" } : { display: "block" }}
            />
            <Outlet />
          </Drawer>
        </>
      )}
    </AutoSizer>
  );
};

export default NewLeadsPage;
