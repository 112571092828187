import { ClassNames } from "@emotion/react";
import { useSelector } from "react-redux";
import classes from "./BrandSettings.module.css";
import { TextField, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { typography } from "@mui/system";
import React from "react";
import { useParams } from "react-router-dom";
//import { useGetBrandQuery } from "../store/api";
import { RootState } from "../store";
import { useGetBrandQuery, useGetUserQuery } from "../store/api";
import Button from "@mui/material/Button";
import { Brand } from "../types";
import { selectCurrentRole, selectCurrentUserId } from "../store/authSlice";
import { useEffect } from "react";
import { useState } from "react";
import { urlBase64ToUint8Array } from "../util/util";
import { useUpdateUserMutation } from "../store/api";
import ConfirmationDialog from "./ConfirmationDialog";
import logger from "../logger";

const BrandSettings: React.FunctionComponent = () => {
  const vapidPublicKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;
  const [updateUser, result] = useUpdateUserMutation();
  const params = useParams();
  const brandId: string = params.brandId!;
  const userId = useSelector(selectCurrentUserId)!;
  const userRole = useSelector(selectCurrentRole);

  logger.info("brandId:", brandId);
  logger.info("userId:", userId);
  // logger.info(
  //   "useSelector(selectCurrentUserId):",
  //   useSelector(selectCurrentUserId)
  // );

  const {
    data: userData,
    error: userError,
    isLoading: userIsLoading,
  } = useGetUserQuery({ userId: userId }, {});
  //we are checking this in the brand container for isLoading & Error, so we can
  //assert existence in this component without checking
  const {
    data: brandData,
    // error: brandError,
    // isLoading: brandIsLoading,
  } = useGetBrandQuery({ brandId: brandId }, {});

  console.log("brandData: ", brandData);
  console.log("userData: ", userData);
  console.log("userIsLoading: ", userIsLoading);
  console.log("userError: ", userError);

  const isManager = userRole === "manager" ? true : false;
  const isDeviceNotificationsEnabled =
    "Notification" in window && Notification.permission === "granted"
      ? true
      : false;

  //navigator.serviceWorker.register("sw.js");

  const [serviceWorker, setServiceWorker] = useState<ServiceWorker | null>(
    null
  );

  const [isPushConfirmationOpen, setIsPushConfirmationOpen] = useState(false);

  useEffect(() => {
    async function getServiceWorker() {
      if ("serviceWorker" in navigator) {
        try {
          //logger.info("hello from try block");
          const sw = await navigator.serviceWorker.ready;
          //logger.info("Service Worker active:", sw.active);
          setServiceWorker(sw.active);
        } catch (error) {
          logger.error("Service Worker registration failed:", error);
        }
      }
    }
    getServiceWorker();
  }, [navigator.serviceWorker]);

  // const sendSampleNotification = async () => {
  //   if (isDeviceNotificationsEnabled) {
  //     //logger.info("isDeviceNotificationsEnabled");

  //     if (serviceWorker) {
  //       logger.info("if serviceWorker...");
  //       // Use the active Service Worker
  //       serviceWorker.postMessage({
  //         type: "show-notification",
  //         title: "Sample notification!",
  //         options: { body: "this is the body" },
  //       });
  //     }
  //   }
  // };

  const handleEnableDeviceNotificationsClick = () => {
    askForNotificationPermission();
  };

  const displaySubscriptionConfirmation = () => {
    setIsPushConfirmationOpen(true);
  };

  const configurePushSubscription = async () => {
    logger.info("hello from configurePushSubscription");
    const sw = await navigator.serviceWorker.ready;
    logger.info("sw.active: ", sw.active);
    if (sw.active) {
      const subscription = await sw.pushManager.getSubscription();
      logger.info("subscription: ", subscription);
      if (!subscription) {
        logger.info("!subscription");

        //no subscription exists, let's create a new one
        if (vapidPublicKey) {
          logger.info("vapidPublicKey: ", vapidPublicKey);
          try {
            const convertedVapidPublicKey =
              urlBase64ToUint8Array(vapidPublicKey);
            const newSubscription = await sw.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: convertedVapidPublicKey,
            });
            //now send and store the newSubscription via the backend to the database
            if (brandData && newSubscription) {
              logger.info("brandData && newSubscription");
              logger.info("userData: ", JSON.stringify(userData));
              let subscriptions: PushSubscription[] =
                userData && userData.pushNotificationSubscriptions
                  ? [...userData.pushNotificationSubscriptions]
                  : [];
              subscriptions.push(newSubscription);
              logger.info("Brand Settings subscriptions: ", subscriptions);

              const res = await updateUser({
                userId: userId,
                propertyName: "pushNotificationSubscriptions",
                propertyValue: subscriptions,
              });

              if (res) {
                logger.info("updateUser response: ", res);
                displaySubscriptionConfirmation();
              }
            }
          } catch (err) {
            logger.error(
              "Error creating subscription from Brand Settings: ",
              err
            );
          }
        }
      }
    } else {
      //subcription already exists
      logger.warn("Subscription already exists.");
    }
  };

  //https://developer.mozilla.org/en-US/docs/Web/API/Notification/requestPermission
  const askForNotificationPermission = () => {
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      logger.info("Notification.permission === already granted");
      //configurePushSubscription();
    } else if (Notification.permission !== "denied") {
      logger.info(
        "Notification.permission !== denied...asking for permission."
      );
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          const notification = new Notification("Notifications enabled.");
          configurePushSubscription();
          // …
        }
      });
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them anymore.
  };

  if (userData && brandData) {
    const brand: Brand = brandData;
    return (
      <>
        <Grid
          container
          spacing={2}
          sx={{ padding: "20px", alignItems: "center" }}
        >
          <Grid item xs={12}>
            <h2>{brand.name} Settings</h2>
          </Grid>
          {!isDeviceNotificationsEnabled && (
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                onClick={handleEnableDeviceNotificationsClick}
              >
                Enable Device Notifications
              </Button>
              <p>
                We detected that you do not have notifications enabled for this
                device. Please enable device notifications for the best user
                experience.
              </p>
            </Grid>
          )}
          {/* <Button variant="contained" onClick={sendSampleNotification}>
          Send Sample Notification
        </Button> */}
          {!isManager && (
            <Grid item xs={12}>
              <p>
                Please contact your Adsurgent creative director to change
                settings.
              </p>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <TextField
              id="filled-basic"
              variant="filled"
              value={brandData?.phoneExtension}
              label="Default Caller ID"
              fullWidth
              disabled={!isManager}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="filled-basic"
              variant="filled"
              value={brandData?.preferredBusinessPhone}
              label="Preferred Business Phone"
              fullWidth
              disabled={!isManager}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="filled-basic"
              variant="filled"
              value={brandData!.geoExclusivity}
              label="Exclusive Geo Area"
              fullWidth
              disabled={!isManager}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="filled-basic"
              variant="filled"
              value={brandData!.reviewRequestLink}
              label="Review Request Link"
              fullWidth
              disabled={!isManager}
            />
          </Grid>
        </Grid>
        {/* Confirmation dialog for push subscriptions */}
        <ConfirmationDialog
          children={null}
          title="Push Subscription Request"
          open={isPushConfirmationOpen}
          message="You have successfully subscribed to push notifications on this device."
          setOpen={setIsPushConfirmationOpen}
          onConfirm={null}
        />
      </>
    );
  } else
    return <div>Sorry, but we're missing either user data or brand data.</div>;
};

export default BrandSettings;
