import "./App.css";
import { Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import NewHomePage from "./pages/home";
import NewLoginPage from "./pages/login";
import NewLeadsPage from "./pages/leads";
import NewLeadHistoryPage from "./pages/lead-history";
import NewPerformancePage from "./pages/performance";
import NewUpgradesListPage from "./pages/upgrades-list";
import NewProfileSettingsPage from "./pages/profile-settings";
import BrandContainer from "./components/layout/BrandContainer";
//import ConfirmationDialog from "./components/ConfirmationDialog";
import NewUtilitiesPage from "./pages/utilities";
import OAuth2CallbackPage from "./pages/oauth2-callback";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { ClassNames, ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { green, purple } from "@mui/material/colors";
import React from "react";
import RequireAuth from "./components/RequireAuth";

//<div className="app-body" style="margin:0px;height:100vh">
const theme = createTheme({
  palette: {
    primary: {
      main: "#000036",
    },
    secondary: {
      main: "#7a7a7a",
    },
  },
});

const App: React.FunctionComponent = () => {
  return (
    <div className="app-body">
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Routes>
          {/*public routes*/}
          <Route path="login" element={<NewLoginPage />} />
          <Route path="/" element={<NewHomePage />} />
          <Route path="/oauth2callback" element={<OAuth2CallbackPage />} />

          {/*Protected Routes*/}
          <Route element={<RequireAuth />}>
            <Route path=":brandId" element={<BrandContainer />}>
              <Route path="performance" element={<NewPerformancePage />} />
              <Route path="leads" element={<NewLeadsPage />}>
                <Route path=":customerId" element={<NewLeadHistoryPage />} />
              </Route>
              <Route path="upgrades-list" element={<NewUpgradesListPage />} />
              <Route
                path="profile-settings"
                element={<NewProfileSettingsPage />}
              />
              <Route path="utilities" element={<NewUtilitiesPage />} />
            </Route>
          </Route>
        </Routes>
      </ThemeProvider>
    </div>
  );
};

export default App;
