import React from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LeadHistory from "../components/layout/LeadHistory";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { uiActions } from "../store/uiSlice";
import classes from "./lead-hitory.module.css";
import { RootState } from "../store/index";
import { Outlet } from "react-router-dom";

const NewLeadsHistoryPage: React.FunctionComponent = () => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const ui = useSelector((state: RootState) => state.ui);

  const dispatch = useDispatch();

  return (
    <AutoSizer>
      {({ height, width }) => {
        if (height && width) {
          return (
            <div style={{ height: height, width: width }}>
              <LeadHistory winHeight={height} />
            </div>
          );
        } else return <div>"No height or width."</div>;
      }}
    </AutoSizer>
  );
};

export default NewLeadsHistoryPage;
