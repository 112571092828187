//Based on: https://www.youtube.com/watch?v=-JJFQ9bkUbo&t=611s
//React Redux Login Authentication Flow with JWT Access, Refresh Tokens, Cookies
//by Dave Gray
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { InitialAuthState } from "../types";

//@ts-ignore
const userDataFromStorage = localStorage.getItem("userData");
const userData: {
  userId: string;
  brandId: string;
  email: string;
  token: string;
  tokenExpirationDate: string;
  role: string;
} = userDataFromStorage ? JSON.parse(userDataFromStorage) : null;

const initialAuthState: InitialAuthState = {
  userId: userData && userData.userId ? userData.userId : null,
  brandId: userData && userData.brandId ? userData.brandId : null,
  email: userData && userData.email ? userData.email : null,
  token: userData && userData.token ? userData.token : null,
  tokenExpirationDate:
    userData && userData.tokenExpirationDate
      ? userData.tokenExpirationDate
      : null,
  role: userData && userData.role ? userData.role : null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    setCredentials: (state, action) => {
      const { userId, brandId, email, token, role, tokenExpirationDate } =
        action.payload;
      state.userId = userId;
      state.brandId = brandId;
      state.email = email;
      state.token = token;
      state.role = role;
      state.tokenExpirationDate = tokenExpirationDate.toISOString();

      localStorage.setItem(
        "userData",
        JSON.stringify({
          userId,
          brandId,
          email,
          token,
          role,
          tokenExpirationDate,
        })
      );
    },
    logOut: (state, action) => {
      state.userId = null;
      state.email = null;
      state.token = null;
      state.role = null;
      state.brandId = null;
      localStorage.removeItem("userData");
    },
  },
});

//export const { setCredentials, logOut }: {setCredentials:AnyAction, logOut:AnyAction} = authSlice.reducer;
export const authActions = authSlice.actions;
export default authSlice.reducer;

export const selectCurrentUserId = (state: RootState) => state.auth.userId;
export const selectCurrentToken = (state: RootState) => state.auth.token;
export const selectCurrentBrandId = (state: RootState) => state.auth.brandId;
export const selectCurrentRole = (state: RootState) => state.auth.role;
