import classes from "./RealtimePerformance.module.css";

import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Brand } from "../../types";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import SpeedIcon from "@mui/icons-material/Speed";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useGetBrandQuery } from "../../store/api";
import { Grid } from "@mui/material";

import StatusCards from "./StatusCards";
import uiSlice from "../../store/uiSlice";
import { useParams } from "react-router-dom";
import ChartGoodFitLeads from "../ChartGoodFitLeads";
import ChartTraffic from "../ChartTraffic";
import ChartBrandOptimization from "../ChartBrandOptimization";
import { selectCurrentRole } from "../../store/authSlice";
import AutoSizer from "react-virtualized-auto-sizer";
import BrandPerformanceCard from "../BrandPerformanceCard";

const RealtimePerformance: React.FunctionComponent = () => {
  const params = useParams();
  // const brandId: string | undefined = params.brandId;

  // const [open, setOpen] = React.useState(false);
  // const [isChartNewLeadsOpen, setChartNewLeadsOpen] = React.useState(false);
  // // const handleClick = () => {
  // //   setOpen(!open);
  // // };

  // const {
  //   data: brandData,
  //   // error: brandError,
  //   // isLoading: brandIsLoading,
  // } = useGetBrandQuery({ brandId: brandId! }, {});

  const userRole = useSelector(selectCurrentRole);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <div
          style={{
            // display: "flex",
            // flexDirection: "column",
            // overflow: "scroll",
            height: height,
            width: width,
          }}
        >
          <>
            {userRole === "manager" && (
              <Grid
                container
                spacing={2}
                justifyContent="center"
                overflow="scroll"
                height={height}
              >
                <Grid item md={3} sm={12} xs={12} minHeight="300px" marginY={2}>
                  <BrandPerformanceCard />
                </Grid>
                <Grid item md={6} sm={12} xs={12} minHeight="300px" marginY={2}>
                  <ChartBrandOptimization />
                </Grid>
                <Grid item md={9} sm={12} xs={12} minHeight="300px">
                  <StatusCards />
                </Grid>
                <Grid item md={12} sm={12} xs={12} minHeight="500px" margin={3}>
                  <ChartGoodFitLeads />
                </Grid>
                <Grid item md={12} sm={12} xs={12} minHeight="500px" margin={3}>
                  <ChartTraffic />
                </Grid>
              </Grid>
            )}
          </>
        </div>
      )}
    </AutoSizer>
  );
};

export default RealtimePerformance;
