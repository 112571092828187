import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import useInput from "../hooks/useInput";
import FormControlLabel from "@mui/material/FormControlLabel";
import AdsurgentLogo from "../assets/images/logo-pos-01.svg";
import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../store/api";
import { useDispatch } from "react-redux";
import { authActions } from "../store/authSlice";
import { LoginRequest, LoginResponse } from "../types";
import logger from "../logger";
import { AdsurgentUser } from "../types";

//let's create a login form
const LoginForm: React.FunctionComponent = (props) => {
  //let's create some basic validators to pass to our useInput hook
  let apiErrorMessage = "";

  const [apiErrMsg, setApiErrMsg] = useState("");

  const isNotEmpty = (value: string) => value.trim() !== "";
  const isValidUserEmail = (value: string) => {
    if (value.includes("@") && apiErrMsg != "401-Invalid user.") {
      return true;
    } else return false;
  };
  const isValidUserPw = (value: string) => {
    if (apiErrMsg != "401-Invalid password.") {
      return true;
    } else return false;
  };
  //using our custom useInput hook, let's create form fields
  //with a nice user experience
  const {
    value: userNameValue,
    isValid: userNameIsValid,
    hasError: userNameHasError,
    valueChangeHandler: userNameChangeHandler,
    inputBlurHandler: userNameBlurHandler,
    reset: userNameReset,
  } = useInput(isValidUserEmail);

  const {
    value: pwValue,
    isValid: pwIsValid,
    hasError: pwHasError,
    valueChangeHandler: pwChangeHandler,
    inputBlurHandler: pwBlurHandler,
    reset: pwReset,
  } = useInput(isValidUserPw);

  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();

  //this will listen for changes to userName(email) or PW and reset the
  //api error message if it changes so that we can try again (resubmit)
  useEffect(() => {
    setApiErrMsg("");
  }, [userNameValue, pwValue]);

  let formIsValid = false;
  if (userNameIsValid && pwIsValid) {
    formIsValid = true;
  }

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!formIsValid) {
      logger.warn("not a valid form!");
      return;
    }
    // logger.info("Submitted!");
    // logger.info(userNameValue, pwValue);
    const tokenExpirationDate = new Date(new Date().getTime() + 1000 * 60 * 60);
    loginHandler(userNameValue, pwValue, tokenExpirationDate);
  };

  const loginHandler = async (
    username: string,
    pw: string,
    tokenExpirationDate: Date | null
  ) => {
    try {
      //unwrap function from redux toolkit allows us to use try/catch here
      const userData = await login({
        email: username,
        pw: pw,
      }).unwrap();

      dispatch(
        //this could potentially be revisited to include less infromation in localStorage
        authActions.setCredentials({
          userId: userData.userId,
          email: userData.email,
          token: userData.token,
          brandId: userData.brandId,
          role: userData.role,
          //create a token expiration 1 hour in the future.
          //https://www.udemy.com/course/react-nodejs-express-mongodb-the-mern-fullstack-guide/learn/lecture/16916242#overview
          tokenExpirationDate: tokenExpirationDate,
        })
      );

      const redirectPage = `/${userData.brandId}/leads/`;

      navigate(redirectPage);
      //the any or uknown below may be able to be specific if we look
      //at the console log
    } catch (err: any | unknown) {
      if (!err?.status) {
        logger.error(err);
        //set error message
        setApiErrMsg("Unknown Error");
      } else if (err.status === 400) {
        logger.error(err);
        //set error message "missing username or password"
        setApiErrMsg(err.status + "-" + err.data.message);
      } else if (err.status === 401) {
        //set error message unathorized
        setApiErrMsg(err.status + "-" + err.data.message);
        logger.error(err.status + "-" + err.data.message);
      } else {
        logger.error(err);
        //set error message "login failed"
        setApiErrMsg("Unknown Error");
      }
      //errRef.current.focus();
      //set focus on the error
    }
  };

  //if we already have a token in storage, let's redirect them to the
  //appropriate page
  useEffect(() => {
    const storedData = localStorage.getItem("userData");
    const userData = storedData ? JSON.parse(storedData) : null;
    if (
      userData &&
      userData.token &&
      userData.brandId &&
      //make sure the token has not expired
      new Date(userData.tokenExpirationDate) > new Date()
    ) {
      const redirectPage = `/${userData.brandId}/leads/`;
      navigate(redirectPage);
    }
  }, []);

  const userNameClasses = userNameHasError
    ? "form-control invalid"
    : "form-control";
  const pwClasses = pwHasError ? "form-control invalid" : "form-control";

  return (
    <Box
      component="form"
      onSubmit={submitHandler}
      noValidate
      sx={{ mt: 1 }}
      maxWidth="350px"
    >
      <img src={AdsurgentLogo} loading="lazy" />
      <TextField
        error={userNameHasError ? true : false}
        margin="normal"
        required
        fullWidth
        id="username"
        label="Username"
        name="username"
        autoComplete="username"
        autoFocus
        value={userNameValue}
        onChange={userNameChangeHandler}
        onBlur={userNameBlurHandler}
        className={userNameClasses}
      />
      <TextField
        error={pwHasError ? true : false}
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        value={pwValue}
        onChange={pwChangeHandler}
        onBlur={pwBlurHandler}
        className={pwClasses}
      />
      <FormControlLabel
        control={<Checkbox value="remember" color="primary" />}
        label="Remember me"
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={!formIsValid ? true : false}
      >
        Sign In
      </Button>
    </Box>
  );
};

export default LoginForm;
