import { useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { MobileDateRangePicker } from "@mui/x-date-pickers-pro/MobileDateRangePicker";
import { Dayjs } from "dayjs";
//import { DateRange } from "@mui/x-date-pickers-pro/internal/models/dateRange.js";
import { DateRange } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs from "dayjs";
import { Collapse } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import IconButton from "@mui/material/IconButton";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import AutoSizer from "react-virtualized-auto-sizer";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import uiSlice from "../store/uiSlice";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { uiActions } from "../store/uiSlice";
import { useDispatch } from "react-redux";
import TuneIcon from "@mui/icons-material/Tune";
import { InputAdornment } from "@mui/material";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import {
  PickersShortcutsItem,
  PickersShortcutsProps,
} from "@mui/x-date-pickers/PickersShortcuts";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Chip from "@mui/material/Chip";
import { Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetBrandQuery } from "../store/api";
import { BrandStatus } from "../types";
import { adsurgentDayJsFormat } from "../util/util";
import logger from "../logger";

//import { DateRange } from "@mui/icons-material";

const LeadFiltering: React.FunctionComponent = () => {
  const ui = useSelector((state: RootState) => state.ui);
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const params = useParams();
  const brandId: string | undefined = params.brandId;
  const { data: brandData } = useGetBrandQuery({ brandId: brandId! }, {});
  const brandStatuses = brandData?.statuses;

  const filteredCustomerCount = useSelector(
    (state: RootState) => state.ui.filteredCustomerCount
  );
  const isResultFiltered = () => {
    if (
      ui.searchFilters.searchString !== "" ||
      ui.searchFilters.showOnlyNeedsAttention !== false ||
      ui.searchFilters.showStatus !== 99
    ) {
      return true;
    } else return false;
    // defaultValues.searchString = ui.searchFilters.searchString;
    // defaultValues.showOnlyNeedsAttention =
    //   ui.searchFilters.showOnlyNeedsAttention;
    // defaultValues.showSpam = ui.searchFilters.showSpam;
    // defaultValues.showStatus = ui.searchFilters.showStatus;
  };
  //let's get 90 days ago
  //   const defaultStartDate = dayjs().subtract(90, "day");
  //let's get the current date
  //   const defaultEndDate = dayjs();
  //   const [dateRangeValue, setDateRangeValue] = useState<DateRange<Dayjs>>([
  //     defaultStartDate,
  //     defaultEndDate,
  //   ]);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const handleFilterClick = () => {
    setFilterDrawerOpen(!filterDrawerOpen);
  };

  const { control, reset, watch } =
    useForm(/*{
  defaultValues: {
    outboundPhoneNumber: "",
  },
}*/);
  //set the default values for forms
  //using react-hook-form approach
  useEffect(() => {
    const dateRangeDefault = [
      dayjs(ui.searchFilters.dateRange[0]),
      dayjs(ui.searchFilters.dateRange[1]),
    ];
    let defaultValues: any = {};
    defaultValues.dateRange = dateRangeDefault; /*ui.searchFilters.dateRange;*/ //[startDate,endDate]
    defaultValues.searchString = ui.searchFilters.searchString;
    defaultValues.showOnlyNeedsAttention =
      ui.searchFilters.showOnlyNeedsAttention;
    defaultValues.showSpam = ui.searchFilters.showSpam;
    defaultValues.showStatus = ui.searchFilters.showStatus;
    reset({ ...defaultValues });
  }, [ui.searchFilters]);

  const dateRange = watch("dateRange");
  //logger.info("leadFiltering watch: ", ui.searchFilters.dateRange[1]);
  const differenceBetweenDates = dayjs(ui.searchFilters.dateRange[1]).diff(
    ui.searchFilters.dateRange[0],
    "day"
  );
  const searchString = watch("searchString");
  const showOnlyNeedsAttention = watch("showOnlyNeedsAttention");
  const showSpam = watch("showSpam");
  const showStatus = watch("showStatus");

  //   logger.info("dateRange:", JSON.stringify(dateRange));
  //   logger.info("showStatus:", showStatus);
  //   logger.info("searchString:", searchString);
  //   logger.info("showOnlyNeedsAttention:", showOnlyNeedsAttention);
  //   logger.info("showSpam:", showSpam);
  //logger.info(JSON.stringify(ui.searchFilters));

  const dispatch = useDispatch();
  const handleSubmitSearch = () => {
    if (dateRange) {
      //dateRange in this context is a dayjs object
      //we need to convert this back to a string to store in state (and db)
      const dateRangeStrings: string[] = [
        dateRange[0].format(adsurgentDayJsFormat),
        //add 5 hours to prevent new messages from disappaering on the LEADLIST
        //when a new message comes in on a manually FILTERED LEADLISt
        dateRange[1].add(5, "hour").format(adsurgentDayJsFormat),
      ];
      //logger.info("dateRangeIso: ", dateRangeIso);
      dispatch(
        uiActions.updateSearchFilters({
          dateRange: dateRangeStrings,
          searchString: searchString,
          showOnlyNeedsAttention: showOnlyNeedsAttention,
          showSpam: showSpam,
          showStatus: showStatus,
        })
      );
      dispatch(uiActions.toggleHistoryDrawer(false));
      setFilterDrawerOpen(!filterDrawerOpen);
    }
  };

  const handleResetFiltersClick = () => {
    dispatch(uiActions.resetSearchFilters({}));
    dispatch(uiActions.toggleHistoryDrawer(false));
    handleFilterClick();
  };

  //let's watch the searchString and update the UI State (redux) when it changes
  useEffect(() => {
    if (dateRange) {
      const dateRangeStrings: string[] = [
        ui.searchFilters.dateRange[0],
        ui.searchFilters.dateRange[1],
      ];
      dispatch(
        uiActions.updateSearchFilters({
          dateRange: dateRangeStrings,
          searchString: searchString,
          showOnlyNeedsAttention: showOnlyNeedsAttention,
          showSpam: showSpam,
          showStatus: showStatus,
        })
      );
    }
  }, [searchString]);

  const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    // {
    //   label: "Last Week",
    //   getValue: () => {
    //     const today = dayjs();
    //     const prevWeek = today.subtract(7, "day");
    //     return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    //   },
    // },
    {
      label: "Last 30",
      getValue: () => {
        const today = dayjs();
        const thirtyDaysAgo = today.subtract(30, "day");
        return [thirtyDaysAgo, today];
      },
    },
    {
      label: "Last 90",
      getValue: () => {
        const today = dayjs();
        const ninetyDaysAgo = today.subtract(90, "day");
        return [ninetyDaysAgo, today];
      },
    },
    {
      label: "All Time",
      getValue: () => {
        const today = dayjs();
        const startDate = dayjs("01-01-2015");
        return [startDate, today];
      },
    },
    // { label: "Reset", getValue: () => [null, null] },
  ];

  function CustomRangeShortcuts(
    props: PickersShortcutsProps<DateRange<Dayjs>>
  ) {
    const { items, onChange, isValid } = props;

    if (items == null || items.length === 0) {
      return null;
    }

    const resolvedItems = items.map((item) => {
      const newValue = item.getValue({ isValid });

      return {
        label: item.label,
        onClick: () => {
          onChange(newValue);
        },
        disabled: !isValid(newValue),
      };
    });

    return (
      <Box
        sx={{
          gridRow: 1,
          gridColumn: 2,
          padding: 0,
          margin: 0,
        }}
      >
        <List
          dense
          sx={(theme) => ({
            display: "flex",
            //justifyContent:"space-between",
            px: 1,
            // "& .MuiListItem-root": {
            //   pt: 0,
            //   pl: 0,
            //   //pr: theme.spacing(1),
            // },
          })}
        >
          {resolvedItems.map((item) => {
            return (
              <ListItem key={item.label}>
                <Chip {...item} sx={{ textAlign: "center" }} />
              </ListItem>
            );
          })}
        </List>
        <Divider />
      </Box>
    );
  }

  const getStatuses = (brandStatuses: BrandStatus[]) => {
    let menuItems = [];
    const optionOne = () => <MenuItem value={99}>All Statuses</MenuItem>;

    const items = brandStatuses.map((brandStatus) => {
      return <MenuItem value={brandStatus.id}>{brandStatus.label}</MenuItem>;
    });

    menuItems.push(optionOne(), items);
    //logger.info(menuItems);
    return menuItems;
  };

  return (
    <>
      <Controller
        name="searchString"
        control={control}
        rules={{ required: false }}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
        }) => (
          <TextField
            onChange={onChange}
            value={value}
            size="small"
            style={{
              width: "100%",
              paddingLeft: "5px",
              paddingRight: "5px",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {<TuneIcon onClick={handleFilterClick} />}
                </InputAdornment>
              ),
            }}
          />
        )}
      />

      <div
        style={{ textAlign: "center", fontSize: ".8em", marginBottom: "10px" }}
      >
        Found {filteredCustomerCount} Customers ({differenceBetweenDates} days
        {isResultFiltered() && ", filtered"})
      </div>

      <Drawer
        anchor="left"
        open={filterDrawerOpen}
        PaperProps={{
          sx: { width: largeScreen ? "30%" : "100%" },
        }}
        variant="temporary"
        ModalProps={{ onBackdropClick: handleFilterClick }}
      >
        <div
          style={{
            textAlign: "right",
            marginTop: "5px",
            marginRight: "15px",
          }}
        >
          <RotateLeftIcon onClick={handleResetFiltersClick} />
        </div>

        <div
          style={{
            paddingTop: 0,
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
            backgroundColor: "#fff",
            borderTop: "none",
            borderColor: "lightgray",
            borderWidth: "1px",
            //borderBottom: "solid",
          }}
        >
          <h4>Customer Filters</h4>
          {/* https://react-hook-form.com/api/usecontroller/controller */}
          {/* Controller is required with 3rd party libraries (like MUI) */}
          <Controller
            name="dateRange"
            control={control}
            rules={{ required: false }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
            }) => (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                localeText={{ start: "Start Date", end: "End Date" }}
              >
                {/* <MobileDateRangePicker */}
                <MobileDateRangePicker
                  value={value}
                  onChange={onChange}
                  slots={{
                    shortcuts: CustomRangeShortcuts,
                  }}
                  slotProps={{
                    shortcuts: {
                      items: shortcutsItems,
                    },
                    toolbar: {
                      hidden: true,
                    },
                    actionBar: {
                      hidden: true,
                    },
                  }}
                  calendars={1}
                />
              </LocalizationProvider>
            )}
          />
          {/* <div
            style={{
              textAlign: "center",
              fontSize: ".7em",
              marginBottom: 5,
              marginTop: 5,
            }}
          >
            (Date range defaults to 90 Days)
          </div> */}

          <InputLabel sx={{ marginTop: 2 }} id="status-select-label">
            Status
          </InputLabel>
          <Controller
            name="showStatus"
            control={control}
            rules={{ required: false }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
            }) => (
              <Select
                labelId="status-select-label"
                id="status-select"
                value={value}
                label="Status"
                onChange={onChange}
              >
                {/* This should be driven from actual status levels/labels of the individual brand and looped through */}
                {brandStatuses && getStatuses(brandStatuses)}
                {/* // <MenuItem value={99}>All Statuses</MenuItem>
                // <MenuItem value={0}>Missing Status</MenuItem>
                // <MenuItem value={1}>Not a Fit</MenuItem>
                // <MenuItem value={2}>Good Fit</MenuItem>
                // <MenuItem value={3}>Sale</MenuItem>
                // <MenuItem value={4}>Warranty Claim</MenuItem> */}
              </Select>
            )}
          />
          <Divider sx={{ marginTop: 3 }} />

          <Controller
            name="showOnlyNeedsAttention"
            control={control}
            rules={{ required: false }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
            }) => (
              <FormControlLabel
                //sx={{ margin: "5px" }}
                label="Focus Attention"
                control={
                  <Checkbox
                    checked={value}
                    onChange={onChange}
                    // inputProps={{ "aria-label": "controlled" }}
                    //   label="Only Show Needs Attention"
                  />
                }
              />
            )}
          />
          {/* Show SPAM? */}
          <Controller
            name="showSpam"
            control={control}
            rules={{ required: false }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
            }) => (
              <FormControlLabel
                sx={{ margin: "5px" }}
                label="Show spam"
                control={
                  <Checkbox
                    checked={value}
                    onChange={onChange}
                    // inputProps={{ "aria-label": "controlled" }}
                    //   label="Only Show Needs Attention"
                  />
                }
              />
            )}
          />
          <div style={{ textAlign: "left", marginTop: 20 }}>
            {/* <IconButton onClick={handleFilterClick}> */}
            <Button variant="outlined" onClick={handleSubmitSearch}>
              <ManageSearchIcon />
              <h4>Apply Filters</h4>
            </Button>
          </div>
        </div>

        {/*  */}
      </Drawer>
    </>
  );
};

export default LeadFiltering;
