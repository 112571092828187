import classes from "./MessageInput.module.css";
import LeadSpeedDial from "./LeadSpeedDial";
import React from "react";

const MessageInput: React.FunctionComponent<{ height: number }> = ({
  children,
  height,
}) => {
  return (
    <div className={classes["msg-input"]} style={{ height: height }}>
      <LeadSpeedDial />
    </div>
  );
};

export default MessageInput;
