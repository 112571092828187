import React from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import logo from "../assets/images/logo-pos-01.svg";

const logoImage = React.createElement(
  "img",

  {
    src: logo,
    alt: "Logo",
    width: "35%",
  },
  null
);

const NewHomePage: React.FunctionComponent = () => {
  return (
    <AutoSizer>
      {({ height, width }) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: height,
            width: width,
          }}
        >
          {logoImage}
        </div>
      )}
    </AutoSizer>
  );
};

export default NewHomePage;
