import React from "react";
import RealtimePerformance from "../components/layout/RealtimePerformance";

const NewPerformancePage: React.FunctionComponent = (props) => {
  return (
    <>
      <RealtimePerformance />
    </>
  );
};

export default NewPerformancePage;
