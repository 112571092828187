import * as React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { Theme, Tooltip } from "@mui/material";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
// import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
// import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import SettingsIcon from "@mui/icons-material/Settings";
import SpeedIcon from "@mui/icons-material/Speed";
import { Outlet } from "react-router";
import AutoSizer from "react-virtualized-auto-sizer";
// import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { useParams } from "react-router";
import classes from "./MiniDrawer.module.css";
import PrecisionManufacturing from "@mui/icons-material/PrecisionManufacturing";
import { RootState } from "../../store";
import { Mixin } from "react";
import { useGetBrandQuery } from "../../store/api";
import { authActions } from "../../store/authSlice";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { uiActions } from "../../store/uiSlice";
import logger from "../../logger";

//const drawerWidth = 240;

// const CustomButton = styled(Button)({
//   // your custom styles go here
// }) as typeof Button;

// const openedMixin = (theme: Theme) => ({
//   width: drawerWidth,
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.enteringScreen,
//   }),
//   overflowX: "hidden",
// });

// const closedMixin = (theme: Theme) => ({
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   overflowX: "hidden",
//   width: `calc(${theme.spacing(7)} + 1px)`,
//   [theme.breakpoints.up("sm")]: {
//     width: `calc(${theme.spacing(9)} + 1px)`,
//   },
// });

type InputProps = {
  open?: any;
};

// const DrawerHeader = styled("div")<InputProps>(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "flex-end",
//   padding: theme.spacing(0, 1),
//   // necessary for content to be below app bar
//   ...theme.mixins.toolbar,
// }));

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== "open",
// })<InputProps>(({ theme, open }) => ({
//   zIndex: theme.zIndex.drawer + 1,
//   transition: theme.transitions.create(["width", "margin"], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     marginLeft: drawerWidth,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(["width", "margin"], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));

// const CustomButton = styled(Button)({
//   // your custom styles go here
// }) as typeof Button;

// const Drawer = styled(MuiDrawer, {
//   shouldForwardProp: (prop) => prop !== "open",
// })<InputProps>(({ theme, open }) => ({
//   width: drawerWidth,
//   flexShrink: 0,
//   whiteSpace: "nowrap",
//   boxSizing: "border-box",
//   ...(open && {
//     ...openedMixin(theme),
//     "& .MuiDrawer-paper": openedMixin(theme),
//   }),
//   ...(!open && {
//     ...closedMixin(theme),
//     "& .MuiDrawer-paper": closedMixin(theme),
//   }),
// }));

const MiniDrawer: React.FunctionComponent = (props) => {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    //close the menu drawer
    setOpen(!open);
    //close the history drawer too
    dispatch(uiActions.toggleHistoryDrawer(false));
  };

  const params = useParams();
  const brandId: string = params.brandId!;
  //we are checking this in the brand container for isLoading & Error, so we can
  //assert existence in this component without checking
  const {
    data: brandData,
    // error: brandError,
    // isLoading: brandIsLoading,
  } = useGetBrandQuery({ brandId: brandId }, {});

  const dispatch = useDispatch();
  const handleLogout = () => {
    //logger.info("logging out...");
    dispatch(uiActions.resetUiState(null));
    dispatch(authActions.logOut(null));
  };

  //const brand = useSelector((state: RootState) => state.ui.brand);

  // const drawerOpenHandler = () => {
  //   setOpen(true);
  // };

  // const closeDrawerHandler = () => {
  //   setOpen(false);
  // };

  const appBarHeight = "64px";
  if (brandData) {
    return (
      <AutoSizer>
        {({ height, width }) => (
          <>
            <Box sx={{ display: "flex" }} height={height} width={width}>
              <CssBaseline />

              <MuiAppBar position="fixed" className={classes.header}>
                <Toolbar
                  style={{
                    minHeight: appBarHeight,
                  }}
                >
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    edge="start"
                    sx={{
                      marginRight: "10px",
                      //...(open && { display: "none" }),
                    }}
                  >
                    <MenuIcon />
                  </IconButton>

                  <img src="/images/logo-invert.png" alt=""></img>
                </Toolbar>
              </MuiAppBar>

              <Drawer
                variant="temporary"
                anchor="left"
                open={open}
                onClose={toggleDrawer}
                PaperProps={{
                  style: {
                    width: 90,
                    paddingLeft: 15,
                    paddingRight: 15,
                  },
                }}
                sx={
                  {
                    //display: { xs: "none", sm: "block" },
                  }
                }
              >
                {/* <DrawerHeader></DrawerHeader> */}
                <Divider />
                <List sx={{ marginTop: appBarHeight }}>
                  <ListItem
                    button
                    key="leads"
                    component={Link}
                    to={`/${brandId}/leads`}
                    onClick={toggleDrawer}
                  >
                    <Tooltip title="Messages">
                      <ListItemIcon>
                        <ChatBubbleIcon />
                        {/* <span style={{ marginLeft: 15, marginRight: 15 }}>
                          Messages
                        </span> */}
                      </ListItemIcon>
                    </Tooltip>
                  </ListItem>

                  <ListItem
                    button
                    key="performance"
                    component={Link}
                    to={`/${brandId}/performance`}
                    onClick={toggleDrawer}
                  >
                    <Tooltip title="Performance">
                      <ListItemIcon>
                        <SpeedIcon />
                        {/* <span style={{ marginLeft: 15, marginRight: 15 }}>
                          Performance
                        </span> */}
                      </ListItemIcon>
                    </Tooltip>
                  </ListItem>

                  <ListItem
                    button
                    key="upgradeslist"
                    component={Link}
                    to={`/${brandId}/upgrades-list`}
                    onClick={toggleDrawer}
                  >
                    <Tooltip title="Upgrades List">
                      <ListItemIcon>
                        <PrecisionManufacturing />
                        {/* <span style={{ marginLeft: 15, marginRight: 15 }}>
                          Upgrades
                        </span> */}
                      </ListItemIcon>
                    </Tooltip>
                  </ListItem>
                </List>
                <Divider />
                <List>
                  <ListItem
                    button
                    key="settings"
                    component={Link}
                    to={`/${brandId}/profile-settings`}
                    onClick={toggleDrawer}
                  >
                    <Tooltip title="Settings">
                      <ListItemIcon>
                        <SettingsIcon />
                        {/* <span style={{ marginLeft: 15, marginRight: 15 }}>
                          Settings
                        </span> */}
                      </ListItemIcon>
                    </Tooltip>
                  </ListItem>
                  <ListItem
                    button
                    key="logout"
                    //component={Link}
                    onClick={handleLogout}
                    sx={{ position: "fixed", bottom: 0 }}
                  >
                    <Tooltip title="Logout">
                      <ListItemIcon>
                        <PowerSettingsNewIcon />
                      </ListItemIcon>
                    </Tooltip>
                  </ListItem>
                </List>
              </Drawer>
              {/* 64px margin for height of the appbar  */}
              <Box
                component="main"
                style={{ flexGrow: 1, marginTop: appBarHeight }}
              >
                {/* <DrawerHeader /> */}
                <Outlet />
              </Box>
            </Box>
          </>
        )}
      </AutoSizer>
    );
  } else return <div></div>;
};

export default MiniDrawer;
