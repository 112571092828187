import { useGetCustomersQuery } from "../store/api";
import { useParams } from "react-router";
import { Customer, DailyTraffic } from "../types";
import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import {
  MonthlyStats,
  TrafficData,
  LeadAsPerformanceData,
  YearlyStats,
  BrandStats,
  Brand,
} from "../types";
import logger from "../logger";

const getDateRangeArray = (startDate: Dayjs, endDate: Dayjs) => {
  // Returns an array of dates between the two dates
  const dates: Dayjs[] = [];
  let dayCounter = startDate.unix();
  const setDayCounter = () => {
    dayCounter = dayCounter + 86000;
  };
  //from startDate, keep looking until the end date
  while (dayCounter <= endDate.unix()) {
    dates.push(dayjs.unix(dayCounter));
    //let's add a unix day every time through the loop
    setDayCounter();
  }
  //   logger.info("getDateRangeArray:", dates);
  return dates;
};

const getDailyBudgets = async (
  brand: Brand,
  startDate: Dayjs,
  endDate: Dayjs
) => {
  //let's build an array of dates with their the amount spent each day
  const dateRangeArray: Dayjs[] = getDateRangeArray(startDate, endDate);
  const monthlyBudgets = brand.monthlyBudgets;
  let dailyBudgets: { date: Dayjs; amount: number }[] = [];
  if (monthlyBudgets) {
    for (const day of dateRangeArray) {
      for (const budget of monthlyBudgets) {
        const budgetEndDate = budget.endDate ? budget.endDate : dayjs();
        if (
          day.isBetween(startDate, endDate, "day", "[]") &&
          day.isBetween(budget.startDate, budgetEndDate, "day", "[]")
        ) {
          const daysInMonth = day.daysInMonth();
          const dailyBudget = budget.amountUsd / daysInMonth;
          const dayBudget = { date: day, amount: dailyBudget };
          dailyBudgets.push(dayBudget);
        }
      }
    }
  }
  //logger.info("getDailyBudgets: ", dailyBudgets);
  return dailyBudgets;
};

//get total budget spent for a period of time
const getBudgetSpent = async (
  brand: Brand,
  startDate: Dayjs,
  endDate: Dayjs
) => {
  //get the array of
  const dailyBudgets: { date: Dayjs; amount: number }[] = await getDailyBudgets(
    brand,
    startDate,
    endDate
  );
  //https://stackoverflow.com/questions/23247859/better-way-to-sum-a-property-value-in-an-array
  const sum = dailyBudgets.reduce((a, b) => {
    return a + b["amount"];
  }, 0);
  //   logger.info("getBudgetSpent:", sum);
  return sum;
};

//get the number of good fit leads (or higher)
//where the date of first contact is within the date range
const getLeadsTotalByStatus = async (
  brand: Brand,
  customers: Customer[],
  statusId: number,
  startDate: Dayjs,
  endDate: Dayjs
) => {
  //this function has to take into account that a status 2 total, must also include any statuses higher that that
  const matchingCustomers = customers.filter((customer) => {
    const customerOldestMessage = getCustomerOldestMessage(customer);
    // logger.info(
    //   "getLeadsTotalByStatus: customerOldestMessage: ",
    //   customerOldestMessage
    // );

    if (
      customer.salesStatus &&
      customer.salesStatus >= statusId &&
      customerOldestMessage &&
      dayjs(customerOldestMessage.timestamp).unix() >= startDate.unix() &&
      dayjs(customerOldestMessage.timestamp).unix() <= endDate.unix()
    ) {
      return true;
    } else return false;
  });
  const count = matchingCustomers.length;
  //   logger.info("getLeadsTotalByStatus: ", count);
  return count;
};

const getGoodFitStatusId = (brand: Brand) => {
  if (brand.statuses) {
    let goodFitStatus = brand.statuses.find(
      (status) =>
        status.isGoodFit === true &&
        status.isNotGoodFit === false &&
        status.isPostSale === false &&
        status.isSale === false &&
        status.isSpam === false
    );
    if (goodFitStatus) {
      return goodFitStatus.id;
    } else {
      logger.warn("getGoodFitStatus: goodFitStatus is missing.");
      return null;
    }
  } else {
    logger.warn("getGoodFitStatus: brand.statuses is missing.");
    return null;
  }
};

const getSaleStatusId = (brand: Brand) => {
  if (brand.statuses) {
    let saleStatus = brand.statuses.find(
      (status) => status.isSale === true || status.isPostSale === true
    );
    if (saleStatus) {
      return saleStatus.id;
    } else {
      logger.warn("getActualCAC: saleStatus is missing.");
      return null;
    }
  } else {
    logger.warn("getActualCAC: brand.statuses is missing.");
    return null;
  }
};

//get Cost Per Good Fit lead
const getActualCPGF = async (
  brand: Brand,
  customers: Customer[],
  startDate: Dayjs,
  endDate: Dayjs
) => {
  if (brand && startDate && endDate) {
    const budgetSpent = await getBudgetSpent(brand, startDate, endDate);
    //logger.info("budgetSpent: ", budgetSpent);

    const goodFitStatusId = getGoodFitStatusId(brand);
    //logger.info("goodFitStatusId: ", goodFitStatusId);

    if (goodFitStatusId) {
      const goodFitLeads = await getLeadsTotalByStatus(
        brand,
        customers,
        goodFitStatusId,
        startDate,
        endDate
      );
      //logger.info("goodFitLeads: ", goodFitLeads);
      const actualCPGF = budgetSpent / goodFitLeads;
      //   logger.info("getActualCPGF: ", actualCPGF);
      if (actualCPGF) {
        return Math.round(actualCPGF);
      } else return null;
    }
  } else return null;
};

//get Customer Acquisition Cost
//(cost per sale status & higher)
const getActualCAC = async (
  brand: Brand,
  customers: Customer[],
  startDate: Dayjs,
  endDate: Dayjs
) => {
  if (brand && startDate && endDate) {
    const budgetSpent = await getBudgetSpent(brand, startDate, endDate);
    //logger.info("budgetSpent: ", budgetSpent);

    const saleStatusId = getSaleStatusId(brand);
    //logger.info("goodFitStatusId: ", goodFitStatusId);

    if (saleStatusId) {
      const saleLeads = await getLeadsTotalByStatus(
        brand,
        customers,
        saleStatusId,
        startDate,
        endDate
      );
      //logger.info("saleLeads: ", saleLeads);
      const actualCAC = budgetSpent / saleLeads;
      //   logger.info("getActualCPGF: ", actualCPGF);
      if (actualCAC) {
        return Math.round(actualCAC);
      } else return null;
    }
  } else return null;
};

const getClosingRate = async (
  brand: Brand,
  customers: Customer[],
  startDate: Dayjs,
  endDate: Dayjs
) => {
  const goodFitStatusId = getGoodFitStatusId(brand);
  const saleStatusId = getSaleStatusId(brand);

  if (goodFitStatusId && saleStatusId) {
    const goodFitLeads = await getLeadsTotalByStatus(
      brand,
      customers,
      goodFitStatusId,
      startDate,
      endDate
    );

    const saleLeads = await getLeadsTotalByStatus(
      brand,
      customers,
      saleStatusId,
      startDate,
      endDate
    );

    if (goodFitLeads && saleLeads) {
      //logger.info("sales / Good Fits: ", saleLeads, goodFitLeads);
      const closingRate = Math.round((saleLeads / goodFitLeads) * 100);
      return closingRate;
    } else {
      logger.warn("getClosingRate: Missing key info to complete request.");
      return null;
    }
  } else {
    logger.warn(
      "getClosingRate: missing data needed to get the requested info."
    );
  }
};

export const getBrandOptimizationScore = async (
  brand: Brand,
  customers: Customer[],
  startDate: Dayjs,
  endDate: Dayjs
) => {
  const customerAvgLifetimeValue = brand.customerAvgLifetimeValue;
  const cacPercentage = brand.cacPercentage;

  if (brand && customers && customerAvgLifetimeValue && cacPercentage) {
    //get the target Customer Acquisition Cost (CAC)
    const targetCAC = customerAvgLifetimeValue * (cacPercentage / 100);
    // logger.info(
    //   "lifetimeValue: ",
    //   customerAvgLifetimeValue,
    //   "cacPercentage: ",
    //   cacPercentage
    // );
    //get the target Cost Per Good Fit (CPGF)
    //.5 means we are assuming that an optimized campaign can close half of the good fits into sales
    const targetCPGF = targetCAC * 0.5;
    //logger.info("targetCAC: ", targetCAC, "targetCPGF: ", targetCPGF);
    //get the actual cost per good for the last 3 months, because that will be a long
    //enough period of time to base budget decisions on
    // const startDate = dayjs().subtract(3, "month");
    // const endDate = dayjs();
    const actualCPGF = await getActualCPGF(
      brand,
      customers,
      startDate,
      endDate
    );
    const actualCAC = await getActualCAC(brand, customers, startDate, endDate);
    const closingRate = await getClosingRate(
      brand,
      customers,
      startDate,
      endDate
    );
    const goodFitStatusId = getGoodFitStatusId(brand);
    const salesStatusId = getSaleStatusId(brand);

    //logger.info("actualCPGF: ", actualCPGF);
    if (
      targetCPGF &&
      actualCPGF &&
      actualCAC &&
      closingRate &&
      salesStatusId &&
      goodFitStatusId
    ) {
      const goodFits = await getLeadsTotalByStatus(
        brand,
        customers,
        goodFitStatusId,
        startDate,
        endDate
      );

      const sales = await getLeadsTotalByStatus(
        brand,
        customers,
        salesStatusId,
        startDate,
        endDate
      );

      const brandOptimizationScore = targetCPGF / actualCPGF;
      const clientOptimizationScore = closingRate / 50;
      const budgetSpent = await getBudgetSpent(brand, startDate, endDate);
      const targetSales = budgetSpent / targetCAC;
      const salesOptimizationScore = sales / targetSales;
      // logger.info(
      //   "targetSales/budgetSpent/targetCAC/sales/SPO: ",
      //   targetSales,
      //   budgetSpent,
      //   targetCAC,
      //   sales,
      //   salesOptimizationScore
      // );

      const brandOptimizationStats: {
        //Adsurgent Performance Optimization Score
        BPOscore: number;
        //Client Performance Optimization Score
        CPOscore: number;
        //Sales Performance Optimization Score
        SPOscore: number;
        budgetSpent: number;
        targetCPGF: number;
        actualCPGF: number;
        actualCAC: number;
        closingRate: number;
        goodFits: number;
        sales: number;
        startDate: Dayjs;
        endDate: Dayjs;
      } = {
        BPOscore: Math.round(brandOptimizationScore * 100),
        CPOscore: Math.round(clientOptimizationScore * 100),
        SPOscore: Math.round(salesOptimizationScore * 100),
        budgetSpent,
        targetCPGF,
        actualCPGF,
        actualCAC,
        closingRate,
        goodFits,
        sales,
        startDate,
        endDate,
      };

      //logger.info("getBrandOptimizationScore: ", brandOptimizationScore);
      return brandOptimizationStats;
    } else return null;
  } else {
    logger.warn(
      "getBrandOptimizationScore: Sorry, but we're missing data to complete the function."
    );
  }
};

export const getDataForSiteTrafficChart = (brandTraffic: DailyTraffic) => {
  //helper function
  //https://stackoverflow.com/questions/23247859/better-way-to-sum-a-property-value-in-an-array
  const getSumOfPropertiesInArray = (items: any[], prop: string) => {
    return items.reduce(function (a, b) {
      return a + b[prop];
    }, 0);
  };
  //sets the date range we want to look at
  const dateRanges = getDateRangesByMonth(12);
  let brandStats: BrandStats = [];
  //   logger.info("dateRanges: ", dateRanges);
  //loop through all brand sites
  for (const site of brandTraffic) {
    let yearlyStats: YearlyStats = {
      site: site._id,
      stats: [],
    };
    //loop through all months/dateRanges
    dateRanges.map(async (month) => {
      const startDate = month.startDate;
      const endDate = month.endDate;

      let dataForMonth: TrafficData = {
        site: site._id,
        startDate: startDate,
        endDate: endDate,
        traffic: [],
      };
      for await (const day of site.dailyStats) {
        if (
          day.timestamp.toString() >= startDate.toString() &&
          day.timestamp.toString() <= endDate.toString()
        ) {
          dataForMonth.traffic.push(day);
        }
      }

      const monthlyStats: MonthlyStats = {
        month: dayjs(startDate).format("MMM"),
        clicks: getSumOfPropertiesInArray(dataForMonth.traffic, "clicks"),
        impressions: getSumOfPropertiesInArray(
          dataForMonth.traffic,
          "impressions"
        ),
        startDate,
        endDate,
      };

      yearlyStats.stats.push(monthlyStats);
      //brandTrafficFormatted.push({site: site._id, traffic: yearlyStats});
      //logger.info("monthlyStats: ", monthlyStats);
    });
    brandStats.push(yearlyStats);
  }
  //const siteOneData = brandStats[0].stats;
  if (brandStats) {
    return brandStats;
  } else return null;
};

const getCustomerOldestMessage = (customer: Customer) => {
  if (customer && customer.messages && customer.messages.length > 0) {
    const customerOldestMessage = customer.messages.reduce(
      (r, o) => (o.timestamp < r.timestamp ? o : r)
      //o.timestamp > r.timestamp ? o : r
    );
    return customerOldestMessage;
  } else {
    logger.warn(
      "getCustomerOldestMessage: Sorry, but missing data to get customerOldestMessage."
    );
    return null;
  }
};

const getLeadsAsPerformanceData = (customersData: Customer[]) => {
  let leads: any[] = [];
  customersData.map((customer: Customer) => {
    const arrayLength = customer.messages?.length;
    //logger.info(arrayLength);
    if (
      customer &&
      customer.messages &&
      arrayLength &&
      arrayLength > 0 &&
      customer.isSpam !== true &&
      customer.salesStatus &&
      customer.salesStatus > 1
    ) {
      //   const customerOldestMessage = customer.messages.reduce(
      //     (r, o) => (o.timestamp < r.timestamp ? o : r)
      //     //o.timestamp > r.timestamp ? o : r
      //   );
      const customerOldestMessage = getCustomerOldestMessage(customer);
      const customerLatestMessage = customer.messages.reduce(
        (r, o) => (o.timestamp > r.timestamp ? o : r)
        //o.timestamp > r.timestamp ? o : r
      );

      if (
        customer.brandId &&
        customer.id &&
        customerOldestMessage &&
        customerOldestMessage.customerName &&
        customerOldestMessage.timestamp &&
        customerLatestMessage.timestamp
      ) {
        const lead: LeadAsPerformanceData = {
          brandId: customer.brandId,
          customerId: customer.id,
          initialCustomerName: customerOldestMessage.customerName,
          dateOfFirstContact: dayjs(customerOldestMessage.timestamp),
          dateOfMostRecentContact: dayjs(customerLatestMessage.timestamp),
          isSameDate:
            customerOldestMessage.timestamp === customerLatestMessage.timestamp
              ? true
              : false,
          source: customerOldestMessage.source,
          urlParams: customerOldestMessage.urlParams
            ? customerOldestMessage.urlParams
            : null,
        };
        leads.push(lead);
      }
      //const data = [{ name: "Page A", uv: 400, pv: 2400, amt: 2400 }];
    }
  });
  return leads;
};

export const getDateRangesByMonth = (numberOfMonths: number) => {
  const monthIndices = Array.from({ length: numberOfMonths }, (_, i) => i + 1);
  let dateRanges: { startDate: Dayjs; endDate: Dayjs }[] = [];
  monthIndices.map((monthIndex) => {
    const date = dayjs().subtract(monthIndex, "month");
    const dayNumber = date.date();
    const firstDayOfMonth = date.subtract(dayNumber - 1, "day");
    //.format("YYYY-MM-DD");
    const daysInMonth = dayjs(firstDayOfMonth).daysInMonth();

    const daysToAdd = daysInMonth - 1;

    const lastDayOfMonth = dayjs(firstDayOfMonth).add(daysToAdd, "day");
    //.format("YYYY-MM-DD");
    dateRanges.push({ startDate: firstDayOfMonth, endDate: lastDayOfMonth });
  });
  //logger.info("getDateRangesByMonth, dateRanges: ", dateRanges);
  const dateRangesReversed = [...dateRanges].reverse();
  return dateRangesReversed;
};

export const getLeadsByMonthOfFirstContact = (customersData: Customer[]) => {
  //Note that customersData is usually referring to a specific brand already

  //build an array of numbers 1-37
  //37 is so that we can get all months needed for three year-over-year reports

  //we want to go back 3 years + the amount of months we already have this year.
  const currentMonthNumber = dayjs().month();
  const monthsToGoBack = 36 + currentMonthNumber;
  const dateRangesLastXMonths = getDateRangesByMonth(monthsToGoBack);
  //logger.info("dateRangesLastXMonths: ", dateRangesLastXMonths);

  if (customersData) {
    //here is a list of leads with data needed for crunching performance metrics
    const leads: LeadAsPerformanceData[] =
      getLeadsAsPerformanceData(customersData);

    //separate by month (date range)
    let leadsByMonth: LeadAsPerformanceData[][] | null = [];
    dateRangesLastXMonths.map(
      (dateRange: { startDate: Dayjs; endDate: Dayjs }) => {
        const filtered = leads.filter((lead) =>
          //https://day.js.org/docs/en/plugin/is-between
          dayjs(lead.dateOfFirstContact).isBetween(
            dateRange.startDate,
            dateRange.endDate,
            "day",
            "[)"
          )
        );

        //logger.info("leadsByMonth: ", leadsByMonth);
        leadsByMonth!.push(filtered);
      }
    );

    return leadsByMonth;
  } else return null;
};

const getDataQuality = (customersData: Customer[]) => {
  const totalCustomers = customersData.length;
  const totalCustomersWithoutStatus = () => {
    const customersWithoutStatus = customersData.filter(
      (customer) => customer.salesStatus === 0
    );

    //logger.info("customersWithoutStatus:", customersWithoutStatus);
    return customersWithoutStatus.length;
  };
  const dataQualityRaw = totalCustomersWithoutStatus() / totalCustomers;
  const dataQualityPercentage = 100 - Math.round(dataQualityRaw * 100);
  //   logger.info(
  //     "totalCustomersWithoutStatus: ",
  //     totalCustomersWithoutStatus(),
  //     "totalCustomers: ",
  //     totalCustomers,
  //     "dataQualityRaw: ",
  //     dataQualityRaw,
  //     "dataQualityPercentage: ",
  //     dataQualityPercentage
  //   );
  return dataQualityPercentage;
};

export const getDataForGoodFitsChart = (customersData: Customer[]) => {
  //this should be an array of arrays
  const leadsByMonthOfFirstContact =
    getLeadsByMonthOfFirstContact(customersData);

  //logger.info("leadsByMonthOfFirstContact: ", leadsByMonthOfFirstContact);

  //   const thisYear = dayjs().year();
  //   const lastYear = thisYear - 1;
  //   const twoYearsAgo = thisYear - 2;

  if (leadsByMonthOfFirstContact) {
    let monthlyTallies: any[] = [];
    //logger.info("leadsByMonthOfFirstContact: ", leadsByMonthOfFirstContact);
    leadsByMonthOfFirstContact.map((monthData) => {
      if (
        monthData &&
        monthData.length > 0 &&
        monthData[0] &&
        monthData[0].dateOfFirstContact
      ) {
        const monthNumber = dayjs(monthData[0].dateOfFirstContact).format("MM");
        const month = dayjs(monthData[0].dateOfFirstContact).format("MMM");
        const year = dayjs(monthData[0].dateOfFirstContact).format("YYYY");
        const data = {
          month: month,
          monthNumber: monthNumber,
          year: year,
          count: monthData.length,
        };

        monthlyTallies.push(data);
      }
      //const length = monthData.length();
    });

    monthlyTallies.sort((a, b) => a.monthNumber - b.monthNumber).reverse();
    //logger.info("monthlyTallies: ", monthlyTallies);

    //https://stackoverflow.com/questions/40774697/how-can-i-group-an-array-of-objects-by-key
    const reducedMonthlyTallies = monthlyTallies
      .reverse()
      .reduce(function (r, a) {
        r[a.month] = r[a.month] || [];
        r[a.month].push(a);
        return r;
      }, Object.create(null));
    //logger.info("reducedMonthlyTallies: ", reducedMonthlyTallies);

    let dataForChart: any[] = [];

    // reducedMonthlyTallies.map((monthlyTally: any) => {
    for (const month in reducedMonthlyTallies) {
      if (Object.prototype.hasOwnProperty.call(reducedMonthlyTallies, month)) {
        // do stuff
        //monthlyTally = "JAN" or "JUN" etc.
        //logger.info(reducedMonthlyTallies);

        //all of these ternary if-checks are for companies where the data doesn't go back 3+ years
        const transposed = {
          month: month,
          [reducedMonthlyTallies[month][0]
            ? reducedMonthlyTallies[month][0].year
            : "Unavailable"]: reducedMonthlyTallies[month][0]
            ? reducedMonthlyTallies[month][0].count
            : 0,
          [reducedMonthlyTallies[month][1]
            ? reducedMonthlyTallies[month][1].year
            : "Unavailable"]: reducedMonthlyTallies[month][1]
            ? reducedMonthlyTallies[month][1].count
            : 0,
          [reducedMonthlyTallies[month][2]
            ? reducedMonthlyTallies[month][2].year
            : "Unavailable"]: reducedMonthlyTallies[month][2]
            ? reducedMonthlyTallies[month][2].count
            : 0,
          [reducedMonthlyTallies[month][3]
            ? reducedMonthlyTallies[month][3].year
            : "Unavailable"]: reducedMonthlyTallies[month][3]
            ? reducedMonthlyTallies[month][3].count
            : 0,
          //   [reducedMonthlyTallies[monthlyTally][3].year]:
          //     reducedMonthlyTallies[monthlyTally][3].count,
        };
        dataForChart.push(transposed);
      }
    }

    //logger.info("dataForChart: ", dataForChart);
    //logger.info("dataForChart: ", dataForChart);
    return { data: dataForChart, dataQuality: getDataQuality(customersData) };
  } else return { data: null, dataQuality: null };

  //    format we need for the chart:
  //    const dataForChart = [
  //     {
  //       name: "Jan",
  //       [thisYear]: 40,
  //       [lastYear]: 30,
  //       [twoYearsAgo]: 20,
  //     }, ...
  //     ;
};
