const OAuth2CallbackPage: React.FunctionComponent = (props) => {
  return (
    <>
      <h1>oauth2callback Page</h1>
      <p>Codes are in URL params. See the params.</p>
      <p></p>
    </>
  );
};

export default OAuth2CallbackPage;
