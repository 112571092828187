import { createSlice } from "@reduxjs/toolkit";
import { Customer } from "../types";
//import { useParams } from "react-router-dom";
import { InitialUiState } from "../types";
import { Phone, Phones, Message } from "../types";
//import { Brand } from "../types";
//import { isNullOrUndefined } from "util";
import { Howl, Howler } from "howler";
import dayjs from "dayjs";
import { SearchFilters } from "../types";
import { adsurgentDayJsFormat } from "../util/util";
import { RootState } from ".";

const getDate90DaysAgo = () => {
  const today = new Date();
  const ninetyDaysAgo: number = new Date().setDate(today.getDate() - 90);
  return ninetyDaysAgo;
};

// const todayIso = dayjs().toISOString();
// const ninetyDaysAgoIso = dayjs().subtract(90, "day").toISOString();
// const oneYearAgoIso = dayjs().subtract(365, "day").toISOString();
const today: string = dayjs().add(1, "day").format(adsurgentDayJsFormat);
const ninetyDaysAgo: string = dayjs()
  .subtract(90, "day")
  .format(adsurgentDayJsFormat);
const oneYearAgo: string = dayjs()
  .subtract(365, "day")
  .format(adsurgentDayJsFormat);
//logger.info("today: ", today, "oneYearAgo: ", oneYearAgo);

const initialUiState: InitialUiState = {
  isPhoneDialogOpen: false,
  isSmsDialogOpen: false,
  isEmailDialogOpen: false,
  isReviewRequestDialogOpen: false,
  isCustomerEditDialogOpen: false,
  isNotesEditDialogOpen: false,
  isUpgradeNotesEditDialogOpen: false,
  isHistoryDrawerOpen: false,
  isSoundPlaying: false,
  activeUpgradeId: "",
  searchFilters: {
    dateRange: [oneYearAgo, today],
    searchString: "",
    showOnlyNeedsAttention: false,
    showSpam: false,
    showStatus: 99,
  },
  filteredCustomerCount: 0,
  //isGlobalWavesurferPlaybackStopped: true,
  isCustomerFetchStopped: false,
  pendingMessage: null,
};

const uiSlice = createSlice({
  name: "ui",
  initialState: initialUiState,
  reducers: {
    togglePhoneDialog(state, action) {
      const newState = action.payload;
      state.isPhoneDialogOpen = newState;
    },
    toggleSmsDialog(state, action) {
      const newState = action.payload;
      state.isSmsDialogOpen = newState;
    },
    toggleEmailDialog(state, action) {
      const newState = action.payload;
      state.isEmailDialogOpen = newState;
    },
    toggleReviewRequestDialog(state, action) {
      const newState = action.payload;
      state.isReviewRequestDialogOpen = newState;
    },
    toggleCustomerEditDialog(state, action) {
      const newState = action.payload;
      state.isCustomerEditDialogOpen = newState;
    },
    toggleNotesEditDialog(state, action) {
      const newState = action.payload;
      state.isNotesEditDialogOpen = newState;
    },
    toggleUpgradeNotesEditDialog(state, action) {
      const newState = action.payload;
      state.isUpgradeNotesEditDialogOpen = newState;
    },
    setActiveUpgradeId(state, action) {
      const upgradeId = action.payload;
      state.activeUpgradeId = upgradeId;
    },
    toggleHistoryDrawer(state, action) {
      const newState = action.payload;
      state.isHistoryDrawerOpen = newState;
    },
    toggleIsSoundPlaying(state, action) {
      const newState = action.payload;
      state.isSoundPlaying = newState;
    },
    updateSearchFilters(state, action) {
      const newState: SearchFilters = action.payload;
      state.searchFilters = newState;
    },
    resetSearchFilters(state, action) {
      state.searchFilters = initialUiState.searchFilters;
    },
    setFilteredCustomerCount(state, action) {
      const newState: number = action.payload;
      state.filteredCustomerCount = newState;
    },
    resetUiState(state, action) {
      state.activeUpgradeId = initialUiState.activeUpgradeId;
      state.filteredCustomerCount = initialUiState.filteredCustomerCount;
      state.isCustomerEditDialogOpen = initialUiState.isCustomerEditDialogOpen;
      state.isEmailDialogOpen = initialUiState.isEmailDialogOpen;
      state.isHistoryDrawerOpen = initialUiState.isHistoryDrawerOpen;
      state.isNotesEditDialogOpen = initialUiState.isNotesEditDialogOpen;
      state.isPhoneDialogOpen = initialUiState.isPhoneDialogOpen;
      state.isReviewRequestDialogOpen =
        initialUiState.isReviewRequestDialogOpen;
      state.isSmsDialogOpen = initialUiState.isSmsDialogOpen;
      state.isSoundPlaying = initialUiState.isSoundPlaying;
      state.isUpgradeNotesEditDialogOpen =
        initialUiState.isUpgradeNotesEditDialogOpen;
      state.searchFilters = initialUiState.searchFilters;
      // state.isGlobalWavesurferPlaybackStopped =
      //   initialUiState.isGlobalWavesurferPlaybackStopped;
      state.isCustomerFetchStopped = initialUiState.isCustomerFetchStopped;
      state.pendingMessage = initialUiState.pendingMessage;
    },
    setIsCustomerFetchStopped(state, action) {
      const newState: boolean = action.payload;
      state.isCustomerFetchStopped = action.payload;
    },
    setPendingMessage(state, action) {
      const newPendingMessage: Message = action.payload;
      state.pendingMessage = newPendingMessage;
    },
    // stopGlobalWavesurferPlayback(state, action) {
    //   //const newState: boolean = action.payload;
    //   state.isGlobalWavesurferPlaybackStopped = true;
    // },
  },
});

const getCustomerName = (customer: Customer) => {
  if (customer.verifiedCustomerName) {
    return customer.verifiedCustomerName;
  } else if (customer.messages) {
    const foundCustomerWithName = customer.messages.find(
      (message) => message.customerName
    );
    const tempName = foundCustomerWithName!.customerName
      ? foundCustomerWithName!.customerName
      : null;
    return tempName;
  } else return null;
};

const getCustomerPhones = (customer: Customer) => {
  //build the primary phone object
  const getPrimaryPhone = () => {
    const nullPhone: Phone = {
      countryCode: null,
      number: null,
      label: null,
    };
    let phone: Phone = nullPhone;
    phone.countryCode = null;
    phone.label = "Last Used";
    const phoneNumber = () => {
      if (customer.messages) {
        const firstFoundMessageWithNumber = customer.messages.find(
          (message) => message.customerPhone
        );
        if (
          firstFoundMessageWithNumber &&
          firstFoundMessageWithNumber.customerPhone
        ) {
          return firstFoundMessageWithNumber.customerPhone;
        } else return null;
      } else return null;
    };
    phone.number = phoneNumber();
    return phone;
  };

  //build the alt phones array of objects
  const getAltPhones = () => {
    const nullPhone: Phone = {
      countryCode: null,
      label: null,
      number: null,
    };
    const tempPhonesArray = [nullPhone];
    return tempPhonesArray;
  };
  var phones: Phones = {};
  //build the overall phones object
  phones.primary = getPrimaryPhone();
  phones.alternates = getAltPhones();

  return phones;
};

export const uiActions = uiSlice.actions;

export default uiSlice;
