//https://blog.datalust.co/choosing-the-right-log-levels/
//we use the same log levels in production as we do in development
//but we want to hide the frontend logs from the user
import pino from "pino";

//require("dotenv").config();

const dev =
  !process.env.NODE_ENV ||
  (process.env.NODE_ENV && process.env.NODE_ENV.trim() !== "production");

const getLogger = () => {
  if (dev) {
    //development environment
    const logger = pino({
      transport: {
        target: "pino-pretty",
        options: {
          translateTime: "UTC:yyyy-mm-dd'T'HH:MM:sso",
          colorize: true,
        },
      },
    });

    return logger;
  } else {
    //production environment, we don't want to use pino-pretty here
    const logger = pino({ browser: { asObject: true, disabled: true } });
    return logger;
  }
};

const logger = getLogger();

export default logger;
