import classes from "./StatusCards.module.css";
import { Grid } from "@mui/material";
import StatusCard from "./StatusCard";
import React from "react";
import { useGetBrandQuery, useGetBrandsQuery } from "../../store/api";
import { useParams } from "react-router-dom";
import { BrandStatus } from "../../types";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useGetCustomersQuery } from "../../store/api";
import logger from "../../logger";

// import { useSelector } from "react-redux";
// import { Customer } from "../../types";
// import { RootState } from "../../store";

const StatusCards: React.FunctionComponent = () => {
  const params = useParams();
  const brandId: string = params.brandId!;

  const {
    data: customersData,
    error: customersError,
    isLoading: customersIsLoading,
  } = useGetCustomersQuery({ brandId: brandId! });
  //we are checking this in the brand container for isLoading & Error, so we can
  //assert existence in this component without checking
  const {
    data: brandData,
    // error: brandError,
    // isLoading: brandIsLoading,
  } = useGetBrandQuery({ brandId: brandId }, {});

  const startDate = new Date();
  const endDate = new Date();

  const getConversionCount = (
    statusId: number,
    startDate: Date,
    endDate: Date
  ) => {
    if (customersData) {
      //a match must have a sales status equal to the statusId
      // AND it's OLDEST message (original message), must fall between
      // the start date and the end date
      const matchingCustomers = customersData.filter((customer) => {
        if (customer.salesStatus === statusId && customer.messages) {
          return true;
        } else return false;
      });
      const count = matchingCustomers.length;
      return count;
    } else {
      return 0;
    }
  };



  const getConversionRate = (
    statusId: number,
    startDate: Date,
    endDate: Date
  ) => {
    if (customersData) {
      const matchingCustomers = customersData.filter(
        (customer) => customer.salesStatus === statusId
      );
      //status 1 is "not a good fit", so we need special logic
      if (statusId >= 3) {
        const oneStatusLower = statusId - 1;
        const customersOneStatusLower = customersData.filter(
          (customer) => customer.salesStatus === oneStatusLower
        );
        // logger.info(
        //   "status: ",
        //   statusId,
        //   "matching:",
        //   matchingCustomers.length,
        //   "statusLower: ",
        //   customersOneStatusLower.length
        // );
        const conversionRate =
          matchingCustomers.length / customersOneStatusLower.length;
        return conversionRate;
      } else if (statusId === 2) {
        const statusOneCustomers = customersData.filter(
          (customer) => customer.salesStatus === 0
        );
        const conversionRate =
          matchingCustomers.length / statusOneCustomers.length;
        return conversionRate;
      } else if (statusId === 0) {
        return 0;
      } else return 0;
    } else {
      return 0;
    }
  };

  if (brandData!.statuses) {
    const displayStatuses = brandData!.statuses.reduce(function (
      result: BrandStatus[],
      status: BrandStatus
    ) {
      if (status.id !== 1) {
        result.push(status);
      }
      return result;
    },
    []);

    const statusCount = displayStatuses.length;

    return (
      <>
        <h1 style={{ textAlign: "center" }}>Funnel Performance</h1>
        <p>
          The marketing funnel performance dashboard reveals any weak points in
          the overall marketing funnel. These metrics reveal any problems with
          your marketing performance quickly and at-a-glance. Marketing is a
          competitive sport and we need to constantly adapt to the market. These
          metrics are your brand's vital signs.
        </p>
        <Grid container columns={statusCount} spacing={1}>
          {displayStatuses.map((status: BrandStatus) => (
            <StatusCard
              key={status.id}
              title={status.label}
              goodFit={status.isGoodFit}
              sale={status.isSale}
              postSale={status.isPostSale}
              conversionRate={getConversionRate(status.id, startDate, endDate)}
              conversionCount={getConversionCount(
                status.id,
                startDate,
                endDate
              )}
              statusCount={statusCount}
            />
          ))}
        </Grid>
      </>
    );
  } else
    return (
      <div>
        Sorry, something went wrong while loading the brand and its statuses...
      </div>
    );
};

export default StatusCards;
