import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import MuiTooltip from "@mui/material/Tooltip";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  getDataForGoodFitsChart,
  getDataForSiteTrafficChart,
} from "../util/performanceData";
import { useParams } from "react-router";
import { useGetCustomersQuery } from "../store/api";
import dayjs from "dayjs";
import { Divider } from "@mui/material";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ChartMultiSelect from "./ChartMultiSelect";
import { useGetDailyTrafficQuery } from "../store/api";
import { useEffect, useState, useMemo } from "react";
//import { DailyTraffic, MonthlyStats } from "../types";
import {
  DailyTraffic,
  MonthlyStats,
  TrafficData,
  LeadAsPerformanceData,
  BrandStats,
} from "../types";
import logger from "../logger";

const ChartTraffic: React.FunctionComponent = () => {
  const params = useParams();
  const brandId: string | undefined = params.brandId;
  const {
    data: customersData,
    error: customersError,
    isLoading: customersIsLoading,
  } = useGetCustomersQuery({ brandId: brandId! }, { pollingInterval: 10000 });
  //const data = [{ name: "Page A", uv: 400, pv: 2400, amt: 2400 }];
  const {
    data: dailyTrafficData,
    error: dailyTrafficError,
    isLoading: dailiyTrafficIsLoading,
  } = useGetDailyTrafficQuery({ brandId: brandId! });

  const [siteDataForChart, setSiteDataForChart]: [
    BrandStats | null | undefined,
    any
  ] = useState();

  useMemo(() => {
    if (dailyTrafficData) {
      const chartData = getDataForSiteTrafficChart(dailyTrafficData);
      setSiteDataForChart(chartData);
    }
  }, [dailyTrafficData]);

  if (
    customersIsLoading ||
    (siteDataForChart && siteDataForChart.length === 0)
  ) {
    return <div>Loading...</div>;
  } else if (
    customersData &&
    dailyTrafficData &&
    siteDataForChart &&
    siteDataForChart.length > 0
  ) {
    //we need traffic data by month for last available months, up to 24 months
    //logger.info("siteDataForChart: ", siteDataForChart);
    const renderLineChart = (lineChartProps: {
      height: number | undefined;
      width: number | undefined;
      siteData: any[];
      siteTitle: string;
    }) => (
      <>
        <h1>{lineChartProps.siteTitle}</h1>
        <LineChart
          width={lineChartProps.width}
          height={300}
          data={lineChartProps.siteData}
          margin={{ top: 0, right: 20, bottom: 5, left: 0 }}
          style={{ display: "block" }}
        >
          <Line
            type="monotone"
            dataKey="clicks"
            stroke="darkgray"
            strokeWidth={2}
          />
          {/* <Line
            type="monotone"
            dataKey="impressions"
            stroke="green"
            strokeWidth={2}
          /> */}
          {/* <Line
            type="monotone"
            dataKey="newLeads"
            stroke="gray"
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey="goodFits"
            stroke="lightgray"
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey="sales"
            stroke="green"
            strokeWidth={2}
          /> */}
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis dataKey="month" />
          <YAxis />
          <Legend />
          <Tooltip />
        </LineChart>
      </>
    );

    return (
      <AutoSizer>
        {({ height, width }) => (
          <div style={{ width: width }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h1>Website Traffic</h1>
                <p>
                  Your website traffic is one indication of how many people are
                  exposed to your brand over time. This chart shows how your
                  website(s) traffic over time.
                </p>
              </div>
              <div>{/* <ChartMultiSelect /> */}</div>
            </div>
            <Divider style={{ marginTop: 25, marginBottom: 25 }}></Divider>
            {siteDataForChart.map((singleSite) =>
              renderLineChart({
                height: height,
                width: width,
                siteData: [...singleSite.stats],
                siteTitle: singleSite.site.substring(
                  singleSite.site.indexOf(":") + 1
                ),
              })
            )}
          </div>
        )}
      </AutoSizer>
    );
  } else return <div>Sorry, no chart data available.</div>;
};
export default ChartTraffic;
