import { useParams, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import MiniDrawer from "./MiniDrawer";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
//import { brandActions } from "../../store/brand-slice";
import { ClassNames } from "@emotion/react";
import classes from "./BrandContainer.module.css";
import React, { useEffect } from "react";
import { useGetBrandQuery, useGetBrandsQuery } from "../../store/api";
import { ValidBrandId, Brand } from "../../types";
import { uiActions } from "../../store/uiSlice";
import LoadingScreen from "../LoadingScreen";

const BrandContainer: React.FunctionComponent = () => {
  //using react-redux, grab a value from the state and automatically subscribe to any changes for re-rendering
  //const brand = useSelector((state) => state.companyLeads);

  const params = useParams();
  const brandId: string = params.brandId!;
  //get an array of valid brand ids
  const {
    data: validBrandsData,
    error: validBrandsError,
    isLoading: validBrandBrandsIsLoading,
  } = useGetBrandsQuery({});

  // logger.info(JSON.stringify(validBrandsData));
  // logger.info(brandId);

  //{ data, error, isLoading }, but to rename, we can:
  //const { data :googleData, isLoading: googleLoading, isFetching: googleIsfetching }
  const {
    data: brandData,
    error: brandError,
    isLoading: brandIsLoading,
  } = useGetBrandQuery({ brandId: brandId }, {});

  if (brandError && "data" in brandError) {
    const errorMsgData = JSON.stringify(brandError.data);
    const errorMsgObject = JSON.parse(errorMsgData);
    return (
      <div className={classes["brand-container"]}>
        <LoadingScreen
          statusMsg={
            "Sorry, but we are having trouble loading the brand. (" +
            brandError.status +
            ") " +
            errorMsgObject.message
          }
        />
      </div>
    );
  }

  if (brandIsLoading) {
    return <LoadingScreen statusMsg="Brand is loading..." />;
  }

  //logger.info(validBrandsData);

  if (brandData && validBrandsData) {
    // if (validBrandsData.includes(brandId)) {
    const brand: Brand = brandData;
    // const dispatch = useDispatch();

    // const loadBrand = () => {
    //   dispatch(uiActions.loadBrand(brand));
    // };
    //do we set the brandId in the redux state, so that it can be used elsewhere
    //do we set the initial state using the params?
    //where is the best place for this???
    //maybe we dont' useParams here either?

    // const dispatch = useDispatch();
    // const brandChangeHandler = (brandId: string) => {
    //   //update the brand in redux state slice
    //   dispatch(uiActions.loadBrand(brandObject));
    // };
    //make sure brandId in URL is a valid brand
    // if (validBrandIds.includes(brandId)) {
    //   brandChangeHandler(brandId);

    return (
      <div className={classes["brand-container"]}>
        <MiniDrawer>
          <Outlet />
        </MiniDrawer>
      </div>
    );
  } else
    return (
      <div className={classes["brand-container"]}>
        <LoadingScreen statusMsg="Problem loading brands. We may be experiencing a cloud service outage." />
      </div>
    );
};

export default BrandContainer;
