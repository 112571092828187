//https://codesandbox.io/s/error-when-beginning-editor-content-with-list-nfj7f?file=/src/components/NoteEditor.tsx

import {
  BlockButton,
  Element,
  Leaf,
  MarkButton,
  Toolbar,
} from "./SlateRTEComponents";
import { Editable, Slate, withReact } from "slate-react";
import React, { useCallback, useMemo } from "react";

import { Descendant, Node } from "slate";
import { createEditor } from "slate";
import { withHistory } from "slate-history";

import FormatBoldIcon from "@mui/icons-material/FormatBold";

interface NoteEditorProps {
  content: Node[];
  onChangeHandler: Function;
  //setContent: React.Dispatch<React.SetStateAction<Descendant[] | undefined>>;
}

function NoteEditor({ content, onChangeHandler }: NoteEditorProps) {
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  //slate-react
  return (
    <Slate
      editor={editor}
      value={content}
      onChange={(newContent) => onChangeHandler(newContent)}
    >
      {/* @ts-ignore */}
      <Toolbar>
        <MarkButton format="bold" icon="format_bold" />
        <MarkButton format="italic" icon="format_italic" />
        <MarkButton format="underline" icon="format_underlined" />
        <MarkButton format="code" icon="code" />
        <BlockButton format="heading-one" icon="looks_one" />
        <BlockButton format="heading-two" icon="looks_two" />
        <BlockButton format="block-quote" icon="format_quote" />
        <BlockButton format="numbered-list" icon="format_list_numbered" />
        <BlockButton format="bulleted-list" icon="format_list_bulleted" />
      </Toolbar>
      <Editable
        placeholder="Write something..."
        style={{ minHeight: 600 }}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        spellCheck
        autoFocus
      />
    </Slate>
  );
}

export default NoteEditor;
