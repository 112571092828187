import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
//import { contactActions } from "../store/contact-slice";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { MenuItem } from "@mui/material";
import NumberFormat from "react-number-format";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { IconButton } from "@mui/material";
import { ClassNames } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import { RootState } from "../store";
import { Phone, Customer } from "../types";
import { uiActions } from "../store/uiSlice";
//import CustomerEditDialog from "./CustomerEditDialog";
import {
  useGetCustomerQuery,
  useUpdateCustomerMutation,
  useGetBrandQuery,
} from "../store/api";
import { useParams } from "react-router-dom";
import {
  getCustomerPhone,
  getCustomerPhones,
  getBusinessPhone,
  getTrackingPhone,
  formatPhoneNumber,
} from "../util/util";
import { useSendSmsMutation } from "../store/api";
import { useState, useEffect } from "react";
//we are using react-hook-form to manage forms, but
//we need to utilize their Controller feature to make it work
//with Material UI (MUI), and Controller takes care of registration process
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import SendIcon from "@mui/icons-material/Send";
import logger from "../logger";

type OutboundPhone = number | null;

const ContactViaReviewRequestDialog: React.FunctionComponent = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const ui = useSelector((state: RootState) => state.ui);

  const params = useParams();
  const brandId: string = params.brandId!;
  const customerId: string = params.customerId!;

  const reviewRequestImage = React.createElement(
    "img",
    {
      src: "https://aos.nyc3.digitaloceanspaces.com/images%2Fcelebrate-that-shit.gif",
      alt: "Take a bow!",
    },
    null
  );

  //we are already checking isLoading & error in LeadHistory (wrapper)
  //so we can just assert the customerData here
  const {
    data: customerData,
    // error: customerError,
    // isLoading: customerIsLoading,
  } = useGetCustomerQuery({ brandId: brandId, customerId: customerId }, {});

  const {
    data: brandData,
    // error: customerError,
    // isLoading: customerIsLoading,
  } = useGetBrandQuery({ brandId: brandId });

  const { control, reset, watch } =
    useForm(/*{
    defaultValues: {
      outboundPhoneNumber: "",
    },
  }*/);

  //const [reviewImageReady, setReviewImageReady] = React.useState(false);

  //react-hook-form has a useful way of "watching" field inputs, without causing a re-render
  //this is helpful when you don't need the change to appear on screen (re-render), but just
  //need the input value to send somewhere, but in our case we need it
  //https://www.youtube.com/watch?v=3qLd69WMqKk

  useEffect(() => {
    if (customerData && brandData) {
      let defaultValues: any = {};
      defaultValues.businessPhoneNumber = getBusinessPhone(
        brandData,
        customerData
      );
      defaultValues.customerPhoneNumber = getCustomerPhone(customerData);
      //only pre-populate the link if one is avaiable in the brand object
      if (brandData.reviewRequestLink) {
        defaultValues.messageToSend =
          "Thank you for being a " +
          brandData?.name +
          " customer!  We would appreciate your honest review: " +
          brandData?.reviewRequestLink;
      }
      //console.log("outboundPhoneNumber: ", defaultValues.businessPhoneNumber);
      reset({ ...defaultValues });
    }
  }, [customerData]);

  const businessPhone = watch("businessPhoneNumber");
  const messageToSend = watch("messageToSend");
  //console.log(messageToSend);

  const [
    sendSms, // This is the mutation trigger
    { isLoading: isSendingSms }, // This is the destructured mutation result
  ] = useSendSmsMutation();

  // const customer: Customer = ui.customer;
  const dispatch = useDispatch();
  const [readyAlertOpen, setReadyAlertOpen] = React.useState(false);
  const [notReadyAlertOpen, setNotReadyAlertOpen] = React.useState(false);
  //let's set phone number for the dropdown select

  //look through the phones that are available on the messages
  //we should default to the most recent phone number, but perhaps give an option
  //of any previously used phone number as well
  //next, let's hit the new endpoint with the correct phone numbers to initiate the call
  if (customerId && customerData && brandData) {
    const customerPhoneNumbers = getCustomerPhones(customerData);
    const customerPhone = getCustomerPhone(customerData);
    // const businessPhone = getBusinessPhone(customerData);

    const trackingPhone = getTrackingPhone(brandData, customerData);
    //logger.info("trackingPhone:", trackingPhone);
    //logger.info("businessPhone:", businessPhone);
    //logger.info("customerPhone:", customerPhone);

    const customerName = customerData!.verifiedCustomerName
      ? customerData!.verifiedCustomerName
      : customerPhone;

    const handleClose = () => {
      dispatch(uiActions.toggleReviewRequestDialog(false));
    };

    const handleOpen = () => {
      uiActions.toggleReviewRequestDialog(true);
    };

    const handleCreateSms = async () => {
      dispatch(uiActions.toggleReviewRequestDialog(false));

      if (trackingPhone && customerPhone && businessPhone) {
        setReadyAlertOpen(true);
        const result = await sendSms({
          brandId: customerData.brandId,
          //phone number for API call should be in format "15552146065"
          callerIdPhone: trackingPhone.toString().replace("+", ""),
          // businessPhone: getBusinessPhone(customerData)
          //   .toString()
          //   .replace("+", ""),
          businessPhone: businessPhone.toString().replace("+", ""),
          customerPhone: getCustomerPhone(customerData)
            .toString()
            .replace("+", ""),
          message: messageToSend,
        });
      } else {
        setNotReadyAlertOpen(true);
       logger.error(
          "Sorry, but we can't create an SMS/Text Message.  We're missing a required phone number (trackingPhone, businessPhone, or customerPhone)."
        );
      }
    };

    // const handlePhoneChange = (event) => {
    //   setPhone(event.target.value);
    // };

    //handle alerts

    const handleReadyAlert = () => {
      setReadyAlertOpen(!readyAlertOpen);
    };

    const handleNotReadyAlert = () => {
      setNotReadyAlertOpen(!notReadyAlertOpen);
    };

    return (
      <>
        <Dialog
          fullScreen={fullScreen}
          open={ui.isReviewRequestDialogOpen}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          PaperProps={{
            sx: {
              minHeight: 400,
              width: fullScreen ? null : 400,
            },
          }}
        >
          {/* <img
            src="https://aos.nyc3.digitaloceanspaces.com/images%2Fcelebrate-that-shit.gif"
            alt="Take a bow..."
          ></img> */}
          {reviewRequestImage}

          <DialogTitle id="responsive-dialog-title">
            <p
              style={{
                fontWeight: 800,
                textAlign: "center",
                marginTop: 0,
                marginBottom: 0,
              }}
            >
              CONGRATS ON THE SALE!
            </p>
            <p style={{ textAlign: "center", marginTop: 0, marginBottom: 0 }}>
              Request a review?
            </p>

            {/* <img src="/images/logo-invert.png" alt=""></img> */}
            {/* <div style={{ float: "right" }}>
              <div
                style={{
                  fontSize: ".7rem",
                  fontStyle: "italic",
                  textAlign: "right",
                }}
              >
                {brandData?.name} Caller ID
              </div>
              <div style={{ fontSize: "1rem" }}>
                {trackingPhone
                  ? formatPhoneNumber(trackingPhone.toString())
                  : ""}
              </div>
            </div> */}
          </DialogTitle>
          <DialogContent>
            {/*phone number to call select from available phone #s */}
            <div>
              <Controller
                render={({ field }) => (
                  <TextField
                    select
                    label="Customer Number"
                    {...field}
                    sx={{
                      marginTop: "20px",
                      width: "100%",
                    }}
                  >
                    {" "}
                    {customerPhoneNumbers
                      ? customerPhoneNumbers.all.map((phoneNumber, index) => {
                          if (phoneNumber) {
                            return (
                              <option value={phoneNumber}>
                                {formatPhoneNumber(phoneNumber.toString())}
                              </option>
                            );
                          }
                        })
                      : ""}
                    {/* <option value={10}>Ten</option>
                    <option value={20}>Twenty</option>
                    <option value={30}>Thirty</option>{" "} */}
                  </TextField>
                )}
                name="customerPhoneNumber"
                control={control}
              />

              {/* //MESSAGE INPUT */}
              <Controller
                name="messageToSend"
                control={control}
                render={({ field }) => (
                  <TextField
                    multiline
                    fullWidth
                    minRows={4}
                    maxRows={4}
                    label="Your Message"
                    {...field}
                    inputProps={{ maxLength: 140 }}
                    sx={{
                      marginTop: "20px",
                      // width: "100%",
                    }}
                  ></TextField>
                )}
              />
            </div>
            <div style={{ fontSize: "small" }}>
              <p>
                Ask for a review if you are confident this customer had a
                positive experience with your brand.
              </p>
            </div>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <Tooltip title="Send Text">
              <IconButton
                color="primary"
                aria-label="send SMS text"
                sx={{ height: "80px", width: "80px" }}
              >
                <SendIcon sx={{ fontSize: "60px" }} onClick={handleCreateSms} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Cancel">
              <IconButton color="primary" aria-label="close call dialog">
                <CloseIcon onClick={handleClose} />
              </IconButton>
            </Tooltip>
          </DialogActions>
        </Dialog>

        <Dialog open={readyAlertOpen} onClose={handleReadyAlert}>
          <Alert
            severity="success"
            color="success"
            role="button"
            icon={<PhoneCallbackIcon />}
            onClose={() => {
              handleReadyAlert();
            }}
            closeText="Got it."
            sx={{
              // width: '80%',
              // margin: 'auto',
              "& .MuiAlert-icon": {
                color: "blue",
              },
              //backgroundColor: "green"
            }}
          >
            <AlertTitle>Text Sent</AlertTitle>
            Text sent to {customerName}. You may dismiss this alert.
          </Alert>
        </Dialog>

        <Dialog open={notReadyAlertOpen} onClose={handleNotReadyAlert}>
          <Alert
            severity="error"
            color="error"
            role="button"
            icon={<PhoneCallbackIcon />}
            onClose={() => {
              handleNotReadyAlert();
            }}
            closeText="Got it."
            sx={{
              // width: '80%',
              // margin: 'auto',
              "& .MuiAlert-icon": {
                color: "red",
              },
              //backgroundColor: "green"
            }}
          >
            <AlertTitle>Oops!</AlertTitle>
            Sorry, but we're missing one of the required phone numbers we need
            to send this text message. Please correct your phone number or
            contact Adsurgent support.
          </Alert>
        </Dialog>
      </>
    );
  } else return <div></div>;
};
export default ContactViaReviewRequestDialog;
