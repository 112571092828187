import React from "react";
import BrandSettings from "../components/BrandSettings";

const NewProfileSettingsPage: React.FunctionComponent = () => {
  return (
    <>
      <BrandSettings />
    </>
  );
};

export default NewProfileSettingsPage;
