import React from "react";
import LeadDividedWindow from "../components/layout/LeadDividedWindow.js";
import { Drawer } from "@mui/material";
import { makeStyles } from "@mui/material";
import { Container } from "@mui/material";
import AutoSizer from "react-virtualized-auto-sizer";
import LeadList from "../components/layout/LeadList";
import { useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LeadHistory from "../components/layout/LeadHistory.js";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { uiActions } from "../store/uiSlice.js";
import CloseIcon from "@mui/icons-material/Close.js";
import classes from "./login.module.css";
import { Grid } from "@mui/material";
import BackgroundImage from "../assets/images/humanoid-face.jpg";
import { useState } from "react";
import LoginForm from "../components/LoginForm";
//import { setCredentials } from "../store/authSlice.js";

const NewLoginPage: React.FunctionComponent = () => {
  const theme = useTheme();
  //const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const dispatch = useDispatch();

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: height, width: width, overflow: "hidden" }}
        >
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              backgroundImage: `url(${BackgroundImage})`,
              height: "100%",
              width: "100%",
              backgroundSize: "cover",
              backgroundPosition: "50% 50%",
              display: { xs: "none", lg: "block" },
            }}
          ></Grid>
          <Grid
            item
            xs={12}
            md={5}
            sx={{ padding: "1em" }}
            //align="center"
            className={classes["login"]}
          >
            <LoginForm />
          </Grid>
        </Grid>
      )}
    </AutoSizer>
  );
};

export default NewLoginPage;
