import dayjs from "dayjs";
import { Customer, Brand, Message } from "../types";

//import { filter } from "lodash";

export const getCustomerEmail = (customer: Customer) => {
  //first, get all the customer messages that actually have an email attached
  const messagesWithCustomerEmail = customer.messages!.filter(
    (message: Message) => {
      if (message.customerEmail) {
        return true;
      } else return false;
    }
  );
  // logger.info("messagesWithBusinessPhone: ", messagesWithBusinessPhone);

  //now get the most recent of those messages
  const mostRecentMessageWithCustomerEmail =
    messagesWithCustomerEmail && messagesWithCustomerEmail.length !== 0
      ? messagesWithCustomerEmail.reduce((r, o) =>
          o.timestamp > r.timestamp ? o : r
        )
      : null;
  // logger.info(
  //   "mostRecentmessageWithBusinessPhone: ",
  //   mostRecentMessageWithBusinessPhone.businessPhone
  // );

  if (
    mostRecentMessageWithCustomerEmail &&
    mostRecentMessageWithCustomerEmail.customerEmail
  ) {
    // logger.info(
    //   "getCustomerEmail:",
    //   mostRecentMessageWithCustomerEmail.customerEmail
    // );

    //now return the businessPhone
    return mostRecentMessageWithCustomerEmail.customerEmail;
  } else {
    return "";
  }
};

export const formatPhoneNumber = (phoneNumber: string) => {
  var cleaned = ("" + phoneNumber).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

export const getProperCase = (name: string) => {
  if (name) {
    return name.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
  } else return "";
};

export const adsurgentHumanDateTime = (date: Date) => {
  const today: Date = new Date();
  const timestamp: Date = new Date(date);
  const differenceInTime: number = today.getTime() - timestamp.getTime();
  const differenceInDays: number = differenceInTime / (1000 * 3600 * 24);
  const sameDay = (d1: Date, d2: Date) => {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };
  const isSameDay = sameDay(today, timestamp);
  const yesterday = (today: Date, d2: Date) => {
    return (
      today.getFullYear() === d2.getFullYear() &&
      today.getMonth() === d2.getMonth() &&
      today.getDate() - 1 === d2.getDate()
    );
  };
  const isYesterday = yesterday(today, timestamp);

  // logger.info(
  //   "Timestamp:",
  //   timestamp,
  //   "isSameDay: ",
  //   isSameDay,
  //   "isYesterday:",
  //   isYesterday
  // );

  if (isSameDay) {
    return "Today";
  } else if (isYesterday) {
    return "Yesterday";
  } else if (differenceInDays < 4) {
    return timestamp.toLocaleDateString("en-us", { weekday: "long" });
    //return format(timestamp);
  } else {
    //const dateFormatted = timestamp.toLocaleDateString();
    const dateFormatted = dayjs(timestamp).format("MM/DD/YY");
    return dateFormatted;
  }
};

//
export const getCustomerName = (customer: Customer) => {
  //if we have a user-verified customer name, let's use it
  if (customer.verifiedCustomerName) {
    //return the verified name,
    return customer.verifiedCustomerName;

    //else, we should use the customer name from the most recent message
  } else {
    const mostRecentMessage = customer.messages
      ? customer.messages.reduce((r, o) => (o.timestamp > r.timestamp ? o : r))
      : null;
    if (mostRecentMessage) {
      return mostRecentMessage.customerName;
      //if all else fails, return null
    } else return null;
  }
};

//
// Let's assume the business phone is the last one used in a call
//
export const getCustomerPhone = (customer: Customer) => {
  //first, get all the customer messages that actually have a businessPhone attached to a message
  const messagesWithCustomerPhone = customer.messages!.filter((message) => {
    if (message.customerPhone) {
      return true;
    } else return false;
  });
  // logger.info("messagesWithBusinessPhone: ", messagesWithBusinessPhone);

  //now get the most recent of those messages
  const mostRecentMessageWithCustomerPhone =
    messagesWithCustomerPhone && messagesWithCustomerPhone.length !== 0
      ? messagesWithCustomerPhone.reduce((r, o) =>
          o.timestamp > r.timestamp ? o : r
        )
      : null;
  // logger.info(
  //   "mostRecentmessageWithBusinessPhone: ",
  //   mostRecentMessageWithBusinessPhone.businessPhone
  // );

  if (
    mostRecentMessageWithCustomerPhone &&
    mostRecentMessageWithCustomerPhone.customerPhone
  ) {
    //logger.info("getCustomerPhone:", msgWithPhone.businessPhone);

    //now return the businessPhone
    return mostRecentMessageWithCustomerPhone.customerPhone;
  } else {
    return "";
  }
};

export const getCustomerPhones = (customer: Customer) => {
  //first, get all the customer messages that actually have a businessPhone attached to a message
  const messagesWithCustomerPhone = customer.messages!.filter((message) => {
    if (message.customerPhone) {
      return true;
    } else return false;
  });

  const customerPhones = messagesWithCustomerPhone.map(
    (msg) => msg.customerPhone
  );

  //https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
  const customerPhonesUnique = [...new Set(customerPhones)];
  //logger.info("customerPhonesUnique: ", customerPhonesUnique);
  // logger.info("messagesWithBusinessPhone: ", messagesWithBusinessPhone);

  //now get the most recent of those messages
  const mostRecentMessageWithCustomerPhone =
    messagesWithCustomerPhone && messagesWithCustomerPhone.length !== 0
      ? messagesWithCustomerPhone.reduce((r, o) =>
          o.timestamp > r.timestamp ? o : r
        )
      : null;
  // logger.info(
  //   "mostRecentmessageWithBusinessPhone: ",
  //   mostRecentMessageWithBusinessPhone.businessPhone
  // );

  if (
    mostRecentMessageWithCustomerPhone &&
    mostRecentMessageWithCustomerPhone.customerPhone
  ) {
    //build an object
    let customerPhonesObject: { primary: number; all: (number | null)[] } = {
      primary: mostRecentMessageWithCustomerPhone.customerPhone,
      all: customerPhonesUnique,
    };
    return customerPhonesObject;
  } else {
    return null;
  }
};

export const getCustomerDisplayPhone = (customer: Customer) => {
  const customerPhone = getCustomerPhone(customer);
  const customerPhoneFormatted = customerPhone
    ? formatPhoneNumber(customerPhone.toString())
    : null;
  //logger.info("customerPhoneFormatted:", customerPhoneFormatted);
  return customerPhoneFormatted;
};

export const getTrackingDisplayPhone = (brand: Brand, customer: Customer) => {
  const trackingPhone = getTrackingPhone(brand, customer);
  const trackingPhoneFormatted = trackingPhone
    ? formatPhoneNumber(trackingPhone.toString())
    : null;
  //logger.info("customerPhoneFormatted:", customerPhoneFormatted);
  return trackingPhoneFormatted;
};

//
// Let's assume the business phone is the last one used in a call
// NOTE: businessPhone is NOT trackingPhone, business phone is the actual mobile
// or landline the brand representative is using to speak to the customer
//
export const getBusinessPhone = (brand: Brand, customer: Customer) => {
  //first, get all the customer messages that actually have a businessPhone attached to a message
  const messagesWithBusinessPhone = customer.messages!.filter((message) => {
    if (message.businessPhone) {
      return true;
    } else return false;
  });
  // logger.info("messagesWithBusinessPhone: ", messagesWithBusinessPhone);

  //now get the most recent of those messages

  const mostRecentMessageWithBusinessPhone =
    messagesWithBusinessPhone && messagesWithBusinessPhone.length !== 0
      ? messagesWithBusinessPhone.reduce((r, o) =>
          o.timestamp > r.timestamp ? o : r
        )
      : null;
  // logger.info(
  //   "mostRecentmessageWithBusinessPhone: ",
  //   mostRecentMessageWithBusinessPhone.businessPhone
  // );

  if (
    mostRecentMessageWithBusinessPhone &&
    mostRecentMessageWithBusinessPhone.businessPhone
  ) {
    //logger.info("getCustomerPhone:", msgWithPhone.businessPhone);

    //now return the businessPhone
    return mostRecentMessageWithBusinessPhone.businessPhone;
  } else {
    if (brand.preferredBusinessPhone) {
      return brand.preferredBusinessPhone;
    } else return null;
  }
};

export const adsurgentDayJsFormat = "YYYY-MM-DDTHH:mm:ssZ";

export const getTrackingPhone = (brand: Brand, customer: Customer) => {
  //first, get all the customer messages that actually have a businessPhone attached to a message
  const messagesWithTrackingPhone = customer.messages!.filter((message) => {
    if (message.trackingPhone) {
      return true;
    } else return false;
  });
  // logger.info("messagesWithBusinessPhone: ", messagesWithBusinessPhone);

  //now get the most recent of those messages
  const mostRecentMessageWithTrackingPhone =
    messagesWithTrackingPhone && messagesWithTrackingPhone.length !== 0
      ? messagesWithTrackingPhone.reduce((r, o) =>
          o.timestamp > r.timestamp ? o : r
        )
      : null;
  // logger.info(
  //   "mostRecentmessageWithBusinessPhone: ",
  //   mostRecentMessageWithBusinessPhone.businessPhone
  // );

  if (
    mostRecentMessageWithTrackingPhone &&
    mostRecentMessageWithTrackingPhone.trackingPhone
  ) {
    // logger.info(
    //   "getTrackingPhone:",
    //   mostRecentMessageWithTrackingPhone.trackingPhone
    // );

    //now return the businessPhone
    return mostRecentMessageWithTrackingPhone.trackingPhone;
  } else {
    if (brand.phoneExtension) {
      return brand.phoneExtension;
    } else return null;
    //there is no trackingPhone associated with the message, so
    //we need to get the brand's default trackingPhone (default caller ID)
    //add it in the brand object
  }
};

// Web-Push
// Public base64 to Uint
export const urlBase64ToUint8Array = (base64String: string): Uint8Array => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};
// const humanReadableTimestamp = format(
//     new Date(customer.messages![0].timestamp)
//   );

// // To calculate the time difference of two dates
// var Difference_In_Time = date2.getTime() - date1.getTime();

// // To calculate the no. of days between two dates
// var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
