import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { uiActions } from "../store/uiSlice";
import { useDispatch } from "react-redux";
import { IconButton, TextField, Typography } from "@mui/material";
import { adsurgentHumanDateTime } from "../util/util";
import classes from "./NotesCustomerEditDialog.module.css";
import { useRef, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import ConfirmationDialog from "./ConfirmationDialog";
import { RootState } from "../store";
import { useParams } from "react-router-dom";
import {
  useGetCustomerQuery,
  useUpdateCustomerMutation,
  //useDeleteUpgradeMutation,
} from "../store/api";
import { Customer } from "../types";
//slate RTE stuff:
import { Descendant } from "slate";
import NoteEditor from "./NoteEditorSlate";
import { emptyValue } from "./SlateRTEComponents";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { getByTitle } from "@testing-library/react";
import DescriptionIcon from "@mui/icons-material/Description";
import { getCustomerName, getProperCase } from "../util/util";
import logger from "../logger";

const NotesCustomerEditDialog: React.FunctionComponent = () => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const ui = useSelector((state: RootState) => state.ui);
  const params = useParams();
  const brandId: string = params.brandId!;
  const customerId: string = params.customerId!;

  const {
    data: customerData,
    error: customerError,
    isLoading: customerIsLoading,
  } = useGetCustomerQuery({ brandId: brandId, customerId: customerId }, {});
  //logger.info("upgrade data: " + JSON.stringify(upgradeData));

  //const [title, setTitle] = React.useState("");
  //const [isTitleFocused, setIsTitleFocused] = React.useState(false);
  //const [open, setOpen] = React.useState(false);

  const [
    updateCustomer, // This is the mutation trigger (RTK Query)
    { isLoading: customerIsUpdating }, // This is the destructured mutation result
  ] = useUpdateCustomerMutation();

  const dispatch = useDispatch();
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    useState<boolean>(false);

  const handleSetConfirmationDialogOpen = (tf: boolean) => {
    setConfirmationDialogOpen(tf);
  };

  const editor = React.useRef();

  const [content, setContent] = useState<Descendant[] | undefined>(emptyValue);

  //let's set the initial value for the notes from database, if it exists
  const getInitialContent = async () => {
    if (customerData && customerData.notes) {
      const existingNotes = JSON.parse(customerData.notes);
      setContent(existingNotes);
    } else setContent(emptyValue);
  };

  // useEffect(() => {
  //   let cancel = false;

  //   someAsyncFunction().then(() => {
  //     if (cancel) return;
  //     setIsVisible(false);
  //   });

  //   return () => {
  //     cancel = true;
  //   };
  // }, []);

  useEffect(() => {
    getInitialContent();
  }, []);

  if (customerData) {
    const customerNameDb = getCustomerName(customerData);
    const customerName = customerNameDb ? getProperCase(customerNameDb) : "";
    // const customerName = customerData.verifiedCustomerName
    //   ? customerData.verifiedCustomerName
    //   : customerData.messages![0].customerName;

    const onChangeContentHandler = async (content: any) => {
      if (content) {
        const contentJson = JSON.stringify(content);
        //create a new upgrade object with udpated "notes"
        //const updatedUpgrade = { ...upgradeData, notes: contentJson };
        //check to see if anything has actually changed,
        //because onChange fires even for text selection, etc.
        if (content != customerData.notes) {
          logger.info("Notes changed, updating now...");
          //update the database
          try {
            await updateCustomer({
              brandId: customerData.brandId,
              customerId: customerData.id,
              propertyName: "notes",
              propertyValue: contentJson,
            });
            //now update the local state used by Slate
            //logger.info("set content", content);
            setContent(content);
          } catch (err) {
            logger.error("Error updating notes: " + err);
          }
        }
      }
    };

    // ????????????//

    //this closes the main modal
    const handleClose = () => {
      dispatch(uiActions.setActiveUpgradeId(""));
    };
    //this opens the main modal
    const handleOpen = () => {
      uiActions.toggleNotesEditDialog(true);
    };

    const className = largeScreen
      ? classes["trix-input-large-screen"]
      : classes["trix-input-small-screen"];

    return (
      <>
        <Dialog
          //go full screen if it's a small screen
          fullScreen={true /*!largeScreen*/}
          open={customerData.id === ui.activeUpgradeId ? true : false}
          onClose={handleClose}
          PaperProps={
            !largeScreen
              ? {
                  sx: {
                    width: 800,
                  },
                }
              : {
                  sx: {},
                }
          }
        >
          <DialogContent>
            <Typography display="flex" alignItems="center">
              <DescriptionIcon fontSize="large" />
              <div className={classes.name}>&nbsp;{customerName}</div>
            </Typography>
            {content && (
              <NoteEditor
                content={content}
                onChangeHandler={onChangeContentHandler}
              />
            )}
          </DialogContent>
          <DialogActions sx={{ justifyContent: "flex-end" }}>
            {!customerIsUpdating && (
              <Button onClick={handleClose} autoFocus={false}>
                Done
              </Button>
            )}
            {/* If upgrade is updating, show a non-clickable button. */}
            {customerIsUpdating && <Button autoFocus={false}>Saving...</Button>}
          </DialogActions>
        </Dialog>
      </>
    );
  } else return <div></div>;
};

export default NotesCustomerEditDialog;
