import axios from "axios";
import { RootState } from "../store";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../store/authSlice";
import logger from "../logger";

//Most communication with the backend API is NOT handled here, but rather through RTK Query
//in the API slice.  This utility is built to handle files that should not be stored in redux state...
//e.g., mp3 audio recording files

export const getFileSignedUrl = async (getUrl: string, bearerToken: string) => {
  try {
    const response = await axios.get(getUrl, {
      headers: {
        authorization: `Bearer ${bearerToken}`,
      },
    });

    //console.log(response.data);
    return response.data;
  } catch (err) {
    // Handle Error Here
    logger.error("Error in getFileSignedUrl: " + err);
  }
};
