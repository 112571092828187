import React from "react";
import classes from "./LoadingScreen.module.css";
import AutoSizer from "react-virtualized-auto-sizer";

const LoadingScreen: React.FunctionComponent<{ statusMsg: string }> = ({
  statusMsg,
}) => {
  return (
    // <AutoSizer>
    //   {({ height, width }) => (
    <div
      className={classes["outerbox"]}
      //style={{ height: height, width: width }}
    >
      <div className={classes["innerbox"]}>
        <div className={classes["loader"]}></div>
        <div>{statusMsg}</div>
      </div>
    </div>

    //   )}
    // </AutoSizer>
  );
};
export default LoadingScreen;
