// https://andreidobrinski.com/blog/implementing-an-audio-waveform-with-react-hooks-and-wavesurferjs/

import { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { getFileSignedUrl } from "../util/fetchFiles";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrow from "@mui/icons-material/PlayArrow";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { IconButton } from "@mui/material";
import VisibilitySensor from "react-visibility-sensor";
import logger from "../logger";
// import { uiActions } from "../store/uiSlice";
// import { useDispatch } from "react-redux";
// import { RootState } from "../store";
// import { useSelector } from "react-redux";
// import { is } from "immer/dist/internal";

const AudioPlayer: React.FunctionComponent<{
  audioUrl: string;
  callDate: string;
}> = ({ audioUrl, callDate }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  //   const isGlobalWavesurferPlaybackStopped = useSelector(
  //     (state: RootState) => state.ui.isGlobalWavesurferPlaybackStopped
  //   );

  //wavesurfer ref is not an instantianted yet, so let's create some defaults
  //to mimic methods available after instantiated
  const waveSurferRef = useRef({
    isPlaying: () => false,
    getCurrentTime: () => 0,
    getDuration: () => 0,
  });

  const [isWavesurferReady, setIsWaveSurferReady] = useState(false);
  //const [isPlaying, toggleIsPlaying] = useState(false);
  const [audioPlayerTime, setAudioPlayerTime] = useState("");
  //logger.info("audioUrl: ", audioUrl);

  //   useEffect(() => {
  //     if (isGlobalWavesurferPlaybackStopped) {
  //       //@ts-ignore
  //       waveSurferRef.current.pause();
  //     }
  //   }, [isGlobalWavesurferPlaybackStopped]);
  useEffect(() => {
    const waveSurfer = WaveSurfer.create({
      container: containerRef.current,
      height: 30,
      normalize: true,
      barWidth: 2,
      cursorColor: "transparent",
      responsive: true,
      partialRender: true,
    });
    waveSurfer.load(audioUrl);
    waveSurfer.on("ready", () => {
      waveSurferRef.current = waveSurfer;
      showDuration();
      setIsWaveSurferReady(true);
    });
    waveSurfer.on("seek", (data: any) => {
      updateTimer();
    });

    waveSurfer.on("pause", (data: any) => {
      showDuration();
    });

    waveSurfer.on("audioprocess", (data: any) => {
      updateTimer();
    });
    //https://hackernoon.com/cleanup-functions-in-reacts-useeffect-hook-explained
    //this useEffect requires cleanup to avoid errors
    return () => {
      //@ts-ignore
      waveSurfer.destroy();
    };
  }, []);

  const updateTimer = () => {
    //let seconds = 0;
    const seconds = waveSurferRef.current.getCurrentTime();

    // var date = new Date(0);
    // date.setSeconds(seconds); // specify value for SECONDS here
    const getTimeString = () => {
      if (seconds < 3600) {
        const displayTime = new Date(seconds * 1000)
          .toISOString()
          .substring(15, 19);
        return displayTime;
      } else if (seconds > 3600) {
        const displayTime = new Date(seconds * 1000)
          .toISOString()
          .substring(11, 16);
        return displayTime;
      }
    };
    const timeString = getTimeString();
    //logger.info(timeString);
    if (timeString) {
      setAudioPlayerTime(timeString);
    }
  };

  const showDuration = () => {
    const durationInSeconds = waveSurferRef.current.getDuration();
    if (durationInSeconds > 0 && durationInSeconds < 60) {
      const displayDuration = new Date(durationInSeconds * 1000)
        .toISOString()
        .substring(15, 19);
      setAudioPlayerTime(displayDuration);
    } else if (durationInSeconds > 60 && durationInSeconds < 600) {
      const displayDuration = new Date(durationInSeconds * 1000)
        .toISOString()
        .substring(15, 19);
      setAudioPlayerTime(displayDuration);
    } else if (durationInSeconds < 3600) {
      const displayDuration = new Date(durationInSeconds * 1000)
        .toISOString()
        .substring(14, 19);
      setAudioPlayerTime(displayDuration);
    }
  };

  const onVisibilityChange = (isVisible: boolean) => {
    //logger.info(isVisible ? "Visible!" : "invisible!");

    if (!isVisible && waveSurferRef.current.isPlaying()) {
      //@ts-ignore
      waveSurferRef.current.playPause();
      //toggleIsPlaying(waveSurferRef.current.isPlaying());
    }
  };
  //custom Play/Pause utilizing redux global store to help with global stopping of playback
  //   const handlePlayPause = () => {
  //     if (waveSurferRef.current.isPlaying()) {
  //       //waveSurferRef.current.pause()
  //       dispatch(uiActions.stopGlobalWavesurferPlayback(null));
  //       //toggleIsPlaying(waveSurferRef.current.isPlaying());
  //     } else {
  //       //@ts-ignore
  //       waveSurferRef.current.play();
  //     }
  //   };
  // if (waveSurferRef && waveSurferRef.current) {

  const getIconForButton = () => {
    if (waveSurferRef.current.isPlaying() ) {
      return <PauseIcon />;
    } else return <PlayArrow />;
  };

  return (
    <VisibilitySensor onChange={onVisibilityChange}>
      <>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          {isWavesurferReady && (
            <div style={{ display: "block", width: "10%" }}>
              <IconButton
                size="small"
                //sx={{ alignItems: "flex-end" }}
                onClick={() => {
                  //@ts-ignore
                  waveSurferRef.current.playPause();
                  //toggleIsPlaying(waveSurferRef.current.isPlaying());
                  //handlePlayPause();
                }}
              >
                {getIconForButton()}
              </IconButton>
            </div>
          )}
          <div
            ref={containerRef}
            style={{
              width: "70%",
              height: "30px",
              display: "inline-block",
            }}
          >
            {!isWavesurferReady && (
              <div style={{ height: "60px" }}>
                <span
                  style={{
                    position: "absolute",
                    top: "33%",
                    fontSize: "small",
                  }}
                >
                  Loading call...
                </span>
              </div>
            )}
          </div>
        </div>
        {/* ROW TWO */}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "12px",
              float: "left",
              marginLeft: 20,
            }}
          >
            {audioPlayerTime}
          </div>

          <div style={{ fontSize: "12px", float: "right" }}>
            {callDate}
            {/* <IconButton
                onClick={onDownload}
                aria-label="download-recording"
                size="small"
              >
                <DownloadIcon />
              </IconButton> */}
            {/* props.messageId.substring(props.messageId.length - 6) */}
          </div>
        </div>
      </>
    </VisibilitySensor>
  );
  // } else return <div>No Wavesurfer Ref Found.</div>;
};

export default AudioPlayer;
