//import { upgradesActions } from "../store/upgrades-slice";
import { uiActions } from "../store/uiSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import classes from "./UpgradesList.module.css";
import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import { Grid } from "@mui/material";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import { TextField } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import { ListItemIcon } from "@mui/material";
import { IconButton } from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ChangeEvent, useState } from "react";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import backgroundImage from "../assets/images/humanoid-face.jpg";
import VisibilityIcon from "@mui/icons-material/Visibility";
//import { contactActions } from "../store/contact-slice";
import NotesUpgradeEditDialog from "./NotesUpgradeEditDialog";
import { Box } from "@mui/material";
import { LinearProgress } from "@mui/material";
import { Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useRef } from "react";
import {
  useGetUpgradesQuery,
  useUpdateUpgradeMutation,
  useDeleteUpgradeMutation,
  useAddUpgradeMutation,
} from "../store/api";
import { useParams } from "react-router-dom";
import { RootState } from "../store";
import { Upgrade } from "../types";
import { v4 as uuid } from "uuid";
import { addAbortSignal } from "stream";
import logger from "../logger";

const UpgradesList: React.FunctionComponent = (props) => {
  // const upgrades = useSelector((state) => state.upgrades);
  // const displayUpgrades = [...upgrades];

  const ui = useSelector((state: RootState) => state.ui);
  const params = useParams();
  const brandId: string = params.brandId!;
  //{ data, error, isLoading }, but to rename, we can:
  //const { data :googleData, isLoading: googleLoading, isFetching: googleIsfetching }
  const {
    data: upgradesData,
    error: upgradesError,
    isLoading: upgradesIsLoading,
  } = useGetUpgradesQuery({ brandId: brandId }, {});

  const [
    addUpgrade, // This is the mutation trigger (RTK Query)
    { isLoading: ugpradeIsAdding }, // This is the destructured mutation result
  ] = useAddUpgradeMutation();

  // const [
  //   deleteUpgrade, // This is the mutation trigger (RTK Query)
  //   { isLoading: upgradeIsDeleting }, // This is the destructured mutation result
  // ] = useDeleteUpgradeMutation(upgradeId);
  const dispatch = useDispatch();

  const [enteredUpgrade, setEnteredUpgrade] = useState("");

  const textInputRef = useRef<any>();

  if (upgradesData) {
    const arrayToSort: Upgrade[] = [...upgradesData];

    //sort by dueDate, isCompleted, completedDate
    const upgradesSorted = arrayToSort.sort((a: Upgrade, b: Upgrade) => {
      const aIsCompleted = a.isCompleted ? 1 : 0;
      const bIsCompleted = b.isCompleted ? 1 : 0;
      //sort first by isCompleted (higher weighting)
      const isCompletedDiff = (aIsCompleted - bIsCompleted) * 10000000000;
      //sort next by completedDateDiff (medium weighting)

      const completedDateDiff =
        b.dateCompleted && a.dateCompleted
          ? (+new Date(b.dateCompleted) - +new Date(a.dateCompleted)) * 10
          : 0;

      //sort next by dueDate, lowest weighting
      const dueDateDiff =
        b.dueDate && a.dueDate
          ? +new Date(a.dueDate) - +new Date(b.dueDate)
          : 0;
      const totalDiff = isCompletedDiff + dueDateDiff + completedDateDiff;
      // logger.info(
      //   "due date diff: " + dueDateDiff,
      //   "isCompletedDiff: " + isCompletedDiff,
      //   "completedDateDiff: " + completedDateDiff,
      //   "totalDiff: " + totalDiff
      // );
      return totalDiff;
    });

    //logger.info("upgradesSorted: " + JSON.stringify(upgradesSorted));
    const getCompletedPercentage = () => {
      const countTotal = upgradesSorted.length;
      const countCompleted = upgradesSorted.filter(function (upgrade) {
        return upgrade.isCompleted;
      }).length;
      const percentageCompleted = Math.round(
        (countCompleted / countTotal) * 100
      );
      return percentageCompleted;
    };

    //we also need to add notes to each Upgrade

    //we could easily show Percentage Completed progress bar
    //based on percentage of items completed in the array
    //though a more accurate percentage would be based on hours

    //change colors of items based on isCompleted state
    //eg. secondary text, checkbox
    //add animations
    //zero out the input when adding new ugprade item

    const upgradeInputChangeHandler = (label: string) => {
      setEnteredUpgrade(label);
    };

    // const createUniqueId = () => {
    //   const currentLength = upgradesData.length;
    //   const uniqueId = "8675309" + currentLength;
    //   return uniqueId;
    // };

    const handleAddUpgrade = async () => {
      //@ts-ignore
      const label = textInputRef.current.value;
      if (label) {
        const upgradeObject: Upgrade = {
          brandId: brandId,
          id: uuid(),
          label: label,
          notes: null,
          dueDate: null,
          isCompleted: false,
          dateCompleted: null,
          isActive: false,
        };

        //dispatch(upgradesActions.addUpgrade(upgradeObject));
        await addUpgrade(upgradeObject);
        resetInput();
      }
    };

    const resetInput = () => {
      if (textInputRef) {
        textInputRef.current.value = "";
      }
    };

    return (
      <>
        <AutoSizer>
          {({ height, width }) => (
            <Grid container sx={{ height: height, width: width }}>
              <Grid item xs={12} sm={12} md={6}>
                <h2 style={{ marginLeft: "15px" }}>Brand Lab To-Dos</h2>
                <Box sx={{ width: "100%" }}>
                  <LinearProgress
                    variant="determinate"
                    value={getCompletedPercentage()}
                  />
                </Box>

                <FixedSizeList
                  height={height ? height - 170 : 500}
                  //width={}
                  itemSize={65}
                  itemCount={upgradesSorted.length}
                  overscanCount={5}
                  itemData={upgradesSorted}
                  //@ts-ignore
                  lazyMeasurement={false}
                  recalculateItemsOnResize={{ width: false, height: true }}
                >
                  {RenderRow}
                </FixedSizeList>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "",
                    padding: "15px",
                    borderTop: "solid",
                    borderWidth: "1px",
                    borderColor: "gray",
                  }}
                >
                  <TextField
                    id="outlined-basic"
                    label="Add new upgrade..."
                    variant="outlined"
                    sx={{ width: "100%" }}
                    inputRef={textInputRef}
                    // onChange={(event) => {
                    //   upgradeInputChangeHandler(event.target.value);
                    // }}
                    // onKeyDown={(event) => {
                    //   if (event.key === "Enter") {
                    //     //@ts-ignore
                    //     upgradeInputChangeHandler(event.target.value);
                    //     //@ts-ignore
                    //     handleAddUpgrade(event.target.value);
                    //   }
                    // }}
                  />

                  <IconButton
                    aria-label="add"
                    color="primary"
                    onClick={(event) => {
                      handleAddUpgrade();
                    }}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div
                  style={{
                    backgroundImage: `url(${backgroundImage})`,
                    height: "100%",
                    width: "100%",
                    backgroundSize: "cover",
                    backgroundPosition: "50% 50%",
                  }}
                ></div>
              </Grid>
            </Grid>
          )}
        </AutoSizer>
      </>
    );
  } else return <div></div>;
};

function RenderRow(props: {
  data: any;
  index: number;
  style: any;
  //itemData: Upgrade;
}) {
  //const upgrades = useSelector((state) => state.upgrades);
  const { data, index, style /*itemData*/ } = props;
  const item: Upgrade = data[index];
  const dispatch = useDispatch();
  const handleCompletedToggle = ({
    isCompleted,
    dateCompleted,
  }: {
    isCompleted: boolean;
    dateCompleted: Date | null;
  }) => {
    //setIsChecked(!isChecked);
    //dispatch(upgradesActions.toggleCompleted(object));
    const changedUpgrade = {
      ...item,
      isCompleted: isCompleted,
      dateCompleted: dateCompleted,
    };
    updateUpgrade(changedUpgrade);
  };

  const [
    updateUpgrade, // This is the mutation trigger (RTK Query)
    { isLoading: upgradeIsUpdating }, // This is the destructured mutation result
  ] = useUpdateUpgradeMutation();

  // function sleep(ms) {
  //   return new Promise((resolve) => setTimeout(resolve, ms));
  // }

  //const [isChecked, setIsChecked] = useState(item.isCompleted);

  const infoClickHandler = async (item: Upgrade) => {
    // const alteredUpgrade = { ...item, isActive: true };
    // //logger.info(alteredUpgrade);
    dispatch(uiActions.setActiveUpgradeId(item.id));
    dispatch(uiActions.toggleNotesEditDialog(true));
  };

  const labelInputElement = useRef(null);
  const labelInputConfirmButton = useRef(null);
  const todaysDate = new Date();

  return (
    <>
      <ListItem
        key={item.id}
        //fyi scrollbar will jittery on scroll if this style tag not included
        style={style}
        sx={
          item.isCompleted == true
            ? {
                backgroundColor: "light-grey",
                border: "solid",
                borderColor: "lightgray",
                borderWidth: "0px 0px 1px 0px",
                color: "lightgray",
                marginBottom: "10px",
              }
            : {
                backgroundColor: "light-grey",
                border: "solid",
                borderColor: "lightgray",
                borderWidth: "0px 0px 1px 0px",
                marginBottom: "10px",
              }
        }
        secondaryAction={
          <IconButton
            edge="end"
            aria-label="comments"
            size="small"
            sx={item.isCompleted ? { color: "lightgray" } : {}}
            onClick={() => {
              infoClickHandler(item);
            }}
          >
            <InfoOutlinedIcon />
          </IconButton>
        }
        disablePadding
      >
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={item.isCompleted}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleCompletedToggle({
                isCompleted: !item.isCompleted,
                dateCompleted: item.isCompleted === false ? todaysDate : null,
              })
            }
            tabIndex={-1}
            disableRipple
            inputProps={{ "aria-labelledby": item.label! }}
            sx={
              item.isCompleted
                ? {
                    paddingLeft: "25px",
                    color: "lightgray",
                    "&.Mui-checked": {
                      color: "lightgray",
                    },
                  }
                : {
                    paddingLeft: "25px",
                  }
            }
          />
        </ListItemIcon>
        <ListItemText
          id={item.id}
          primary={item.label}
          secondary={
            item.dateCompleted
              ? "Completed on " +
                new Date(item.dateCompleted).toLocaleString([], {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : ""
          }
        />
      </ListItem>
      <NotesUpgradeEditDialog upgradeId={item.id} />
    </>
  );
}

export default UpgradesList;
