import { configureStore } from "@reduxjs/toolkit";
import uiSlice from "./uiSlice";
import authSlice from "./authSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import { backendApi } from "./api";

//configureStore is from the redux toolkit
const store: any = configureStore({
  //accepts single reduce or map of reduces, this is a map
  reducer: {
    [backendApi.reducerPath]: backendApi.reducer,
    auth: authSlice,
    ui: uiSlice.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(backendApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export default store;

//https://redux-toolkit.js.org/tutorials/typescript
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
